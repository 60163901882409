export default {
  isOrgAdmin(_, getters, rootState) {
    return rootState.org.org && getters.orgsRolesDict[rootState.org.org.id]?.admin
  },
  isOrgQhp(_, getters, rootState) {
    return rootState.org.org && (getters.orgsRolesDict[rootState.org.org.id]?.qhp99091 || getters.orgsRolesDict[rootState.org.org.id]?.qhp99457)
  },
  // map of orgId -> roles for the user
  orgsRolesDict(state) {
    const d = {}
    if (!state.user) return d
    for (const org of state.user.orgs) {
      d[org.orgId] = org
    }
    return d
  }
}
