import client from '@/store/v2/client'
import get from 'lodash/get'

const teleClient = client.HttpClientWithSso(process.env.VUE_APP_TELE_API_URL)

export default{
  // onOrgChange is called when org.org changes.
  onOrgChange({ commit, state }, org) {
    if (state._org?.id === org?.id) {
      return  // org id didn't change, so don't do anything
    }
    commit('_setOrg', org)
  },
  // onPatientChange is called when patient.patient changes.
  onPatientChange({ commit, state }, patient) {
    if (state._patient?.id === patient?.id) {
      return // no change, so do nothing
    }
    commit('_setPatient', patient)
  },
  async getUnreadMessagesCount({ commit, state }) {
    if (!state.conversation) {
      return 0
    }
    let newerThan = state.conversation.orgLastRead
    let c = state.messages.length
    if (c > 0) {
      let lastMessage = state.messages[c - 1]
      newerThan = lastMessage.created
    }
    let { data } = await teleClient.post('/GetMsgsCount', {
      convoId: state.conversation.id,
      newerThan,
    })

    commit('setUnreadMessagesCount', Number(data.count))
    return data
  },
  async getConversation({ commit, state }, { convoId = '', patientId = '' }) {
    let { data } = await teleClient.post('/GetConvo', {
      convoId,
      orgId: state._org.id,
      patientId,
    })
    commit('setConversation', data.convo)
    return data.convo
  },
  async getConversations({ commit, state }, { pageSize, pageToken, unreadOnly }) {
    let { data } = await teleClient.post('/GetConvos', {
      orgId: state._org.id,
      pageSize,
      pageToken,
      unreadOnly,
    })
    data = makeBackwardsCompatible(data)
    commit('setConversations', data.convos)
    return data.convos
  },
  async getMessages({ dispatch, commit, state }, { patientId }) {
    // no convo, get it
    if (!state.conversation) {
      await dispatch('getConversation', {
        orgId: state._org.id,
        patientId,
      })
    }
    // still no convo, return empty messages
    if (!state.conversation) {
      return state.messages
    }
    let newerThan
    let c = state.messages.length
    if (c > 0) {
      let lastMessage = state.messages[c - 1]
      newerThan = lastMessage.created
    }

    let { data } = await teleClient.post('/GetMsgs', {
      convoId: state.conversation.id,
      newerThan,
      pageSize: 100,
    })
    let messages = [].concat(state.messages)
    if (state.messages.length === 0 || newerThan) {
      messages = messages.concat(parseMessages(data.msgs))
      messages = purgeTempMessages(messages)
    }
    commit('setMessages', messages)
    return messages
  },
  async sendMessage({ commit, state }, { patientId, content }) {
    let { data } = await teleClient.post('/SendMsg', {
      orgId: state._org.id,
      patientId,
      content,
    })
    let messages = state.messages.concat(parseMessages([data.msg]))
    commit('setMessages', messages)
    return data.msg
  },
  clearMessages({ commit }) {
    commit('setConversation', null)
    commit('setUnreadMessagesCount', 0)
    commit('setMessages', [])
  },
  incMessengerOpenCount({ commit }) {
    commit('incMessengerOpenCount')
  },
  decMessengerOpenCount({ dispatch, commit, state }) {
    commit('decMessengerOpenCount')
    if (state.messengerOpenCount === 0) {
      dispatch('clearMessages')
    }
  },
}

function makeBackwardsCompatible(data) {
  let s = JSON.stringify(data)
  s = s.replaceAll('"id"', '"_id"')
  s = s.replaceAll('"lastReadMsgId"', '"lastReadMessageID"')
  s = s.replaceAll('"lastReadMsgCreated"', '"lastReadMessageCreated"')
  s = s.replaceAll('"orgId"', '"partnerID"')
  s = s.replaceAll('"lastMsg"', '"lastMessage"')
  s = s.replaceAll('"convoId"', '"conversationID"')
  s = s.replaceAll('"userId"', '"userID"')
  s = s.replaceAll('"patientId"', '"patientID"')
  s = s.replaceAll('"ownerPatientId"', '"ownerPatientID"')
  s = s.replaceAll('"conversations"', '"items"')
  s = s.replaceAll('"orgUnread"', '"unread"')
  data = JSON.parse(s)
  return data
}

function purgeTempMessages(messages = []) {
  let msgs = []
  for (let i = 0; i < messages.length; i++) {
    if (messages[i].id) {
      msgs.push(messages[i])
    }
  }
  return msgs
}

function parseMessages(messages = []) {
  let msgs = []
  for (let i = 0; i < messages.length; i++) {
    let msg = messages[i]
    msgs.push({
      id: msg.id,
      senderId: msg.sender.userId,
      displayName: msg.sender.displayName,
      isSender: msg.sender.kind !== 'PATIENT',
      content: msg.content,
      delivered: get(msg, 'smsStatus', '').toLowerCase() === 'delivered',
      created: msg.created,
    })
  }
  return msgs
}