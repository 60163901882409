export default {
  org: null,
  orgLoading: false,
  invites: [],
  invitesLoading: false,

  // Patients
  patients: [],
  patientsLoading: false,
  patientsDict: {},
  enrolledPatients: [],
  unenrolledPatients: [],
  enrolledPatientsZips: [], // move out into population map?

  // Settings
  settings: {},
  settingsLoading: false,

  // Users
  users: [],
  usersLoading: false,
  usersDict: {},
  providers: {},

  // Convos
  unreadConvosCount: 0,
  // firebase notifications
  providerReviewNotifications: [],
  careTeamReviewNotifications: [],
  mentions: [],
  ticklers: [],

  _resetHooks: [],
  _orgId: null,
}
