import Vue from 'vue'
import clone from 'lodash/clone'
import CONSTS from '@/consts'

import client from './client'

const enrollmentsClient = client.HttpClientWithSso(process.env.VUE_APP_ENROLLMENTS_API_URL)
const patientsClient = client.HttpClientWithSso(process.env.VUE_APP_PATIENTS_API_URL)
const ordersClient = client.HttpClientWithSso(process.env.VUE_APP_ORDERS_API_URL)

function populateLastNote(candidate) {
  let logs = candidate.logs || []
  let log = logs[logs.length - 1] || {}
  candidate.lastNote = log.note || ''
}

export default {
  namespaced: true,
  state: {
    currentCandidate: null,
    candidates: null,
    startCallTime: 0,
    currentPatient: null,
  },
  mutations: {
    setCurrentCandidate(state, candidate) {
      if (candidate) {
        if (!state.currentCandidate || state.currentCandidate.id !== candidate.id) {
          populateLastNote(candidate)
          state.currentCandidate = clone(candidate)
        }
      } else {
        state.currentCandidate = null
      }
    },
    setCandidates(state, candidates) {
      state.candidates = candidates
    },
    setStartCallTime(state, time) {
      state.startCallTime = time
    },
    updateCandidate(state, candidate) {
      let candidates = state.candidates
      for (let i = 0; i < candidates.length; i++) {
        if (candidates[i].id === candidate.id) {
          populateLastNote(candidate)
          Vue.set(candidates, i, candidate)
          break
        }
      }
    },
    setCurrentPatient(state, patient) {
      state.currentPatient = patient
    },
  },
  actions: {
    async initCallTime({ commit }) {
      commit('setStartCallTime', new Date())
    },
    async getCandidates({ commit }) {
      let { data } = await enrollmentsClient.post('/GetCandidates', {
        status: [CONSTS.STATUS_TYPES.CALLBACK, CONSTS.STATUS_TYPES.NO_ANSWER, CONSTS.STATUS_TYPES.NOT_CONTACTED],
      })
      if (data && data.candidates) {
        for (let i = 0; i < data.candidates.length; i++) {
          let patient = data.candidates[i]
          populateLastNote(patient)
        }
        commit('setCandidates', data.candidates)
      } else {
        commit('setCandidates', [])
      }
    },
    async getCandidate({ commit }, { id }) {
      try {
        let { data } = await enrollmentsClient.post('/GetCandidate', { id })
        commit('setCurrentCandidate', data.candidate)
        return data.candidate
      } catch (e) {
        return null
      }
    },
    async findCandidate({ commit }, { ownerId, ownerPatientId }) {
      try {
        let { data } = await enrollmentsClient.post('/FindCandidate', {
          ownerId,
          ownerPatientId,
        })
        commit('setCurrentCandidate', data.candidate)
        return data.candidate
      } catch (e) {
        return null
      }
    },
    async getPatient({ commit }, { id = '', ownerId = '', ownerPatientId = '', smsPhone = '' }) {
      try {
        let { data } = await patientsClient.post('/GetPatient', {
          id,
          ownerId,
          ownerPatientId,
          smsPhone,
        })
        commit('setCurrentPatient', data.patient)
        return data.patient
      } catch (e) {
        commit('setCurrentPatient', null)
        return null
      }
    },
    async createPatient({ commit }, candidate) {
      let { data } = await patientsClient.post('/CreatePatient', {
        owner: candidate.owner,
        ownerPatientId: candidate.ownerPatientId,
        firstName: candidate.firstName,
        lastName: candidate.lastName,
        gender: candidate.gender,
        birthdate: candidate.birthdate,
        primaryPhone: candidate.primaryPhone,
        smsPhone: candidate.smsPhone,
        email: candidate.email,
        address: candidate.address,
      })
      commit('setCurrentPatient', data.patient)
      return data.patient
    },
    async createOrder(_, { patient, deviceName }) {
      const { data } = await ordersClient.post('/CreateOrder', {
        patientId: patient.id,
        owner: { id: patient.org.id, ref: "Partners" },
        ownerPatientId: patient.org.patientId,
        shippingRecipientName: patient.firstName + ' ' + patient.lastName,
        shippingAddress: patient.address,
        deviceName,
      })
      return data.order
    },
    async markPatientAsEnrolled(_, patientId) {
      try {
        await patientsClient.post('/MarkPatientAsEnrolled', { id: patientId })
        return true
      } catch (e) {
        return false
      }
    },
    setCurrentPatient({ commit }, patient) {
      commit('setCurrentPatient', patient)
    },
    setCurrentCandidate({ commit }, candidate) {
      commit('setCurrentCandidate', candidate)
    },
  },
}
