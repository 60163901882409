<template>
  <v-slide-y-reverse-transition>
    <v-container v-if="!patientsLoading" fluid class="pa-0">
      <div style="width: 100%">
        <v-row class="pr-3 pt-1 pb-3" no-gutters>
          <v-col class="pl-3 pb-4" v-for="(item, index) in headsUpCategories" :key="index" cols="3">
            <div :class="enrolledPatients.length > 0 ? 'topCard' : null">
              <v-card
                :disabled="enrolledPatients.length === 0"
                class="flex"
                height="70"
                @click=";(selectedAlert = index), backToInbox()"
                :dark="selectedAlert === index && item.darkSelected"
                flat
                rounded="lg"
                :color="selectedAlert === index && enrolledPatients.length > 0 ? item.color : 'white'"
              >
                <div v-if="enrolledPatients.length > 0">
                  <v-row justify="end" style="position: absolute; top: 0; right: 0; width: 100%" class="pa-2"> </v-row>
                  <v-row
                    style="position: absolute; bottom: 0; left: 0; width: 100%"
                    class="pa-2"
                    no-gutters
                    align="center"
                    justify="start"
                  >
                    <v-col>
                      <div class="font-weight-bold mr-1" style="font-size: 12px; line-height: 1">
                        {{ item.title }}
                      </div>
                      <!-- <div id="value" class="text-caption">{{ item.text }}</div> -->
                    </v-col>
                  </v-row>
                  <v-row
                    no-gutters
                    style="position: absolute; top: 0; left: 0; width: 100%"
                    class="pa-2 pt-1"
                    align="start"
                    justify="start"
                  >
                    <span id="value" class="font-weight-bold" style="font-size: 23px">{{ item.list.length }} </span>
                  </v-row>
                </div>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </div>

      <v-card flat height="200" color="transparent" v-if="enrolledPatients.length === 0" class="flex">
        <v-row v-if="!patientsLoading" justify="center" align="center" class="fill-height" no-gutters>
          <span>No enrolled patients</span>
        </v-row>
      </v-card>

      <div
        style="
          position: sticky;
          top: 63px;
          z-index: 100;
          backdrop-filter: blur(2px);
          border-right: 1px solid transparent;
        "
        height="50"
      >
        <v-card
          height="50"
          v-if="enrolledPatients.length > 0 && !patientsLoading"
          tile
          flat
          :color="headerColor + 'cc'"
        >
          <v-row no-gutters align="center" class="fill-height">
            <inbox-toolbar-simplified :selectedPatients="selected">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-simple-checkbox
                    v-bind="attrs"
                    v-on="on"
                    :value="selected.length === headsUpCategories[selectedAlert].list.length"
                    :indeterminate="
                      selected.length > 0 && selected.length !== headsUpCategories[selectedAlert].list.length
                    "
                    @click="toggleSelectAll()"
                    :ripple="false"
                    color="primary"
                  ></v-simple-checkbox>
                </template>
                Select All
              </v-tooltip>

              <v-select
                background-color="transparent"
                slot="sortSlot"
                :items="sortOptions"
                hide-details
                dense
                solo
                flat
                style="padding: 0px; width: 150px"
                v-model="selectedSort"
                :append-icon="null"
                class="text-right px-0"
                v-show="selectedAlert < 3"
              >
                <template v-slot:selection> </template>
                <template v-slot:append>
                  <v-row justify="end" no-gutters>
                    <v-spacer />
                    <v-chip style="cursor: pointer" small
                      >{{ selectedSort }} <v-icon small right>mdi-chevron-down</v-icon></v-chip
                    >
                  </v-row>
                </template>
              </v-select>
            </inbox-toolbar-simplified>
          </v-row>
          <v-divider />
        </v-card>
      </div>
      <v-slide-y-reverse-transition hide-on-leave>
        <div v-if="enrolledPatients.length > 0 && !patientsLoading">
          <v-lazy
            :options="{
              threshold: 0,
            }"
            min-height="30"
            v-for="(listPatient, index) in headsUpCategories[selectedAlert].list"
            :key="index + 'pt'"
            :transition="null"
            :id="listPatient.id"
          >
            <inbox-patient-card-simplified
              :selected="selected.includes(listPatient)"
              @toggleSelected="toggleSelected(listPatient)"
              :patientRoute="$route.params.id"
              :listPatient="listPatient"
              @clicked="handleClick"
            />
          </v-lazy>
        </div>
      </v-slide-y-reverse-transition>

      <v-slide-y-reverse-transition hide-on-leave>
        <v-tabs-items
          v-if="enrolledPatients.length > 0 && false"
          style="background-color: transparent"
          v-model="selectedAlert"
        >
          <v-tab-item style="min-height: 80vh" v-for="(section, index) in headsUpCategories" :key="index">
            <v-lazy
              :options="{
                threshold: 0,
              }"
              min-height="30"
              v-for="(listPatient, index) in section.list"
              :key="index + 'pt'"
              :transition="'fade'"
              :id="listPatient.id"
            >
              <inbox-patient-card-simplified
                :selected="selected.includes(listPatient)"
                @toggleSelected="toggleSelected(listPatient)"
                :patientRoute="$route.params.id"
                :listPatient="listPatient"
                @clicked="handleClick"
              />
            </v-lazy>
          </v-tab-item>
        </v-tabs-items>
      </v-slide-y-reverse-transition>
      <portal to="inboxSummary">
        <inbox-snapshot v-if="!patientsLoading && showList" :filteredArray="combinedPatientListFiltered" />
      </portal>
    </v-container>
  </v-slide-y-reverse-transition>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import dayjs from 'dayjs'
import { createDeviceCSV } from '@/helpers/csv'
import consts from '@/consts.js'
import { msToMinutesAndSeconds } from '@/helpers/time'
import InboxPatientCardSimplified from '@/components/appdrawer/InboxPatientCardSimplified.vue'
import InboxToolbarSimplified from '@/components/appdrawer/InboxToolbarSimplified.vue'
import InboxSnapshot from './InboxSnapshot.vue'

export default {
  components: { InboxPatientCardSimplified, InboxToolbarSimplified, InboxSnapshot },
  props: {
    headerColor: {
      type: String,
      default: '#ffffff',
    },
  },
  data() {
    return {
      selectedCategory: 0,
      mode: 0,
      selected: [],
      selectedAlert: 0,
      page: 0,
      selectedAlertPatients: [],
      selectedMentions: [],
      selectedReviews: [],
      showList: false,
      waitingToScrollToPatient: false,
      patientToScrollTo: null,
      sortOptions: ['Latest Data', 'On Track', 'Coaching Time', 'Newest Enrollment'],
      selectedSort: 'Latest Data',
      fromClick: false,
      optionalInboxCategories: ['mentions', 'review', 'welcome', 'providerReview', 'followUp'],
    }
  },
  watch: {
    enrolledPatients: {
      handler: function () {
        if (this.selected.length > 0 && this.headsUpCategories[this.selectedAlert].list.length > 0) {
          let selectedCopy = JSON.parse(JSON.stringify(this.selected))
          // replace the patients in selected?
          let vm = this
          selectedCopy.forEach(function (value, i) {
            let newPatient = vm.headsUpCategories[vm.selectedAlert].list.findIndex(object => {
              return object.id === value.id
            })
            selectedCopy[i] = vm.headsUpCategories[vm.selectedAlert].list[newPatient]
          })

          this.selected = selectedCopy
        } else {
          this.selected = []
        }
      },
      deep: true,
      immediate: true,
    },
    // showList: {
    //   handler: function (val) {
    //     if (val) {
    //       if (this.$route.params.id) {
    //         setTimeout(() => {
    //           this.scrollToPatientInList(this.$route.params.id)
    //         }, 300)
    //       }
    //     }
    //   },
    //   immediate: true,
    // },
    patientsLoading(val) {
      if (this.settings.inboxCategories) {
        this.optionalInboxCategories = this.settings.inboxCategories
      }

      console.log('patients loading value', val)
      let vm = this
      if (!val) {
        setTimeout(() => {
          vm.showList = true
        }, 700)
      } else {
        vm.showList = false
      }
    },
    filters(val) {
      this.selected = []
      if (val && val.length > 0) {
        this.selectedAlert = 0
      }
    },
    patientTagFilters(val) {
      this.selected = []
      if (val && val.length > 0) {
        this.selectedAlert = 0
      }
    },
    selectedAlert() {
      this.selected = []
    },
  },
  computed: {
    ...mapState('rpm', ['orgDevices']),
    ...mapState('auth', ['user']),
    ...mapState('org', [
      'org',
      'patientsLoading',
      'enrolledPatients',
      'patientsDict',
      'unenrolledPatients',
      'users',
      'providerReviewNotifications',
      'careTeamReviewNotifications',
      'settings',
    ]),
    ...mapState('inbox', [
      'filters',
      'complexFilters',
      'tryPopulationView',
      'inboxCategorySync',
      'mentions',
      'orgNotificationLists',
      'orgNotificationsByPatient',
      'patientTagFilters',
    ]),

    sortedList() {
      let preSorting = this.headsUpCategories[this.selectedAlert].list

      if (this.selectedSort === 'Latest Data') {
        if (!this.headsUpCategories[this.selectedAlert].note) {
          preSorting.sort((a, b) => b.notificationTimestamp - a.notificationTimestamp)
        } else {
          preSorting.sort((a, b) => b.msOfMostRecentTimestamp - a.msOfMostRecentTimestamp)
        }
      }

      return preSorting
    },
    firstBoxTitle() {
      if (this.filters.length > 0 || this.patientTagFilters.length > 0) {
        return 'Filtered'
      }
      return 'Enrolled'
    },
    firstBoxColor() {
      if (this.filters.length > 0) {
        return 'primary'
      }
      return '#1A76F2'
    },
    headsUpCategories() {
      let categories = [
        {
          title: this.firstBoxTitle,
          icon: 'mdi-at',
          text: 'Total number of patients enrolled.',

          color: this.firstBoxColor,
          darkSelected: true,
          list: this.combinedPatientListFiltered,
          headers: this.headers,
        },
      ]

      categories.push({
        title: 'Alerts',
        icon: 'mdi-at',
        text: 'Unseen threshold breaches.',

        color: '#FFEB3B',
        darkSelected: false,
        list: this.orgNotificationLists.alerts ? this.orgNotificationLists.alerts : [],
        headers: this.headers,
      })

      if (this.optionalInboxCategories.includes('welcome')) {
        categories.push({
          title: 'Welcome',
          icon: 'mdi-at',
          text: 'New patients needing outreach.',
          color: '#1A76F2',
          darkSelected: true,
          list: this.orgNotificationLists.newPatients ? this.orgNotificationLists.newPatients : [],
          headers: this.headers,
        })
      }
      if (this.optionalInboxCategories.includes('mentions')) {
        categories.push({
          title: 'Mentions',
          icon: 'mdi-at',
          text: "Notes you've been mentioned in.",
          color: '#1A76F2',
          darkSelected: true,
          list: this.orgNotificationLists.mentions ? this.orgNotificationLists.mentions : [],
          headers: this.notesHeaders,
        })
      }

      if (this.optionalInboxCategories.includes('review')) {
        categories.push({
          title: 'Review',
          icon: 'mdi-at',
          text: 'Notes flagged for Care Team Review',
          count: 3,
          color: '#1A76F2',
          darkSelected: true,
          list: this.orgNotificationLists.careTeamReview ? this.orgNotificationLists.careTeamReview : [],
          headers: this.notesHeaders,
        })
      }

      if (this.optionalInboxCategories.includes('providerReview')) {
        categories.push({
          title: 'Provider Review',
          icon: 'mdi-at',
          text: 'Notes flagged for Provider Review',
          count: 3,
          color: '#1A76F2',
          darkSelected: true,
          list: this.orgNotificationLists.providerReview ? this.orgNotificationLists.providerReview : [],
          headers: this.notesHeaders,
        })
      }

      if (this.optionalInboxCategories.includes('followUp')) {
        categories.push({
          title: 'Follow Up',
          icon: 'mdi-at',
          text: 'Notes flagged follow up',
          count: 3,
          color: '#1A76F2',
          darkSelected: true,
          list: this.orgNotificationLists.ticklers ? this.orgNotificationLists.ticklers : [],
          headers: this.notesHeaders,
        })
      }

      return categories
    },
    calculatedHeight() {
      return Math.round(document.documentElement.clientHeight) - 218
    },
    headers() {
      let headers = [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'lastName',
          class: 'blue-grey lighten-5',
        },
        {
          text: 'Readings',
          align: 'center',
          sortable: true,
          value: 'daysThisPeriodWithReadings',
          width: 75,
          class: 'blue-grey lighten-5',
        },
        {
          text: 'Days Left',
          align: 'center',
          sortable: true,
          value: 'daysLeftToUse',
          width: 75,
          class: 'blue-grey lighten-5',
        },
        {
          text: 'Coaching',
          align: 'center',
          sortable: true,
          value: 'rpm.qhpTime.millisecondsThisPeriod',
          width: 75,
          class: 'blue-grey lighten-5',
        },
      ]
      return headers
    },
    notesHeaders() {
      let headers = [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'lastName',
          class: 'blue-grey lighten-5',
        },
        {
          text: 'Date',
          align: 'center',
          sortable: true,
          value: 'noteForDisplay.date',
          width: 75,
          class: 'blue-grey lighten-5',
        },
      ]
      return headers
    },
    combinedPatientList() {
      let arrayForSorting = JSON.parse(JSON.stringify(this.enrolledPatients))

      arrayForSorting.forEach(patient => {
        patient.eventsArray = this.getEventsArray(patient)
        let readingsCat = this.readingsCategory(patient)
        patient.perfectionNeededText = readingsCat.text
        patient.perfectionNeededColor = readingsCat.color
        patient.qhpMin = this.msToMinutesAndSeconds(patient.rpm.qhpTime.millisecondsThisPeriod)
      })

      if (this.selectedSort === 'Latest Data') {
        arrayForSorting = arrayForSorting.sort((a, b) => b.msOfMostRecentTimestamp - a.msOfMostRecentTimestamp)
      }

      if (this.selectedSort === 'On Track') {
        arrayForSorting = arrayForSorting.sort((a, b) => a.perfectionNeeded - b.perfectionNeeded)
      }

      if (this.selectedSort === 'Coaching Time') {
        arrayForSorting = arrayForSorting.sort(
          (a, b) => b.rpm.qhpTime.millisecondsThisPeriod - a.rpm.qhpTime.millisecondsThisPeriod
        )
      }

      if (this.selectedSort === 'Newest Enrollment') {
        arrayForSorting = arrayForSorting.sort((a, b) => b.enrollmentTimestamp - a.enrollmentTimestamp)
      }

      return arrayForSorting
    },
    combinedPatientListFiltered() {
      let arrayForSorting = JSON.parse(JSON.stringify(this.enrolledPatients))

      arrayForSorting.forEach(patient => {
        patient.eventsArray = this.getEventsArray(patient)
        let readingsCat = this.readingsCategory(patient)
        patient.perfectionNeededText = readingsCat.text
        patient.perfectionNeededColor = readingsCat.color
        patient.qhpMin = this.msToMinutesAndSeconds(patient.rpm.qhpTime.millisecondsThisPeriod)
      })

      if (this.complexFilters && this.complexFilters.length) {
        this.complexFilters.forEach(filterObject => {
          if (filterObject.tags.length > 0) {
            // respond to filters
            let filterList = filterObject.tags
            arrayForSorting = arrayForSorting.filter(function (e) {
              return filterList.includes(e.sort[filterObject.name].toString())
            })
          }
        })
      }

      ///

      if (this.patientTagFilters.length > 0) {
        let vm = this
        arrayForSorting = arrayForSorting.filter(function (e) {
          return e.tags.some(r => vm.patientTagFilters.includes(r))
        })
      }

      if (this.selectedSort === 'Latest Data') {
        arrayForSorting = arrayForSorting.sort((a, b) => b.msOfMostRecentTimestamp - a.msOfMostRecentTimestamp)
      }

      if (this.selectedSort === 'On Track') {
        arrayForSorting = arrayForSorting.sort((a, b) => a.perfectionNeeded - b.perfectionNeeded)
      }

      if (this.selectedSort === 'Coaching Time') {
        arrayForSorting = arrayForSorting.sort(
          (a, b) => b.rpm.qhpTime.millisecondsThisPeriod - a.rpm.qhpTime.millisecondsThisPeriod
        )
      }

      if (this.selectedSort === 'Newest Enrollment') {
        arrayForSorting = arrayForSorting.sort((a, b) => b.enrollmentTimestamp - a.enrollmentTimestamp)
      }

      return arrayForSorting
    },
    notesForCareTeamReview() {
      let notifications = []
      if (
        this.enrolledPatients.length > 0 &&
        this.careTeamReviewNotifications &&
        this.careTeamReviewNotifications.length > 0
      ) {
        this.careTeamReviewNotifications.forEach(notification => {
          let patient = this.enrolledPatients.find(pt => pt.id === notification.patient)

          let notificationObject = {}
          notificationObject.patient = patient

          notificationObject.firstName = patient.firstName
          notificationObject.lastName = patient.lastName
          notificationObject.org = { patientId: patient.org.patientId }
          notificationObject.notificationTimestamp = notification.notificationTimestamp
          // get the text of the note

          notificationObject.noteForDisplay = this.generateNoteForDisplay(patient, notification)
          notificationObject.firebaseNotification = notification

          notifications.push(notificationObject)
        })
      }
      return notifications
    },
    notesForProviderReview() {
      let notifications = []
      if (
        this.enrolledPatients.length > 0 &&
        this.providerReviewNotifications &&
        this.providerReviewNotifications.length > 0
      ) {
        this.providerReviewNotifications.forEach(notification => {
          let patient = this.enrolledPatients.find(pt => pt.id === notification.patient)

          let notificationObject = {}
          notificationObject.patient = patient

          notificationObject.firstName = patient.firstName
          notificationObject.lastName = patient.lastName
          notificationObject.org = { patientId: patient.org.patientId }
          notificationObject.notificationTimestamp = notification.notificationTimestamp
          // get the text of the note

          notificationObject.noteForDisplay = this.generateNoteForDisplay(patient, notification)
          notificationObject.firebaseNotification = notification

          notifications.push(notificationObject)
        })
      }
      return notifications
    },
    mentionsList() {
      let notifications = []
      if (this.enrolledPatients.length > 0 && this.mentions) {
        this.mentions.forEach(notification => {
          let patient = this.enrolledPatients.find(pt => pt.id === notification.patient)
          if (patient) {
            let notificationObject = {}
            notificationObject.patient = patient

            notificationObject.firstName = patient.firstName
            notificationObject.lastName = patient.lastName
            notificationObject.org = { patientId: patient.org.patientId }
            notificationObject.notificationTimestamp = notification.notificationTimestamp
            // get the text of the note

            notificationObject.noteForDisplay = this.generateNoteForDisplay(patient, notification)
            notificationObject.firebaseNotification = notification

            notifications.push(notificationObject)
          }
        })
      }

      return notifications
    },
    devicesByPatient() {
      let dict = {}
      this.orgDevices.forEach(device => {
        device.name = device.kind
        if (consts.DEVICES[device.kind]) {
          device.name = consts.DEVICES[device.kind].name
        }
        if (device.patientId && device.live) {
          if (dict[device.patientId]) {
            dict[device.patientId].push(device)
          } else {
            dict[device.patientId] = []
            dict[device.patientId].push(device)
          }
        }
      })
      return dict
    },
    isViewingUnassigned() {
      return true
    },
    orgUserDictionary() {
      const dict = {}
      if (this.users && this.users.length > 0) {
        this.users.forEach(user => {
          dict[user.id] = { name: 'Unknown', initials: 'UK' }
          if (user.firstName && user.lastName) {
            dict[user.id].name = user.firstName + ' ' + user.lastName
            dict[user.id].initials = user.firstName.charAt(0) + user.lastName.charAt(0)
          }
        })
      }
      return dict
    },
    enrolledPatientDictionary() {
      let dict = {}

      this.enrolledPatients.forEach(patient => {
        dict[patient.id] = patient
      })
      return dict
    },
    orgDevicesCorrelatedWithEnrolledPatients() {
      let rawDevices = this.orgDevices

      rawDevices.forEach(device => {
        device.status = '-'
        device.age = '-'
        device.orgID = '-'
        device.firstName = '-'
        device.lastName = '-'
        if (device.live && this.enrolledPatientDictionary[device.patientId]) {
          device.firstName = this.enrolledPatientDictionary[device.patientId].firstName
          device.lastName = this.enrolledPatientDictionary[device.patientId].lastName
          device.orgID = this.enrolledPatientDictionary[device.patientId].org.patientId
          device.enrolled = Date.parse(this.enrolledPatientDictionary[device.patientId].rpm.enrollment.start)
          device.enrolledHumanReadable = dayjs(
            Date.parse(this.enrolledPatientDictionary[device.patientId].rpm.enrollment.start)
          ).format('MM/DD/YYYY')
          device.status = 'Assigned'
          const date1 = dayjs()
          device.age = date1.diff(
            Date.parse(this.enrolledPatientDictionary[device.patientId].rpm.enrollment.start),
            'month'
          )
          device.assignmentEnrolled
          device.patientDetail =
            this.enrolledPatientDictionary[device.patientId].firstName.charAt(0) +
            '. ' +
            this.enrolledPatientDictionary[device.patientId].lastName
        } else if (device.live && !this.enrolledPatientDictionary[device.patientId]) {
          device.patientDetail = 'Assigned to an unenrolled Patient'
          device.issue = true
        } else if (device.live) {
          device.patientDetail = device.patientId
        }
      })
      return rawDevices
    },
    arrayToUse() {
      let live = this.orgDevicesCorrelatedWithEnrolledPatients.filter(function (e) {
        return e.live
      })

      let notLive = this.orgDevicesCorrelatedWithEnrolledPatients.filter(function (e) {
        return !e.live
      })

      return [
        {
          count: live.length,
          color: '#1A76F2',
          title: 'Assigned Devices',
          list: live,
          fullTitle: 'assigned',
          icon: 'mdi-thumb-up',
          total: Math.floor((live.length / this.orgDevicesCorrelatedWithEnrolledPatients.length) * 100),
          description: 'Devices currently assigned to patients',
        },
        {
          count: notLive.length,
          color: '#454545',
          title: 'Unassigned Devices',
          list: notLive,
          fullTitle: 'not assigned',
          icon: 'mdi-thumb-up',
          total: Math.floor((notLive.length / this.orgDevicesCorrelatedWithEnrolledPatients.length) * 100),
          description: 'Devices not currently assigned to patients',
        },
      ]
    },
    percentOfDaysRemainingNeeded() {
      let doingGreat = this.enrolledPatients.filter(function (e) {
        return !e.possibleOffTrack && !e.unenrollCandidate
      })

      let unenrollCandidates = this.enrolledPatients.filter(function (e) {
        return e.unenrollCandidate
      })

      let includedPatients = this.enrolledPatients.filter(function (e) {
        return e.possibleOffTrack && !e.unenrollCandidate
      })

      // let zeroTo25 = includedPatients.filter(function(e) {
      //   return e.perfectionNeeded <= 70
      // })

      let zeroTo50 = includedPatients.filter(function (e) {
        return e.perfectionNeeded <= 80
      })

      // let twentySixTo50 = includedPatients.filter(function(e) {
      //   return e.perfectionNeeded > 70 && e.perfectionNeeded <= 80
      // })

      // let fiftyOneTo75 = includedPatients.filter(function(e) {
      //   return e.perfectionNeeded > 80 && e.perfectionNeeded <= 90
      // })

      // let seventySixTo100 = includedPatients.filter(function(e) {
      //   return e.perfectionNeeded > 90 && e.perfectionNeeded <= 100
      // })

      let severeRisk = includedPatients.filter(function (e) {
        return e.perfectionNeeded > 80 && e.perfectionNeeded <= 100
      })

      let notAChance = includedPatients.filter(function (e) {
        return e.perfectionNeeded > 100
      })

      let totals = [
        {
          count: doingGreat.length,
          color: '#1A76F2',
          title: 'On Track',
          list: doingGreat,
          fullTitle: 'On Track',
          icon: 'mdi-thumb-up',
          total: Math.floor((doingGreat.length / this.enrolledPatients.length) * 100),
        },
        // {
        //   count: zeroTo25.length,
        //   color: '#FBC02D',
        //   title: 'At Risk',
        //   list: zeroTo25,
        //   fullTitle: 'Concerning',
        //   icon: 'mdi-alert-circle',
        //   total: Math.floor((zeroTo25.length / this.enrolledPatients.length) * 100),
        // },
        {
          count: zeroTo50.length,
          color: '#F9A825',
          title: 'Moderate Risk',
          list: zeroTo50,
          fullTitle: 'Moderate Risk',
          icon: 'mdi-alert-circle',
          total: Math.floor((zeroTo50.length / this.enrolledPatients.length) * 100),
        },
        // {
        //   count: fiftyOneTo75.length,
        //   color: '#F57F17',
        //   title: 'Serious Risk',
        //   list: fiftyOneTo75,
        //   fullTitle: 'Serious Risk',
        //   icon: 'mdi-alert-circle',
        //   total: Math.floor((fiftyOneTo75.length / this.enrolledPatients.length) * 100),
        // },
        {
          count: severeRisk.length,
          color: '#E53935',
          title: 'Severe Risk',
          list: severeRisk,
          fullTitle: 'Severe Risk',
          icon: 'mdi-alert-circle',
          total: Math.floor((severeRisk.length / this.enrolledPatients.length) * 100),
        },
        {
          count: notAChance.length,
          color: '#424242',
          title: 'Ineligible',
          list: notAChance,
          fullTitle: 'Ineligible',
          icon: 'mdi-alert-octagon',
          total: Math.floor((notAChance.length / this.enrolledPatients.length) * 100),
        },
        {
          count: unenrollCandidates.length,
          color: '#000000',
          title: 'Inactive',
          list: unenrollCandidates,
          fullTitle: 'Inactive',
          icon: 'mdi-close-circle',
          total: Math.floor((unenrollCandidates.length / this.enrolledPatients.length) * 100),
        },
      ]

      return totals
    },
    actuals() {
      let includedPatients = this.enrolledPatients.filter(function (e) {
        return e.possibleOffTrack
      })
      let startOfToday = new Date()
      startOfToday.setHours(0, 0, 0, 0)

      let todayTotal = includedPatients.filter(function (e) {
        return e.mostRecentDataTimestamp >= startOfToday.getTime()
      })

      let startOfYesterday = new Date()
      startOfYesterday.setDate(startOfToday.getDate() - 1)
      startOfYesterday.setHours(0, 0, 0, 0)

      let yesterdayTotal = includedPatients.filter(function (e) {
        return e.mostRecentDataTimestamp >= startOfYesterday.getTime() && e.mostRecentDataTimestamp < startOfToday
      })

      let startOfSeven = new Date()
      startOfSeven.setDate(startOfToday.getDate() - 7)
      startOfSeven.setHours(0, 0, 0, 0)

      let sevenTotal = includedPatients.filter(function (e) {
        return e.mostRecentDataTimestamp >= startOfSeven.getTime() && e.mostRecentDataTimestamp < startOfYesterday
      })

      let startOfThirty = new Date()
      startOfThirty.setDate(startOfToday.getDate() - 30)
      startOfThirty.setHours(0, 0, 0, 0)

      let thirtyTotal = includedPatients.filter(function (e) {
        return e.mostRecentDataTimestamp >= startOfThirty.getTime() && e.mostRecentDataTimestamp < startOfSeven
      })

      let moreThanThirty = includedPatients.filter(function (e) {
        return e.mostRecentDataTimestamp < startOfThirty.getTime()
      })

      let neverTotal = includedPatients.filter(function (e) {
        return !e.mostRecentDataTimestamp
      })

      let totals = [
        {
          count: todayTotal.length,
          color: '#1A76F2',
          title: 'Today',
          list: todayTotal,
          fullTitle: 'Active Today',
        },
        {
          count: yesterdayTotal.length,
          color: '#F9A825',
          title: 'Yesterday',
          list: yesterdayTotal,
          fullTitle: 'Last Active Yesterday',
        },
        {
          count: sevenTotal.length,
          color: '#F57F17',
          title: '3-7 Days',
          list: sevenTotal,
          fullTitle: 'Last Active 3-7 Days Ago',
        },
        {
          count: thirtyTotal.length,
          color: '#F57F17',
          title: '8-30 Days',
          list: thirtyTotal,
          fullTitle: 'Last Active 8-30 Days Ago',
        },
        {
          count: moreThanThirty.length,
          color: '#E53935',
          title: '31+ Days',
          list: moreThanThirty,
          fullTitle: 'Last Active more than 30 Days Ago',
        },
        {
          count: neverTotal.length,
          color: '#000000',
          title: 'Never',
          list: neverTotal,
          fullTitle: 'Never Active',
        },
      ]

      return totals
    },
    chartOptions() {
      const vm = this
      let options = {
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: false,
                name: {
                  show: false,
                },
                value: {
                  formatter: function (item) {
                    return Math.ceil((item / vm.patientCount) * 100) + '%'
                  },
                },
              },
            },
          },
        },
        chart: {
          type: 'donut',
          events: {
            dataPointSelection: function (_, chartContext, config) {
              vm.selectedCategory = config.dataPointIndex
            },
            dataPointMouseEnter: function (event) {
              event.path[0].style.cursor = 'pointer'
            },
          },
        },
        labels: this.arrayToUse.map(x => x.title),
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        colors: this.arrayToUse.map(x => x.color),
      }
      return options
    },
    chartData() {
      return this.arrayToUse.map(x => x.count)
    },
    patientCount() {
      return this.arrayToUse.map(x => x.count).reduce((a, b) => a + b)
    },
  },
  methods: {
    ...mapActions('rpm', [
      'assignDevice',
      'getPatientIHealthOauthFlow',
      'getPatient',
      'getOrgDevices',
      'getPatientDevices',
    ]),
    ...mapMutations('inbox', ['toggleInboxFilter', 'clearInboxFilters']),
    backToInbox() {
      if (this.$route.name !== 'Inbox') {
        this.$router.push({ name: 'Inbox' })
      }
    },
    handleClick(patient) {
      if (this.$route.params.id === patient.id) {
        this.$router.push({ name: 'Inbox' })
      } else {
        this.fromClick = true
        this.$router.push({ name: 'PatientFeed', params: { id: patient.id } })
      }
    },
    scrollToPatientInList(patientId) {
      if (document.getElementById(patientId)) {
        console.log('scrolling', patientId)
        let scrollbox = document.getElementById('inboxScrollbox')
        var element = document.getElementById(patientId)
        var headerOffset = 200
        var elementPosition = element.getBoundingClientRect().top
        var offsetPosition = elementPosition + scrollbox.scrollTop - headerOffset
        scrollbox.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        })
      } else {
        this.waitingToScrollToPatient = true
        this.patientToScrollTo = patientId
      }
    },
    toggleSelectAll() {
      if (this.selected.length === this.headsUpCategories[this.selectedAlert].list.length) {
        this.selected = []
      } else {
        this.selected = this.headsUpCategories[this.selectedAlert].list
      }
    },
    toggleSelected(patient) {
      if (this.selected.includes(patient)) {
        this.selected = this.selected.filter(e => e !== patient)
      } else {
        this.selected.push(patient)
      }
    },
    generateNoteForDisplay(patientObject, notification) {
      if (patientObject.rpm.notes.length > 0) {
        let timestampOfCorrectNote = notification.ts
        let latestNote = patientObject.rpm.notes[0]

        if (latestNote.ts !== timestampOfCorrectNote) {
          patientObject.rpm.notes.forEach(note => {
            if (note.ts === timestampOfCorrectNote) {
              latestNote = note
            }
          })
        }

        if (notification.comment) {
          if (latestNote.comments.length > 0) {
            latestNote = latestNote.comments[0]
          }
        }
        let displayObject = { text: 'Note', author: 'Author', date: 'date' }

        if (this.orgUserDictionary[latestNote.userId]) {
          displayObject.author = this.orgUserDictionary[latestNote.userId].name
        }
        displayObject.text = latestNote.text
        displayObject.date = notification.notificationTimestamp
        return displayObject
      }

      return null
    },
    getEventsArray(listPatient) {
      const events = []
      for (const [k, v] of Object.entries(listPatient.rpm.lastReadings)) {
        if (dayjs().diff(v.timestamp, 'days') >= 182) continue
        v.unreadAlert = listPatient.rpm.newAlertsArray.includes(k)
        events.push(v)
      }
      return events.sort((a, b) => {
        if (a.unreadAlert > b.unreadAlert) return -1
        if (a.unreadAlert < b.unreadAlert) return 1
        if (a.tsMs > b.tsMs) return -1
        if (a.tsMs < b.tsMs) return 1
        return 0
      })
    },
    row_classes() {
      return 'orangeish'
    },
    readingsCategory(patient) {
      let category = patient.sort.onTrackCategory
      let details = consts.sortingCategories.engagementPerfectionNeeded.find(e => e.tag === category)
      if (details) {
        let color = '#4485ed'
        if (details.color === 0) {
          color = consts.colorsForGraphs.success[0]
        }
        if (details.color === 1) {
          color = consts.colorsForGraphs.warning[2]
        }
        if (details.color === 2) {
          color = consts.colorsForGraphs.failure[0]
        }
        if (details.color > 2) {
          color = consts.colorsForGraphs.issue[0]
        }
        return { text: details.name, color: color }
      }
      return { text: null, color: null, dark: false }
    },
    msToMinutesAndSeconds,
    translateTheDate(date) {
      return dayjs(date).format('MM/DD/YYYY')
    },
    exportTheList() {
      createDeviceCSV(
        this.orgDevicesCorrelatedWithEnrolledPatients,
        this.org.name + ' Device List ' + dayjs().format('MM/DD/YYYY')
      )
    },
    cardClick(cat) {
      this.selectedCategory = cat
    },
    graphClick() {},
  },
  async mounted() {
    if (!this.patientsLoading && this.enrolledPatients.length > 0) {
      this.showList = true
    }
  },
}
</script>

<style scoped>
.headerClassDave {
  background-color: blue !important;
}
.row-pointer >>> .v-data-table__expanded {
  box-shadow: none !important;
  background-color: transparent;
}
.row-pointer >>> .v-data-table__expanded__row:hover {
  background-color: green;
  cursor: pointer;
}
.row-pointer >>> .v-data-table__expanded__row td {
  background-color: transparent;
  border: 0px solid green !important;
}
.topCard {
  border-radius: 6px;
  box-shadow: 0px 0.3px 1.2px -3px rgba(0, 0, 0, 0.014), 0px 0.7px 2.9px -3px rgba(0, 0, 0, 0.02),
    0px 1.3px 5.5px -3px rgba(0, 0, 0, 0.025), 0px 2.2px 9.8px -3px rgba(0, 0, 0, 0.03),
    0px 4.2px 18.4px -3px rgba(0, 0, 0, 0.036), 0px 10px 44px -3px rgba(0, 0, 0, 0.05);
}
</style>

<style>
.row-pointer >>> tbody tr {
  border-bottom: medium solid rgba(0, 0, 0, 0.12);
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.orangeish {
  background-color: white;
  border: 10px solid green;
}
</style>
