<template>
  <v-autocomplete
    v-model="patientSelection"
    :loading="pendingSearch"
    :items="filteredLocalPatients"
    :search-input.sync="patientSearchText"
    hide-details
    solo
    flat
    prepend-inner-icon="mdi-magnify"
    placeholder="Patient Search"
    @keyup="conductGlobalSearch"
    no-filter
    clearable
    :allow-overflow="false"
    ref="searchBar"
    @blur="$emit('blurred')"
    dense
    full-width
  >
    <template v-slot:append>
      <v-btn
        icon
        :color="includeArchived ? 'primary' : 'grey lighten-2'"
        text
        @click="includeArchived = !includeArchived"
      >
        <v-icon>mdi-archive-outline</v-icon>
      </v-btn>
    </template>

    <template v-slot:item="{ item }">
      <div v-if="item.value.rpm && item.value.rpm.enrollment && item.value.rpm.enrollment.enrolled" class="py-1">
        <div style="width: 200px" class="text-truncate">
          {{ item.value.firstName }}
          <span style="font-weight: 700">{{ item.value.lastName }}</span
          ><span class="text--secondary"> {{ item.value.org.patientId }}</span>
        </div>
        <div v-if="item.value.primaryPhone || item.value.smsPhone" class="text-caption text--secondary">
          <span v-if="item.value.primaryPhone" class="mr-1">{{ item.value.primaryPhone }}</span
          ><span v-if="item.value.smsPhone">{{ item.value.smsPhone }}</span>
        </div>
        <div v-else class="text-caption text--secondary">
          <span class="red--text">Missing Phone Number</span>
        </div>
      </div>
      <div v-else class="py-1" style="opacity: 0.6">
        <div style="width: 240px" class="text-truncate">
          {{ item.value.firstName }}
          <span style="font-weight: 700">{{ item.value.lastName }}</span
          ><span class="text--secondary"> {{ item.value.org.patientId }}</span>
        </div>
        <div v-if="item.value.primaryPhone || item.value.smsPhone" class="text-caption text--secondary">
          <span v-if="item.value.primaryPhone" class="mr-1">{{ item.value.primaryPhone }}</span
          ><span v-if="item.value.smsPhone">{{ item.value.smsPhone }}</span>
        </div>
        <div v-else class="text-caption text--secondary">
          <span class="red--text">Missing Phone Number</span>
        </div>
      </div>
    </template>
    <template v-slot:no-data>
      <v-row no-gutters align="center" justify="center">
        <div style="max-width: 180px">
          <v-row v-if="!pendingSearch" no-gutters justify="center">
            <v-col>
              <v-row class="pt-3" justify="center" no-gutters>
                <v-icon style="opacity: 0.3" size="50">mdi-account-search </v-icon>
              </v-row>
              <div style="height: 80px">
                <v-row class="fill-height px-5" no-gutters align="center" justify="center">
                  <span
                    style="text-align: center; opacity: 0.5"
                    v-if="patientSearchText === null || patientSearchText === ''"
                    >Search by last name or phone number</span
                  >
                  <span style="text-align: center; opacity: 0.5" v-else>No matching patients</span>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="pendingSearch" no-gutters justify="center">
            <v-col>
              <v-row class="pt-3" justify="center" no-gutters>
                <v-icon style="opacity: 0.3" size="50">mdi-database-search </v-icon>
              </v-row>
              <div style="height: 80px">
                <v-row class="fill-height px-5" no-gutters align="center" justify="center">
                  <span style="text-align: center; opacity: 0.5">Searching Globally</span>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-row>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  data() {
    return {
      patientSearchText: null,
      cardHeight: 64,
      timeout: null,
      pendingSearch: false,
      globalResults: null,
      expandedPanel: true,
      searchFocused: false,
      patientSelection: null,
      includeArchived: false,
    }
  },
  props: {
    focusOnShow: {
      type: Boolean,
      default: true,
    },
    orgsDict: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    patientSearchText(val) {
      this.globalResults = null
      if (val && val.length > 0) {
        this.expandedPanel = true
      }
    },
    patientSelection(val) {
      if (val) {
        this.$refs.searchBar.blur()
        this.goToPatient(val)
        this.$refs.searchBar.reset()
      }
    },
  },
  methods: {
    ...mapActions('patient', ['globalPatientSearch']),
    openSearch() {
      this.$refs.searchBar.focus()
    },
    rollup() {
      this.expandedPanel = false
      this.searchFocused = false
    },
    expand() {
      this.expandedPanel = true
      this.searchFocused = true
    },
    conductGlobalSearch() {
      this.pendingSearch = true
      this.globalResults = null
      // can this be called only when the patient is done? can we cancel the last
      clearTimeout(this.timeout)

      // Make a new timeout set to go off in 1000ms (1 second)
      let vm = this
      this.timeout = setTimeout(async function () {
        if (vm.patientSearchText && vm.patientSearchText.length > 0) {
          let results = await vm.globalPatientSearch(vm.patientSearchText)

          vm.globalResults = results
          vm.pendingSearch = false
          vm.expandedPanel = true
          clearTimeout(vm.timeout)
        } else {
          vm.pendingSearch = false
          clearTimeout(vm.timeout)
          this.expandedPanel = false
        }
      }, 1000)
    },
    expandCard() {
      this.cardHeight = 200
    },
    contractCard() {
      this.cardHeight = 64
    },
    goToPatient(patient) {
      this.$emit('searchResultPatient', patient)
    },
  },
  computed: {
    ...mapState('org', [
      'org',
      'patientsLoading',
      'enrolledPatients',
      'unreadConvosCount',
      'unenrolledPatients',
      'providerReviewNotifications',
      'careTeamReviewNotifications',
      'users',
      'mentions',
    ]),
    filteredLocalPatients() {
      let arrayForSorting = []
      if (!this.org || !this.org.id) {
        return []
      }
      if (this.patientSearchText !== null && this.patientSearchText !== '') {
        arrayForSorting = this.enrolledPatients.concat(this.unenrolledPatients)
        const rgx = new RegExp(this.patientSearchText.replace('-', '').replace(')', '').replace('(', ''), 'i')
        arrayForSorting = arrayForSorting.filter(function (e) {
          const toTest = [
            e.firstName,
            e.lastName,
            e.org?.patientId || '',
            e.primaryPhone.replace('-', '').replace(')', '').replace('(', '') || '',
            e.smsPhone.replace('-', '').replace(')', '').replace('(', '') || '',
            e.displayBirthdate || '',
            e.fullName || '',
          ].join('|')
          return rgx.test(toTest)
        })
      }

      let results = []
      if (arrayForSorting.length > 0) {
        let localOrg = {
          id: this.org.id,
          results: arrayForSorting.filter(e => e.rpm && e.rpm.enrollment && e.rpm.enrollment.enrolled),
          allResults: arrayForSorting,
          local: true,
        }

        results.push(localOrg)
      }

      if (this.globalResults !== null) {
        //append the global results
        let remoteOrgs = {}

        this.globalResults.data.patients.forEach(patient => {
          if (remoteOrgs[patient.org.id]) {
            remoteOrgs[patient.org.id].push(patient)
          } else {
            remoteOrgs[patient.org.id] = [patient]
          }
        })

        Object.keys(remoteOrgs).forEach(org => {
          let remoteOrg = {
            id: org,
            results: remoteOrgs[org].filter(e => e.rpm && e.rpm.enrollment && e.rpm.enrollment.enrolled),
            allResults: remoteOrgs[org],
          }
          if (org !== this.org.id) {
            results.push(remoteOrg)
          }
        })
      }

      let autocompleteResults = []

      results.forEach(org => {
        if (this.includeArchived) {
          if (org.allResults.length > 0) {
            autocompleteResults.push({ header: this.orgsDict[org.id].name })
            org.allResults.forEach(patient => {
              autocompleteResults.push({ text: patient.lastName, value: patient })
            })
          }
        } else {
          if (org.results.length > 0) {
            autocompleteResults.push({ header: this.orgsDict[org.id].name })
            org.results.forEach(patient => {
              autocompleteResults.push({ text: patient.lastName, value: patient })
            })
          }
        }
      })

      return autocompleteResults
    },
  },
  mounted() {},
}
</script>

<style>
.header {
  position: sticky;
  z-index: 250;
  top: -1px;
  background-color: black;
  color: white;
}
</style>
