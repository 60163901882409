import store from '@/store'

export default {
  async beforeEnter(to, from, next) {
    // Wait for auth to be ready, i.e. to receive first hook from firebase.auth.onAuthStateChanged
    // auth.ready only changes once, so we only potentially need to watch once at the very beginning
    // otherwise, every subsequent check can be quick
    await new Promise( resolve => {
      if (store.state.auth.ready) return resolve() // quick check before setting up a watch
      store.watch((state) => state.auth.ready, () => { resolve() }) // set a watch for once auth is ready
      if (store.state.auth.ready) return resolve() // check one last time in case of race condition between first check and setting watch
    })

    if (store.state.auth.user) {
      next()
    } else {
      // Not authenticated. Go to login, add a redirect_after_login param if this access was trying to go somewhere.
      if (to.path === '/') {
        next({ name: 'Login', replace: true })
      } else {
        next({ name: 'Login', replace: true, query: { redirect_after_login: encodeURI(location.href.split(location.origin).join('')) } })
      }
    }
  }
}
