import { HttpClient } from '../../helpers/httpClient'
import { beforeRequestHandler } from '../sso'

if (!process.env.VUE_APP_ORGS_API_URL) {
  throw new Error('VUE_APP_ORGS_API_URL not defined in .env')
}

const skipAuth = ['GetOrgs']

export const orgsClient = HttpClient.create({
  baseUrl: process.env.VUE_APP_ORGS_API_URL,
  beforeRequestHandler(ctx, request) {
    if (ctx.url.includes(skipAuth)) {
      return request
    }
    return beforeRequestHandler(ctx, request)
  },
})
