import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'

export default {
  _setOrg(state, org) {
    state._org = org
  },
  _setPatient(state, patient) {
    state._patient = patient
  },
  setConversation(state, conversation) {
    state.conversation = conversation
  },
  setConversations(state, conversations) {
    state.conversations = conversations
  },
  setMessages(state, messages) {
    messages = uniqBy(messages, 'id')
    messages = sortBy(messages, 'created')
    state.messages = messages
  },
  setUnreadMessagesCount(state, count) {
    state.unreadMessagesCount = count
  },
  incMessengerOpenCount(state) {
    state.messengerOpenCount++
  },
  decMessengerOpenCount(state) {
    let count = state.messengerOpenCount - 1
    if (count < 0) {
      console.warn('messenger count was less than 0')
      count = 0
    }
    state.messengerOpenCount = count
  },
}