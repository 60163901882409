import moment from 'moment-timezone'
import dayjs from 'dayjs'

// Converts rfc3339 timestamp to "MM/DD/YYYY"
export function rfc3339ToUSDate(ts) {
  return ts.substring(5, 7) + '/' + ts.substring(8, 10) + '/' + ts.substring(0, 4)
}

// Converts milliseconds timestamp to "MM/DD/YYYY HH:MM [am|pm]"
export function msToUSDateTime(tsMs) {
  const d = new Date(tsMs)
  const hours = String(d.getHours() % 12 || 12).padStart(2, '0') // zero pads AND converts 24-hour clock to 12-hour
  const mins = String(d.getMinutes()).padStart(2, '0')
  const ampm = d.getHours() >= 12 ? 'pm' : 'am'
  const month = String(d.getMonth() + 1).padStart(2, '0')
  const day = String(d.getDate()).padStart(2, '0')
  return `${month}/${day}/${d.getFullYear()} ${hours}:${mins} ${ampm}`
}

// Converts milliseconds to "12:34:56"
export function msToTime(ms) {
  if (!ms) return '00:00:00'
  let seconds = Math.floor((ms / 1000) % 60)
  let minutes = Math.floor((ms / (1000 * 60)) % 60)
  let hours = Math.floor(ms / (1000 * 60 * 60))

  hours = hours < 10 ? '0' + hours : hours
  minutes = minutes < 10 ? '0' + minutes : minutes
  seconds = seconds < 10 ? '0' + seconds : seconds

  return hours + ':' + minutes + ':' + seconds
}

export function msToMinutesAndSeconds(ms) {
  if (!ms) return '00:00'
  let seconds = Math.floor((ms / 1000) % 60)
  let minutes = Math.floor((ms / (1000 * 60)) % 60)
  let hours = Math.floor(ms / (1000 * 60 * 60))

  minutes = minutes + hours * 60

  minutes = minutes < 10 ? '0' + minutes : minutes
  seconds = seconds < 10 ? '0' + seconds : seconds

  return minutes + ':' + seconds
}

// Converts milliseconds to "12h 34m 56s"
export function msToTime2(ms) {
  if (!ms) {
    return '0h 0m 0s'
  }
  let seconds = Math.floor((ms / 1000) % 60)
  let minutes = Math.floor((ms / (1000 * 60)) % 60)
  let hours = Math.floor(ms / (1000 * 60 * 60))

  hours = hours < 10 ? '' + hours : hours
  minutes = minutes < 10 ? '' + minutes : minutes
  seconds = seconds < 10 ? '' + seconds : seconds

  return hours + 'h ' + minutes + 'm ' + seconds + 's'
}

export function relativeTime(value) {
  if (value) {
    let dt = moment(value).tz(moment.tz.guess()).format('MM/DD/YYYY h:mm a')
    if (dt.includes('12:00 am')) {
      return 'Today'
    } else {
      return moment(value).tz(moment.tz.guess()).fromNow()
    }
  }
  return '-'
}

export function specificRelativeTime(value) {
  // if it is today then a minutes/hours ago is fine.
  //if it is yesterday, then yesterday at 459pm is fine
  //if it is before then, the date atnd time
  if (value) {
    let startOfToday = new Date()
    startOfToday.setHours(0, 0, 0, 0)
    let todayCutoff = startOfToday.getTime()

    let startOfYesterday = new Date()
    startOfYesterday.setDate(startOfYesterday.getDate() - 1)
    startOfYesterday.setHours(0, 0, 0, 0)
    let yesterdayCutoff = startOfYesterday.getTime()

    if (new Date().getTime() - value < 60 * 40 * 1000) {
      return moment(value).tz(moment.tz.guess()).fromNow()
    }

    if (value > todayCutoff) {
      return moment(value).tz(moment.tz.guess()).format('h:mm a')
    }

    if (value > yesterdayCutoff) {
      return 'Yesterday'
      // return 'Yesterday at ' + moment(value).tz(moment.tz.guess()).format('h:mm a')
    }
    return moment(value).tz(moment.tz.guess()).format('MM/DD/YYYY')
    // return moment(value).tz(moment.tz.guess()).format('MM/DD/YYYY h:mm a')
  }
  return '-'
}

export const daysPast = days => {
  const inThePast = new Date()
  inThePast.setDate(new Date().getDate() - days) // 30 days
  return inThePast
}

export function specificRelativeTimeWithHoursAndMinutes(value) {
  // if it is today then a minutes/hours ago is fine.
  //if it is yesterday, then yesterday at 459pm is fine
  //if it is before then, the date atnd time
  if (value) {
    let startOfToday = new Date()
    startOfToday.setHours(0, 0, 0, 0)
    let todayCutoff = startOfToday.getTime()

    let startOfYesterday = new Date()
    startOfYesterday.setDate(startOfYesterday.getDate() - 1)
    startOfYesterday.setHours(0, 0, 0, 0)
    let yesterdayCutoff = startOfYesterday.getTime()

    if (new Date().getTime() - value < 60 * 40 * 1000) {
      return moment(value).tz(moment.tz.guess()).fromNow()
    }

    if (value > todayCutoff) {
      return 'Today at ' + moment(value).tz(moment.tz.guess()).format('h:mm a')
    }

    if (value > yesterdayCutoff) {
      return 'Yesterday at ' + moment(value).tz(moment.tz.guess()).format('h:mm a')
    }
    return moment(value).tz(moment.tz.guess()).format('MM/DD/YYYY h:mm a')
  }
  return '-'
}

export function specificRelativeTimeJustDate(rawValue) {
  // if it is today then a minutes/hours ago is fine.
  //if it is yesterday, then yesterday at 459pm is fine
  //if it is before then, the date atnd time
  let value = rawValue
  if (!Number.isInteger(rawValue)) {
    value = Date.parse(rawValue)
  }
  if (value) {
    let startOfToday = new Date()
    startOfToday.setHours(0, 0, 0, 0)
    let todayCutoff = startOfToday.getTime()

    let startOfYesterday = new Date()
    startOfYesterday.setDate(startOfYesterday.getDate() - 1)
    startOfYesterday.setHours(0, 0, 0, 0)
    let yesterdayCutoff = startOfYesterday.getTime()

    if (value > todayCutoff) {
      return 'Today'
    }

    if (value > yesterdayCutoff) {
      return 'Yesterday'
    }

    if (dayjs(value).format('YYYY') !== dayjs().format('YYYY')) {
      return dayjs(value).format('M/D/YY')
    }
    return dayjs(value).format('MMM D')
  }
  return '-'
}

export function specificRelativeTimeJustHoursAndMinutes(rawValue) {
  // if it is today then a minutes/hours ago is fine.
  //if it is yesterday, then yesterday at 459pm is fine
  //if it is before then, the date atnd time
  let value = rawValue
  if (!Number.isInteger(rawValue)) {
    value = Date.parse(rawValue)
  }
  if (value) {
    return dayjs(value).format('h:mm a')
  }
  return '-'
}
