import { castMetricKind, prepMetric } from './metric'

import CONSTS from '@/consts'
import get from 'lodash/get'
import moment from 'moment-timezone'
import { rfc3339ToUSDate } from '@/helpers/time'

const msDay = 86400000

export function prepPat(p) {
  const now = Date.now()
  // Set some defaults
  p.rpm = p.rpm || {}
  p.rpm.enrollment = p.rpm.enrollment || {}
  p.rpm.alertNotifications = p.rpm.alertNotifications || []
  p.rpm.lastReadings = p.rpm.lastReadings || {}
  p.tele = p.tele || {}
  p.tele.messaging = p.tele.messaging || {}
  p.lastUpdatedLocally = new Date().getTime()

  if (p.lastName) p.lastName = p.lastName.trim()
  p.fullName = p.firstName + ' ' + p.lastName

  if (p.birthdate) {
    p.displayBirthdate = rfc3339ToUSDate(p.birthdate)
    const dobTs = Date.parse(p.birthdate)
    const ageDiffMs = now - dobTs
    const ageDate = new Date(ageDiffMs)
    p.age = Math.abs(ageDate.getUTCFullYear() - 1970)
  }

  if (p.gender === 'FEMALE') p.gender = 'Female'
  // back compat
  else if (p.gender === 'MALE') p.gender = 'Male' // back compat
  if (p.gender) p.shortGender = p.gender.charAt(0)

  if (p.address?.zip) {
    p.zip = p.address.zip.substring(0, 5)
    p.fullAddress =
      get(p, 'address.line1', '') +
      ' ' +
      get(p, 'address.line2', '') +
      ' ' +
      get(p, 'address.city', '') +
      ' ' +
      get(p, 'address.state', '') +
      ' ' +
      get(p, 'address.zip', '')
  } else p.zip = 'None'

  p.enrolled = p.rpm.enrollment.enrolled
  p.archived = !p.enrolled
  p.conditionFilters = {}
  p.conditionsForMatching = []
  p.icd10Array = []

  for (const c of p.conditions) {
    const icd10 = c.split('|')[0]
    p.conditionFilters[icd10] = true
    p.conditionsForMatching.push(icd10)
    p.icd10Array.push(icd10)
  }
  prepPatLatestReadings(p)
  prepPatLastData(p)
  prepPatQhpTime(p)
  prepPatUnseenAlerts(p)
  prepPatFirstData(p)

  if (p.enrolled) {
    prepPatCategorizeByActivity(p)
    prepPatIsNew(p)
    prepPatForSorting(p)
  }
}

function prepPatLatestReadings(p) {
  for (const [preCastEventKind, event] of Object.entries(p.rpm.lastReadings)) {
    const eventKind = castMetricKind(preCastEventKind)
    if (!CONSTS.METRICS_KINDS.has(eventKind)) {
      delete p.rpm.lastReadings[preCastEventKind]
      continue
    }
    p[eventKind] = event
    prepMetric(event)
    p[eventKind + 'ForSorting'] = event.displayValue
    p.alert = p.alert || event.alert
  }
}

function prepPatLastData(p) {
  p.msOfMostRecentTimestamp = 0
  if (!p.rpm.lastData) {
    // this patient needs to finish onboarding
    p.noData = true
    p.tookReadingToday = false
    return
  }
  // this patient has data, not tag it with how long it has been since the reading
  p.mostRecentDataTimestamp = Date.parse(p.rpm.lastData)

  // calculate num of days of inactivity
  const endOfTheDayOfLastReading = new Date(Date.parse(p.rpm.lastData))
  endOfTheDayOfLastReading.setHours(23, 59, 59, 999)
  const startOfToday = new Date()
  startOfToday.setHours(0, 0, 0, 0)
  const msSinceLastReading = startOfToday.getTime() - endOfTheDayOfLastReading.getTime()
  p.daysOfInactivity = Math.floor(msSinceLastReading / msDay)
  p.tookReadingToday = p.daysOfInactivity === 0
  p.msOfMostRecentTimestamp = new Date(Date.parse(p.rpm.lastData)).getTime()
  if (!p.rpm.lastData) {
    p.msOfMostRecentTimestamp = 0
  }
}

function prepPatCategorizeByActivity(p) {
  let thirtyDaysAgo = new Date()
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)
  thirtyDaysAgo.setHours(0, 0, 0, 0)

  let startOfToday = new Date()
  startOfToday.setHours(0, 0, 0, 0)

  if (p.enrolled) {
    let enrollmentDate = Date.parse(p.rpm.enrollment.start)
    p.enrollmentTimestamp = Date.parse(p.rpm.enrollment.start)

    if (enrollmentDate < thirtyDaysAgo.getTime()) {
      // this patient has been enrolled for more than a month...
      if (p.rpm.lastData) {
        if (Date.parse(p.rpm.lastData) < thirtyDaysAgo.getTime()) {
          p.unenrollCandidate = true
        }
      } else {
        p.unenrollCandidate = true
      }
    }
    // so now deal with the patients that have taken a reading
  }

  p.daysLeftToUse = Math.ceil(p.daysUntilPeriodEnd)

  if (p.daysLeftToUse < 22.5 && p.daysThisPeriodWithReadings < 16) {
    p.possibleOffTrack = true

    let daysElapsed = 30 - p.daysLeftToUse

    p.risk = daysElapsed / p.daysThisPeriodWithReadings

    p.shouldBeAt = Math.ceil((30 - p.daysLeftToUse) / 2)

    p.severity = Math.ceil(((p.shouldBeAt - p.daysThisPeriodWithReadings) / p.shouldBeAt) * 100)
    if (Math.floor(p.daysLeftToUse < 16 - p.daysThisPeriodWithReadings)) {
      p.hopeless = true
      p.severity = 101
    }

    // days you can miss of the days remaining and still make it

    if (p.shouldBeAt <= p.daysThisPeriodWithReadings) {
      p.possibleOffTrack = false
    }
  }

  p.daysYouCanMissOfTheDaysLeft = p.daysLeftToUse - (16 - p.daysThisPeriodWithReadings)

  p.howLazyCanYouBe = Math.floor((p.daysYouCanMissOfTheDaysLeft / p.daysLeftToUse) * 100)

  p.perfectionNeeded = 100 - p.howLazyCanYouBe

  if (!p.rpm.lastData) {
    // these patient never took a reading
    p.perfectionNeeded = 400
  }

  if (p.unenrollCandidate) {
    // these patients have been inactive for more than a month
    //p.perfectionNeeded = 500
  }
}

function prepPatQhpTime(p) {
  const orgStartOfThisMonth = moment.tz(p.org.tzName).startOf('month').toDate()
  if (!p.rpm.qhpTime) {
    p.rpm.qhpTime = { start: orgStartOfThisMonth, millisecondsThisPeriod: 0 }
  } else {
    p.rpm.qhpTime.start = new Date(p.rpm.qhpTime.start)
    if (p.rpm.qhpTime.start.toISOString().substring(0, 10) !== orgStartOfThisMonth.toISOString().substring(0, 10)) {
      // check if qhpTime has data for this month, or replace qhpTime with 0 data for this month
      // - start timezone is inconsistent, to cover both UTC and in-timezone starts-of-the-month, just match against the "yyyy-mm-dd" part of the ISO string
      p.rpm.qhpTime = { start: orgStartOfThisMonth, millisecondsThisPeriod: 0 }
    }
  }
  p.millisecondsThisPeriod = p.rpm.qhpTime.millisecondsThisPeriod

  // is this patient close to qualifying? for 454? for 457?
  if (p.millisecondsThisPeriod > 60000 * 16 && p.millisecondsThisPeriod < 60000 * 20) {
    p.close = true
    p.closeOnTime = true
  }
}

function prepPatUnseenAlerts(p) {
  if (!p.rpm.newAlertsArray) {
    return
  }

  // set the alert statement
  p.thresholdBreachAlertNotifications = []
  for (const alert of p.rpm.newAlertsArray) {
    p.unseenAlert = true
    p.alertMetric = alert
    let measurementName = CONSTS.METRICS[alert].commonName
    let operator = 'above'

    if (!p.rpm.lastReadings[alert].alertMsg.includes('above')) {
      operator = 'below'
    }
    let noteFragment =
      p.firstName + "'s" + ' most recent ' + measurementName + ' reading is ' + operator + ' the threshold. '
    p.thresholdBreachAlertNotifications.push({
      event: p.rpm.lastReadings[alert],
      note: noteFragment,
    })
  }
}

function prepPatFirstData(p) {
  if (!p.rpm.enrollment.firstData || new Date(p.rpm.enrollment.firstData).getTime() > new Date().getTime()) {
    return
  }
  // this tells me when the next 99454 is up

  let periodStart = new Date(p.rpm.enrollment.firstData)
  periodStart.setHours(0, 0, 0, 0)

  let rightNow = new Date()

  let monitoringPeriods = []

  while (periodStart.getTime() < rightNow.getTime()) {
    // this will add thirty days to the period start. when this ends, period start will be the start of NEXT period.
    let start = periodStart.getTime()
    periodStart.setDate(periodStart.getDate() + 30)
    let end = new Date(periodStart.getTime() - 1).getTime()
    monitoringPeriods.push({ start: start, end: end })
  }

  //let endDateObject = new Date(monitoringPeriods[monitoringPeriods.length - 1].end)

  p.currentRPMPeriodEnd = monitoringPeriods[monitoringPeriods.length - 1].end
  p.currentRPMPeriodStart = monitoringPeriods[monitoringPeriods.length - 1].start

  let Difference_In_Time = p.currentRPMPeriodEnd - new Date().getTime()

  // To calculate the no. of days between two dates
  p.daysUntilPeriodEnd = Difference_In_Time / msDay

  let internalActivityArray = []
  if (p.rpm && p.rpm.activity && p.rpm.activity.dates) {
    // dates are 12:00am in teh timezone of the patient.
    // as a dirty fix, add 12 hours
    p.rpm.activity.dates.forEach(date => {
      internalActivityArray.push(Date.parse(date) + 43200000)
    })
  }

  p.arrayOfActivityDates = internalActivityArray

  p.daysThisPeriodWithReadings = internalActivityArray.filter(function (e) {
    return e >= p.currentRPMPeriodStart
  }).length

  // GO SEE HOW THEY WERE DOING THE LAST THREE PERIODS?

  let daysOfReadingsPerPeriod = []

  monitoringPeriods.forEach(period => {
    let numberThisPeriod = internalActivityArray.filter(function (e) {
      return e >= period.start && e <= period.end
    }).length

    period.total = numberThisPeriod

    daysOfReadingsPerPeriod.push(numberThisPeriod)
  })

  p.monitoringPeriods = monitoringPeriods

  let lastThreePeriods = daysOfReadingsPerPeriod.slice(Math.max(daysOfReadingsPerPeriod.length - 3, 0))

  let total = 0

  lastThreePeriods.forEach(periodTotal => {
    total += periodTotal
  })

  p.recentQualifications = lastThreePeriods.filter(function (e) {
    return e >= 16
  }).length

  p.onFire = p.recentQualifications === lastThreePeriods.length

  const averageOfLastThreePeriods = total / lastThreePeriods.length
  p.highCompliance = averageOfLastThreePeriods >= 16
  p.lowCompliance = averageOfLastThreePeriods <= 3

  // a high priority patient has NOT taken a reading today.
  // a high priority patient has fewer than 20 days left in the period.
  // a high priority patient has more days left in the period than he or she needs
  // a patient is higher priority if they have fewer days left in the period.

  if (!p.tookReadingToday && p.daysUntilPeriodEnd < 20 && p.daysThisPeriodWithReadings < 16) {
    // now make sure the patient CAN get the days needed
    if (16 - p.daysThisPeriodWithReadings < p.daysUntilPeriodEnd) {
      let Difference_In_Time_Last_Reading = p.mostRecentDataTimestamp - new Date().getTime()
      // To calculate the no. of days between two dates
      p.daysSinceLastReading = Difference_In_Time_Last_Reading / msDay
      p.highPriority = true
      p.daysProgress = (p.daysThisPeriodWithReadings / 16) * 100
    }
  }
}

function prepPatIsNew(p) {
  const thirtyDays = new Date()
  thirtyDays.setDate(thirtyDays.getDate() - 30)
  thirtyDays.setHours(0, 0, 0, 0)

  if (p.rpm.enrollment.start) {
    const startDate = Date.parse(p.rpm.enrollment.start)
    if (startDate > thirtyDays.getTime()) {
      p.newPatient = true
    }
    // get enrollment age of patient

    const msSinceEnrollment = new Date().getTime() - startDate

    // To calculate the no. of days between two dates
    p.enrollmentAge = msSinceEnrollment / msDay
  }
}

function prepPatForSorting(p) {
  p.sort = {}
  function determineLastReadingCategory(c) {
    if (c.rpm.lastData) {
      let timestampOfLastData = new Date() - new Date(Date.parse(p.rpm.lastData)).getTime()
      if (timestampOfLastData < CONSTS.sortingCategories.timeSinceLastReading[0].cutoff) {
        return CONSTS.sortingCategories.timeSinceLastReading[0].tag
      } else if (timestampOfLastData < CONSTS.sortingCategories.timeSinceLastReading[1].cutoff) {
        return CONSTS.sortingCategories.timeSinceLastReading[1].tag
      } else if (timestampOfLastData < CONSTS.sortingCategories.timeSinceLastReading[2].cutoff) {
        return CONSTS.sortingCategories.timeSinceLastReading[2].tag
      } else if (timestampOfLastData <= CONSTS.sortingCategories.timeSinceLastReading[3].cutoff) {
        return CONSTS.sortingCategories.timeSinceLastReading[3].tag
      } else if (timestampOfLastData > CONSTS.sortingCategories.timeSinceLastReading[4].cutoff) {
        return CONSTS.sortingCategories.timeSinceLastReading[4].tag
      }
      return 'uncategorized'
    }
    return 'never'
  }
  p.sort.lastReadingCategory = determineLastReadingCategory(p)

  function determineQHPCategory(c) {
    if (c.millisecondsThisPeriod) {
      if (c.millisecondsThisPeriod > CONSTS.sortingCategories.qhpTimeThisMonth[0].cutoff) {
        return CONSTS.sortingCategories.qhpTimeThisMonth[0].tag
      } else if (c.millisecondsThisPeriod > CONSTS.sortingCategories.qhpTimeThisMonth[1].cutoff) {
        return CONSTS.sortingCategories.qhpTimeThisMonth[1].tag
      } else if (c.millisecondsThisPeriod > CONSTS.sortingCategories.qhpTimeThisMonth[2].cutoff) {
        return CONSTS.sortingCategories.qhpTimeThisMonth[2].tag
      } else if (c.millisecondsThisPeriod > CONSTS.sortingCategories.qhpTimeThisMonth[3].cutoff) {
        return CONSTS.sortingCategories.qhpTimeThisMonth[3].tag
      } else if (c.millisecondsThisPeriod >= CONSTS.sortingCategories.qhpTimeThisMonth[4].cutoff) {
        return CONSTS.sortingCategories.qhpTimeThisMonth[4].tag
      }
      return CONSTS.sortingCategories.qhpTimeThisMonth[5].tag
    }
    return CONSTS.sortingCategories.qhpTimeThisMonth[5].tag
  }
  p.sort.qhpCategory = determineQHPCategory(p)

  function determineOnTrackCategory(c) {
    // create perfection needed?

    if (c.perfectionNeeded !== null && c.rpm.lastData) {
      if (c.perfectionNeeded <= CONSTS.sortingCategories.engagementPerfectionNeeded[0].cutoff) {
        return CONSTS.sortingCategories.engagementPerfectionNeeded[0].tag
      } else if (c.perfectionNeeded < CONSTS.sortingCategories.engagementPerfectionNeeded[1].cutoff) {
        return CONSTS.sortingCategories.engagementPerfectionNeeded[1].tag
      } else if (c.perfectionNeeded < CONSTS.sortingCategories.engagementPerfectionNeeded[2].cutoff) {
        return CONSTS.sortingCategories.engagementPerfectionNeeded[2].tag
      } else if (c.perfectionNeeded <= CONSTS.sortingCategories.engagementPerfectionNeeded[3].cutoff) {
        return CONSTS.sortingCategories.engagementPerfectionNeeded[3].tag
      } else if (c.perfectionNeeded > CONSTS.sortingCategories.engagementPerfectionNeeded[4].cutoff) {
        return CONSTS.sortingCategories.engagementPerfectionNeeded[4].tag
      }

      return CONSTS.sortingCategories.engagementPerfectionNeeded[4].tag
    }
    if (!p.rpm.lastData) {
      return CONSTS.sortingCategories.engagementPerfectionNeeded[5].tag
    }
    return CONSTS.sortingCategories.engagementPerfectionNeeded[5].tag
  }
  p.sort.onTrackCategory = determineOnTrackCategory(p)

  function determineEnrollmentAgeCategory(c) {
    if (c.rpm.enrollment.start) {
      const startDate = Date.parse(p.rpm.enrollment.start)

      const msSinceEnrollment = new Date().getTime() - startDate

      if (msSinceEnrollment < CONSTS.sortingCategories.enrollmentAge[0].cutoff) {
        return CONSTS.sortingCategories.enrollmentAge[0].tag
      } else if (msSinceEnrollment < CONSTS.sortingCategories.enrollmentAge[1].cutoff) {
        return CONSTS.sortingCategories.enrollmentAge[1].tag
      } else if (msSinceEnrollment < CONSTS.sortingCategories.enrollmentAge[2].cutoff) {
        return CONSTS.sortingCategories.enrollmentAge[2].tag
      } else if (msSinceEnrollment <= CONSTS.sortingCategories.enrollmentAge[3].cutoff) {
        return CONSTS.sortingCategories.enrollmentAge[3].tag
      } else if (msSinceEnrollment > CONSTS.sortingCategories.enrollmentAge[4].cutoff) {
        return CONSTS.sortingCategories.enrollmentAge[4].tag
      }
      return 'uncategorized'
    }
    return CONSTS.sortingCategories.enrollmentAge[5].tag
  }
  p.sort.enrollmentAgeCategory = determineEnrollmentAgeCategory(p)

  function determineWeeksOnThePlatform(c) {
    return Math.floor(c.enrollmentAge / 7)
  }
  p.sort.weeksCategory = determineWeeksOnThePlatform(p)

  function determineNeedWelcome(c) {
    // if the patient has been on the platform for more than 4 days, does not have a welcome tag?
    if (c.enrollmentAge > 4 && c.enrollmentAge < 30 && !c.tags.includes('welcomed')) {
      return 'needsWelcome'
    }

    if (c.enrollmentAge <= 4) {
      return 'justEnrolled'
    }
  }
  p.sort.welcomeCategory = determineNeedWelcome(p)

  p.systemTags = [p.sort.enrollmentAgeCategory, p.sort.onTrackCategory, p.sort.qhpCategory, p.sort.lastReadingCategory]
}
