<template>
  <v-dialog v-model="show" :max-width="options.maxWidth" scrollable>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title v-if="patient">
            Still Here?
          </v-toolbar-title>
          <v-toolbar-title v-else>
            Chart Closed
          </v-toolbar-title>
        </v-toolbar>
        
        <!-- Content -->
        <v-card-text v-if="patient" >
          This chart will close automatically after 5 minutes of inactivity. In order to keep Protected Health Information safe, we periodically check to see if you're still here. You can stay in the chart by clicking below.
        </v-card-text>
        <v-card-text v-else >
          In order to keep Protected Health Information safe, we periodically check to see if you're still here. The chart you were viewing has been closed due to inactivity.
        </v-card-text>

        <!-- Actions -->
        <v-card-actions v-if="patient">
          <!-- Buttons -->
          <v-btn depressed @click="logOut()">Log Out</v-btn>
          <v-spacer></v-spacer>
          <v-btn depressed dark color="#primary" @click="cancel()">Continue</v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <!-- Buttons -->
          <v-spacer></v-spacer>
          <v-btn text color="#primary" @click="cancel()">Dismiss</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
</template>


<script>
import DialogMixin from '@/core/mixins/DialogMixin'
import { mapActions, mapState } from 'vuex'
import bus from '@/core/helpers/bus'
import { after as idleAfter, cancel as idleCancel } from '@/helpers/idle'

const msMinute = 60000

export default {
  mixins: [DialogMixin],
  data() {
    return {
      idleCloseCbId: null,
      idleWarnCbId: null,
      countdownTimeout: null,
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('org', ['settings']),
    ...mapState('patient', ['patient']),
    autoCloseMs() {
      return (this.settings.chartInactivity || 0) * msMinute
    },
  },
  methods: {
    ...mapActions('auth', ['signOut']),
    ...mapActions('patient', ['loadPatient']),
    logOut() {
      this.signOut()
      this.$router.push({ name: 'Login' });
      bus.$emit('toast', { type: 'success', text: 'Logged Out' })
      this.cancel()
    },
    onClose() {
      this.reset()
    },
    countdownStart() {
      this.countdownTimeout = setTimeout(this.loadPatient, msMinute)  // loadPatient() unsets patient
    },
    countdownStop() {
      clearTimeout(this.countdownTimeout)
    },
    onIdleClose() {
      this.loadPatient(null)
    },
    onIdleWarn() {
      this.open()
      this.countdownStart()
    },
    reset() {
      idleCancel(this.idleCloseCbId)
      idleCancel(this.idleWarnCbId)
      this.countdownStop()
      if (this.patient) {
        this.idleCloseCbId = idleAfter(this.onIdleClose, this.autoCloseMs)
        this.idleWarnCbId = idleAfter(this.onIdleWarn, this.autoCloseMs - msMinute)
      }
    },
  },
  watch: {
    patient() {
      this.reset()
    },
    user() {
      if (!this.user) this.cancel()  // this can happen on user-initiated logout or auto-logout. In either case, this dialog should be closed.
    },
  },
  mounted() {
    this.reset()
  }
}
</script>
