<template>
  <v-col
    id="inboxScrollbox"
    style="overflow-y: scroll; overflow-x: hidden; background-color: #eff2f5; padding-left: 60px"
  >
    <div v-if="!transitioning">
      <v-card
        style="position: sticky; top: 0; z-index: 100; backdrop-filter: blur(2px); border-right: 1px solid transparent"
        height="64"
        flat
        tile
        color="#eff2f5cc"
        class="flex"
      >
        <v-row align="center" no-gutters class="fill-height px-3 flex">
          <v-fade-transition hide-on-leave>
            <org-switcher />
          </v-fade-transition>
        </v-row>
      </v-card>

      <v-fade-transition hide-on-leave>
        <div style="height: 56px">
          <v-row class="mx-3 fill-height" align="start" no-gutters>
            <global-patient-search-simplified
              @blurred="searchFocused = false"
              :orgsDict="fullOrgsDict"
              @searchResultPatient="searchResultPatient"
            />
          </v-row>
        </div>
      </v-fade-transition>
      <v-slide-y-reverse-transition>
        <inbox-patient-list headerColor="#EFF2F5" @clicked="handleClick" />
      </v-slide-y-reverse-transition>
    </div>
  </v-col>
</template>
<style>
.v-card--link:before {
  background: none;
}
</style>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { getOrgs } from '@/shared-ui/store/org'
import GlobalPatientSearchSimplified from '@/components/appdrawer/GlobalPatientSearchSimplified.vue'
import InboxPatientList from '@/components/appdrawer/InboxPatientList.vue'
import OrgSwitcher from '@/components/appdrawer/OrgSwitcher'

export default {
  components: {
    GlobalPatientSearchSimplified,
    InboxPatientList,
    OrgSwitcher,
  },
  props: {
    transitioning: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      patientSearchText: null,
      selectedPatients: [],
      fullOrgsDict: {},
      searchFocused: false,
    }
  },
  watch: {
    $route: {
      handler: function (ti, tb) {
        let currentDrawerOpen = tb.meta.expandedDrawer === true
        let destinationDrawerOpen = ti.meta.expandedDrawer === true

        if (currentDrawerOpen !== destinationDrawerOpen) {
          this.transitioning = true
        }
      },
      deep: true,
      immediate: true,
    },
    transitioning: {
      handler: function (val) {
        this.$emit('transitioning', val)
      },
    },
    // enrolledPatients: {
    //   handler: async function (val, oldVal) {
    //     if (val.length > 0 && (!oldVal || val.length !== oldVal.length)) {
    //       val.forEach(async patient => {
    //         await this.convertLegacyReviewNotesToFirebaseReviewNotes(patient)
    //       })
    //     }
    //   },

    //   deep: true,
    //   immediate: true,
    // },
    orgSwitcherOptions() {
      this.orgSwitcherSelection = this.orgSwitcherOptions.find(opt => {
        return opt.id === this.selectedOrgId
      })
      this.orgSwitcherSelection = this.orgSwitcherSelection || this.orgSwitcherOptions[0]
      this.loadOrg(this.orgSwitcherSelection.id)
    },
    // providerReviewNotifications: {
    //   handler: function (newData) {
    //     console.log('new provider review notifications', newData)
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    // careTeamReviewNotifications: {
    //   handler: function (newData) {
    //     console.log('new care tean review notifications', newData)
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    // mentions: {
    //   handler: function (newData) {
    //     console.log('new mentions notifications', newData)
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
  methods: {
    ...mapActions('org', ['loadOrg']),
    ...mapMutations('auth', ['setSelectedOrgId']),
    ...mapMutations('inbox', ['setPatientList', 'setOrgNotifications']),
    ...mapActions('patient', ['convertLegacyReviewNotesToFirebaseReviewNotes']),

    searchResultPatient(patient) {
      if (this.org.id === patient.org.id) {
        this.selectedCategory = 2
        this.mode = 3
        this.$router.push({ name: 'PatientFeed', params: { id: patient.id } })
      } else {
        this.orgSwitcherSelection = this.orgSwitcherOptions.find(opt => {
          return opt.id === patient.org.id
        })

        this.setOrgNotifications(null)
        this.setSelectedOrgId(this.orgSwitcherSelection.id)

        this.loadOrg(this.orgSwitcherSelection.id).then(() => {
          this.selectedCategory = 2
          this.mode = 3
          this.$router.push({ name: 'PatientFeed', params: { id: patient.id } })
        })
      }
    },
    generateNoteForDisplay(patientObject, notification) {
      if (patientObject.rpm.notes.length > 0) {
        let timestampOfCorrectNote = notification.ts
        let latestNote = patientObject.rpm.notes[0]

        if (latestNote.ts !== timestampOfCorrectNote) {
          patientObject.rpm.notes.forEach(note => {
            if (note.ts === timestampOfCorrectNote) {
              latestNote = note
            }
          })
        }

        if (notification.comment) {
          if (latestNote.comments.length > 0) {
            latestNote = latestNote.comments[0]
          }
        }
        let displayObject = { text: 'Note', author: 'Author', date: 'date' }

        if (this.orgUserDictionary[latestNote.userId]) {
          displayObject.author = this.orgUserDictionary[latestNote.userId].name
        }
        displayObject.text = latestNote.text
        displayObject.date = notification.notificationTimestamp
        return displayObject
      }

      return null
    },
    openNewBillingPlatform() {
      this.child = window.open('https://partners.novorpm.com', 'null', null)
    },
    switchOrg() {
      this.navigateHome()
      this.setOrgNotifications(null)
      this.setSelectedOrgId(this.orgSwitcherSelection.id)
      this.loadOrg(this.orgSwitcherSelection.id)
    },
    navigateHome() {
      this.selectedPatients = []
      if (this.$route.name !== 'Inbox') {
        this.$router.push({ name: 'Inbox' })
      }
    },
    handleClick(patient) {
      if (this.$route.params.id === patient.id) {
        this.$router.push({ name: 'Inbox' })
      } else {
        this.$router.push({ name: 'PatientFeed', params: { id: patient.id } })
      }
    },
  },
  computed: {
    ...mapState('auth', ['user', 'selectedOrgId']),
    ...mapState('org', [
      'org',
      'patientsLoading',
      'enrolledPatients',
      'unreadConvosCount',
      'unenrolledPatients',
      'users',
    ]),
    ...mapState('org', ['providerReviewNotifications', 'careTeamReviewNotifications', 'mentions']),
    ...mapState('patient', ['patient']),
    orgUserDictionary() {
      const dict = {}
      if (this.users && this.users.length > 0) {
        this.users.forEach(user => {
          dict[user.id] = { name: 'Unknown', initials: 'UK' }
          if (user.firstName && user.lastName) {
            dict[user.id].name = user.firstName + ' ' + user.lastName
            dict[user.id].initials = user.firstName.charAt(0) + user.lastName.charAt(0)
          }
        })
      }
      return dict
    },
    expandedDrawer() {
      if ('Inbox' === this.$route.name || 'PatientFeed' === this.$route.name) {
        return true
      }
      return false
    },
    isDisplayingReports() {
      return 'Reports' === this.$route.name
    },
    isDisplayingEnterprise() {
      return 'Enterprise' === this.$route.name
    },
    isDisplayingAccount() {
      return 'Account' === this.$route.name
    },
    isDisplayingDevices() {
      return 'Enrollment' === this.$route.name
    },
    isDisplayingEnrollment() {
      return 'Device Management' === this.$route.name
    },
    isDisplayingOrg() {
      return 'Organization' === this.$route.name
    },
    accountRoute() {
      return { name: 'Account', params: { id: this.user.id } }
    },
    orgRoute() {
      return { name: 'Organization', params: { id: this.org.id } }
    },
    enrollmentRoute() {
      return { name: 'Enrollment', params: { id: this.org.id } }
    },
    deviceRoute() {
      return { name: 'Device Management', params: { id: this.org.id } }
    },
    isDisplayingAlerts() {
      return 'Alerts' === this.$route.name
    },
    isDisplayingInbox() {
      return 'Inbox' === this.$route.name
    },
    isDisplayingActivity() {
      return 'Engagement' === this.$route.name
    },
    isDisplayingDeviceManagement() {
      return 'Device Management' === this.$route.name
    },
    isDisplayingDashboard() {
      return 'Inbox' === this.$route.name
    },
    isDisplayingMessaging() {
      return 'Messaging' === this.$route.name
    },
    isDisplayingConversations() {
      return 'Conversations' === this.$route.name
    },
    notesForMentions() {
      let notifications = []
      if (this.enrolledPatients.length > 0) {
        this.mentions.forEach(notification => {
          let patient = this.enrolledPatients.find(pt => pt.id === notification.patient)
          if (patient) {
            let notificationObject = {}
            notificationObject.patient = patient

            notificationObject.firstName = patient.firstName
            notificationObject.lastName = patient.lastName
            notificationObject.org = { patientId: patient.org.patientId }
            notificationObject.notificationTimestamp = notification.notificationTimestamp
            // get the text of the note

            notificationObject.noteForDisplay = this.generateNoteForDisplay(patient, notification)
            notificationObject.firebaseNotification = notification

            notifications.push(notificationObject)
          }
        })
      }

      return notifications
    },
    notesForProviderReview() {
      let notifications = []
      if (this.enrolledPatients.length > 0) {
        this.providerReviewNotifications.forEach(notification => {
          let patient = this.enrolledPatients.find(pt => pt.id === notification.patient)
          if (patient) {
            console.log('generating note for patient', patient.lastUpdatedLocally)
            let notificationObject = {}
            notificationObject.patient = patient

            notificationObject.firstName = patient.firstName
            notificationObject.lastName = patient.lastName
            notificationObject.org = { patientId: patient.org.patientId }
            notificationObject.notificationTimestamp = notification.notificationTimestamp
            // get the text of the note

            notificationObject.noteForDisplay = this.generateNoteForDisplay(patient, notification)
            notificationObject.firebaseNotification = notification

            notifications.push(notificationObject)
          }
        })
      }
      return notifications
    },

    notesForCareTeamReview() {
      let notifications = []
      if (this.enrolledPatients.length > 0) {
        this.careTeamReviewNotifications.forEach(notification => {
          let patient = this.enrolledPatients.find(pt => pt.id === notification.patient)

          let notificationObject = {}
          notificationObject.patient = patient

          notificationObject.firstName = patient.firstName
          notificationObject.lastName = patient.lastName
          notificationObject.org = { patientId: patient.org.patientId }
          notificationObject.notificationTimestamp = notification.notificationTimestamp
          // get the text of the note

          notificationObject.noteForDisplay = this.generateNoteForDisplay(patient, notification)
          notificationObject.firebaseNotification = notification

          notifications.push(notificationObject)
        })
      }
      return notifications
    },
    newWithin7Days() {
      return this.enrolledPatients.filter(function (e) {
        const thirtyDays = new Date()
        thirtyDays.setDate(thirtyDays.getDate() - 90)
        thirtyDays.setHours(0, 0, 0, 0)

        return (
          e.rpm &&
          e.rpm.enrollment &&
          e.rpm.enrollment.start &&
          Date.parse(e.rpm.enrollment.start) > thirtyDays.getTime() &&
          !e.tags.includes('welcomed')
        )
      })
    },
    careTeamStarred() {
      return this.enrolledPatients.filter(function (e) {
        return e.tags && e.tags.includes('Care Team Starred')
      })
    },
    navigatorReview() {
      return this.notesForCareTeamReview
    },
    mentionsReview() {
      return this.notesForMentions
    },
    zeroTime() {
      return this.enrolledPatients.filter(function (e) {
        //listPatient.rpm.qhpTime.millisecondsThisPeriod
        return !e.rpm.qhpTime || !e.rpm.qhpTime.millisecondsThisPeriod || e.rpm.qhpTime.millisecondsThisPeriod === 0
      })
    },
    under20() {
      return this.enrolledPatients
        .filter(function (e) {
          return (
            e.rpm.qhpTime &&
            e.rpm.qhpTime.millisecondsThisPeriod &&
            e.rpm.qhpTime.millisecondsThisPeriod > 0 &&
            e.rpm.qhpTime.millisecondsThisPeriod < 1200000
          )
        })
        .sort((a, b) => b.rpm.qhpTime.millisecondsThisPeriod - a.rpm.qhpTime.millisecondsThisPeriod)
    },
    under40() {
      return this.enrolledPatients
        .filter(function (e) {
          return (
            e.rpm.qhpTime &&
            e.rpm.qhpTime.millisecondsThisPeriod &&
            e.rpm.qhpTime.millisecondsThisPeriod >= 1200000 &&
            e.rpm.qhpTime.millisecondsThisPeriod < 2400000
          )
        })
        .sort((a, b) => b.rpm.qhpTime.millisecondsThisPeriod - a.rpm.qhpTime.millisecondsThisPeriod)
    },
    moreThan40() {
      return this.enrolledPatients
        .filter(function (e) {
          return (
            e.rpm.qhpTime && e.rpm.qhpTime.millisecondsThisPeriod && e.rpm.qhpTime.millisecondsThisPeriod >= 2400000
          )
        })
        .sort((a, b) => b.rpm.qhpTime.millisecondsThisPeriod - a.rpm.qhpTime.millisecondsThisPeriod)
    },
    archivedPatients() {
      return this.unenrolledPatients
    },
    unseenAlerts() {
      return this.enrolledPatients.filter(function (e) {
        return e.unseenAlert
      })
    },
    generalBreaches() {
      return this.enrolledPatients.filter(function (e) {
        return e.alert && !e.unseenAlert
      })
    },
    orgSwitcherOptions() {
      const options = []
      for (const o of this.user.orgs) {
        if (!this.fullOrgsDict[o.orgId]) continue
        options.push({
          name: this.fullOrgsDict[o.orgId].name,
          id: o.orgId,
          value: this.fullOrgsDict[o.orgId].name,
        })
      }
      options.sort((a, b) => a.name.localeCompare(b.name))
      return options
    },
  },
  async beforeCreate() {
    const fullOrgsDict = {}
    for (const org of await getOrgs()) {
      fullOrgsDict[org.id] = org
    }
    this.fullOrgsDict = fullOrgsDict
  },
}
</script>
