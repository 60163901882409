import client from '@/store/v2/client'
import firebase from 'firebase/app'
import { getUserId } from '@/shared-ui/store/sso'

const orgsClient = client.HttpClientWithSso(process.env.VUE_APP_ORGS_API_URL)
const patientsClient = client.HttpClientWithSso(process.env.VUE_APP_PATIENTS_API_URL)
const teleClient = client.HttpClientWithSso(process.env.VUE_APP_TELE_API_URL)

export default {
  /**
   * Selects an organization and loads the relevent org data: patients, settings, users
   *
   * @param commit
   * @param dispatch
   * @param orgId
   * @returns {Promise<void>}
   */
  async loadOrg({ commit, dispatch, state }, orgId) {
    if (state._orgId === orgId) return
    commit('reset')
    commit('_setOrgId', orgId)
    dispatch('_loadOrg', orgId)
    dispatch('_loadInvites', orgId)
    dispatch('_loadPatients', orgId)
    dispatch('_loadUsers', orgId)
    dispatch('_watchSettings', orgId)
    dispatch('_watchUnreadConvosCount', orgId)
    dispatch('_watchNotes', orgId)
    dispatch('_watchMentions', orgId)
    dispatch('_watchTicklers', orgId)
    dispatch('_watchProviders', orgId)
  },
  async _loadOrg({ commit }, orgId) {
    commit('_setOrgLoading', true)
    const resp = await orgsClient.post('/GetOrg', { id: orgId })
    const org = resp.data.org

    // Some default values
    org.patientInactivitySettings = org.patientInactivitySettings || {}
    org.patientInactivitySettings.automatedSms = org.patientInactivitySettings.automatedSms || {}
    org.defaultPatientEventRules = org.defaultPatientEventRules || {}

    commit('_setOrg', org)
    commit('_setOrgLoading', false)
  },
  async _loadInvites({ commit }, orgId) {
    commit('_setInvitesLoading', true)
    const resp = await orgsClient.post('/GetOrgInvites', { id: orgId })
    commit('_setInvites', resp.data.invites)
    commit('_setInvitesLoading', false)
  },
  async _loadPatients({ commit }, orgId) {
    commit('_setPatientsLoading', true)
    const resp = await patientsClient.post('/GetOrgPatients', { orgId })
    commit('_setUnpreppedPatients', resp.data.patients)
    //commit('_setPatientsLoading', false)
  },
  async _loadUsers({ commit }, orgId) {
    commit('_setUsersLoading', true)
    const resp = await orgsClient.post('/GetOrgUsers', { id: orgId, pageSize: 500 })
    commit('_setUsers', resp.data.users)
    commit('_setUsersLoading', false)
  },
  async _watchSettings({ commit }, orgId) {
    commit('_setSettingsLoading', true) // we'll be fetching settings immediately (then watching hereafter) so set loading to true
    const unsub = firebase
      .firestore()
      .collection('configuration')
      .doc(orgId)
      .onSnapshot(doc => {
        const settings = doc.exists ? doc.data() : {}

        // Some default values
        settings.chartNoteTemplates = settings.chartNoteTemplates || {}
        settings.messageTemplates = settings.messageTemplates || {}
        settings.chartInactivity = settings.chartInactivity || 5
        settings.autoCounting = settings.autoCounting || false

        commit('_setSettings', settings)
        commit('_setSettingsLoading', false) // Idempotent if loading already is set to false. Other things that knowingly trigger this watcher can set loading to true (e.g. updateSettings).
      })
    commit('_addResetHook', unsub)
  },
  async _watchNotes({ commit, dispatch }, orgId) {
    const unsub = firebase
      .firestore()
      .collection('review')
      .where('org', '==', orgId)
      .onSnapshot(querySnapshot => {
        let providerReviewNotifications = []
        let careTeamReviewNotifications = []
        querySnapshot.forEach(async doc => {
          let notification = doc.data()
          notification.notificationIDForEditing = doc.id
          // update each patient that is returned...
          if (notification.providerReview) {
            providerReviewNotifications.push(notification)
          } else {
            careTeamReviewNotifications.push(notification)
          }
        })

        commit('_setProviderReviewNotifications', providerReviewNotifications)
        commit('_setCareTeamReviewNotifications', careTeamReviewNotifications)
        dispatch('inbox/refreshOrgNotifications', null, { root: true })
      })

    commit('_addResetHook', unsub)
  },
  async _watchProviders({ commit }, orgId) {
    const unsub = firebase
      .firestore()
      .collection('providers')
      .doc(orgId)
      .onSnapshot(doc => {
        const providersArray = doc.exists ? doc.data().providers : []

        let providersDict = {}
        providersArray.forEach(provider => {
          providersDict[provider.id] = provider
        })

        commit('_setProviders', providersDict)
      })
    commit('_addResetHook', unsub)
  },
  async _watchMentions({ commit, dispatch }, orgId) {
    let userID = await getUserId()
    const unsub = firebase
      .firestore()
      .collection('mentions')
      .where('org', '==', orgId)
      .where('user', '==', userID)
      .onSnapshot(querySnapshot => {
        let mentions = []

        querySnapshot.forEach(async doc => {
          let notification = doc.data()
          notification.notificationIDForEditing = doc.id
          mentions.push(notification)
        })

        commit('_setMentions', mentions)
        dispatch('inbox/refreshOrgNotifications', null, { root: true })
      })

    commit('_addResetHook', unsub)
  },
  async _watchTicklers({ commit, dispatch }, orgId) {
    const unsub = firebase
      .firestore()
      .collection('ticklers')
      .where('org', '==', orgId)
      .where('ticklerDate', '<', new Date().getTime())
      .onSnapshot(querySnapshot => {
        let ticklers = []
        console.log('getting ticklers')
        querySnapshot.forEach(async doc => {
          let notification = doc.data()
          notification.notificationIDForEditing = doc.id
          ticklers.push(notification)
        })

        commit('_setTicklers', ticklers)
        dispatch('inbox/refreshOrgNotifications', null, { root: true })
      })

    commit('_addResetHook', unsub)
  },
  async _watchUnreadConvosCount({ commit }, orgId) {
    const fn = async () => {
      const { data } = await teleClient.post('/GetConvosCount', {
        orgId,
        unreadOnly: true,
      })

      commit('_setUnreadConvosCount', Number(data.count))
    }
    fn()
    const interval = setInterval(fn, 60000)
    commit('_addResetHook', () => {
      clearInterval(interval)
    })
  },
  updateInboxCategories({ state, dispatch }, categories) {
    state.settings.inboxCategories = categories

    dispatch('updateSettings', { inboxCategories: categories })
  },
  addMessageTemplate({ state, dispatch }, { title, content }) {
    const ObjectID = require('bson-objectid')
    const uniqueMongoID = ObjectID().toString()

    state.settings.messageTemplates[uniqueMongoID] = {
      title: title,
      content: content,
      id: uniqueMongoID,
    }

    dispatch('updateSettings', { messageTemplates: state.settings.messageTemplates })
  },
  deleteMessageTemplate({ state, dispatch }, templateId) {
    delete state.settings.messageTemplates[templateId]
    dispatch('updateSettings', { messageTemplates: state.settings.messageTemplates })
  },
  addNoteTemplate({ state, dispatch }, { title, content }) {
    const ObjectID = require('bson-objectid')
    const uniqueMongoID = ObjectID().toString()

    state.settings.chartNoteTemplates[uniqueMongoID] = {
      title: title,
      content: content,
      id: uniqueMongoID,
    }

    dispatch('updateSettings', { chartNoteTemplates: state.settings.chartNoteTemplates })
  },
  deleteNoteTemplate({ state, dispatch }, templateId) {
    delete state.settings.chartNoteTemplates[templateId]
    dispatch('updateSettings', { chartNoteTemplates: state.settings.chartNoteTemplates })
  },
  async deleteInvite({ dispatch, state }, inviteId) {
    await orgsClient.post('/DeleteOrgInvite', { inviteId })
    dispatch('_loadInvites', state.org.id)
  },
  async inviteUser({ dispatch, state }, { email, roles }) {
    const resp = await orgsClient.post('/InviteUser', { id: state.org.id, email: email, roles })
    if (resp.data.existingUser) dispatch('_loadUsers', state.org.id)
    else dispatch('_loadInvites', state.org.id)
    return resp.data.existingUser
  },
  async refreshUsers({ dispatch, state }) {
    await dispatch('_loadUsers', state.org.id)
  },
  async removeUser({ dispatch, state }, userId) {
    const payload = { id: state.org.id, userId }
    await orgsClient.post('/RemoveUser', payload)
    dispatch('_loadUsers', state.org.id)
  },
  async update({ dispatch, state }, update) {
    const payload = {}
    for (const field in update) payload[field] = { value: update[field] }
    await orgsClient.post('/UpdateOrg', { id: state.org.id, ...payload })
    dispatch('_loadOrg', state.org.id)
  },
  async updateSettings({ commit, state }, update) {
    const docRef = firebase.firestore().collection('configuration').doc(state.org.id)
    let doc = null

    // check doc exists
    try {
      doc = await docRef.get()
    } catch (e) {
      console.log('Error getting org configuration document:', e)
      throw e
    }

    // create empty doc if dne
    if (!doc.exists) {
      try {
        await docRef.set({})
      } catch (e) {
        console.error('Error creating org configuration document: ', e)
        throw e
      }
    }

    // update doc
    try {
      await docRef.update(update)
    } catch (e) {
      console.error('Error updating org configuration document: ', e)
      throw e
    }

    // get doc
    try {
      doc = await docRef.get()
    } catch (e) {
      console.log('Error getting org configuration document:', e)
      throw e
    }
    if (!doc.exists) {
      throw new Error('org configuration doc DNE after update')
    }
    commit('_setSettingsLoading', true)
  },
  async updateUserRoles({ dispatch, state }, user) {
    const payload = { id: state.org.id, userId: user.id, roles: {} }
    if (user.roles?.admin !== undefined) payload.roles.admin = { value: user.roles.admin }
    if (user.roles?.qhp !== undefined) payload.roles.qhp99457 = { value: user.roles.qhp }
    await orgsClient.post('/UpdateUserRoles', payload)
    dispatch('_loadUsers', state.org.id)
  },
  async addProvider({ state }, provider) {
    const ObjectID = require('bson-objectid')
    const uniqueMongoID = ObjectID().toString()
    let providerObject = {}
    providerObject.id = uniqueMongoID
    providerObject.firstName = provider.firstName
    providerObject.lastName = provider.lastName
    providerObject.credential = provider.credential
    providerObject.npi = provider.npi
    providerObject.displayName = provider.displayName
    let providerArray = []
    let providerDict = state.providers
    Object.keys(providerDict).forEach(providerKey => {
      providerArray.push(providerDict[providerKey])
    })
    providerArray.push(providerObject)
    const docRef = firebase.firestore().collection('providers').doc(state.org.id)
    try {
      await docRef.update({ providers: providerArray })
    } catch (e) {
      console.error('Error updating org configuration document: ', e)
      throw e
    }
  },
  async updateProviders({ state }, providers) {
    const docRef = firebase.firestore().collection('providers').doc(state.org.id)
    try {
      await docRef.update({ providers: providers })
    } catch (e) {
      console.error('Error updating org configuration document: ', e)
      throw e
    }
  },
  async removeProvider({ state }, providerID) {
    let providerArray = []
    let providerDict = state.providers
    providerDict[providerID].inactive = true
    Object.keys(providerDict).forEach(providerKey => {
      providerArray.push(providerDict[providerKey])
    })
    const docRef = firebase.firestore().collection('providers').doc(state.org.id)
    try {
      await docRef.update({ providers: providerArray })
    } catch (e) {
      console.error('Error updating org configuration document: ', e)
      throw e
    }
  },
}
