/**
 * Auth module
 *
 * Uses firebase for initial login/signup, but then creates a Novo SSO user session (implemented by shared-ui/store/sso).
 * Auth user state is set at the following moments:
 *  - email/pw login
 *  - new signup
 *  - on page/tab refresh, firebase token still live, no need for re-login
 *
 */
import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
