import throttle from 'lodash/throttle'

// since is the last time user action was detected
export var since = new Date()

const userActions = ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load']
const userActionThrottle = 1000 // the throttle for detecting user actions

// watch user actions
for (const action of userActions) {
  window.addEventListener(
    action,
    throttle(() => {
      since = new Date()
      triggerCbs() // we are mid-interval for triggering cbs, trigger any now
      adjustCbsToNow()
    }, userActionThrottle)
  )
}

///////////////
// CALLBACKS //
///////////////
var cbIdCounter = 1
// cbs is an array of { id: int, timeToTrigger: Date, cb: Function, afterIdleMs: int }, ordered by timeToTrigger ascending (soonest first)
// TODO: cbs is a simple ordered array for now, if we start using this more-than-a-little, we should prob use a datastructure like a heap/priority queue.
var cbs = []

/**
 * Register a callback to be called after idleMs milliseconds of user idle time. This makes no promises of promptness on when cb will be called,
 * only that cb will be called if idleMs of idle time is reached. Reasons: interval polling is used internally and intervals are imprecise, 
 * the browser/computer might have been asleep, etc.
 * As a result, multiple callbacks might be called at a time. However, it is guaranteed that the callbacks will be called in the order they would
 * have been triggered, e.g. callback A set for 30 seconds of idle time and callback B set for 20 seconds of idle time; A and B might be called at the
 * same time, but B will be called before A since 20 < 30. Callbacks that would be triggered at the same time have no specific order.
 * 
 * @param {Function} cb The callback to be called after idleMs milliseconds of user idle time.
 * @param {number} idleMs The milliseconds of user idle time that will trigger calling cb.
 * @returns {number} the id of the registered callback; can be used to cancel the callback
 */
export function after(cb, idleMs) {
  const descriptor = {
    id: cbIdCounter++,
    timeToTrigger: new Date(new Date().getTime() + idleMs),
    cb,
    afterIdleMs: idleMs,
  }
  cbs.push(descriptor)
  cbs.sort((a, b) => a.timeToTrigger - b.timeToTrigger)
  return descriptor.id
}

/**
 * Cancel a callback registered via `after`.
 * 
 * @param {number} cbId The id returned from `after` when the callback was registered.
 */
export function cancel(cbId) {
  if (!cbId) return
  const idx = cbs.findIndex(x => x.id == cbId)
  if (idx === -1) return
  cbs.splice(idx, 1)
}

function adjustCbsToNow() {
  const nowMs = new Date().getTime()
  for (const cb of cbs) {
    cb.timeToTrigger = new Date(nowMs + cb.afterIdleMs)
  }
}

function triggerCbs() {
  const now = new Date()
  while (cbs.length > 0 && cbs[0].timeToTrigger <= now) {
    const cb = cbs.shift()
    cb.cb()
  }  
}
setInterval(triggerCbs, 1000)
