<template>
  <v-app id="app" :style="{ background: $vuetify.theme.themes[theme].background }">
    <idle-dialog ref="idleDialog" />
    <idle-dialog-chart ref="idleDialogChart" />
    <post-auth-notification-dialog ref="postAuthNotificationDialog" />
    <post-auth-notification-error-dialog ref="postAuthNotificationErrorDialog" />
    <SharedRecordsDisclaimerDialog ref="sharedRecordsDisclaimer" />

    <!--//////APP TOOLBAR//////-->

    <app-drawer-enhanced
      @turnOffEnhanced="tryEnhancedView = false"
      @transitioning="toggleTransition"
      v-if="!this.$route.meta.hideHeader && user"
    />
    <v-app-bar
      flat
      style="border-bottom: 1px solid #eceff1; background-color: white"
      v-if="!this.$route.meta.hideHeader && !navTest && !this.$route.meta.hideOnlyHeader"
      app
      extension-height="70"
      clipped-right
      height="64"
    >
      <portal-target class="appBarContentLeft" name="appBarContentLeft" slim />
      <v-spacer></v-spacer>
      <portal-target name="appBarContentRight" slim />

      <portal-target v-if="this.$route.meta.appBarExtension" slot="extension" name="appBarContentExtension" slim />
      <ProfileMenu />
    </v-app-bar>

    <!--//////ROUTER VIEW//////-->
    <v-main>
      <router-view v-if="showPage"></router-view>
    </v-main>

    <!--//////TOAST//////-->
    <v-snackbar v-model="showSnackbar" :color="snackbarType" :timeout="3500">
      <v-layout class="mx-0" row align-center>
        <v-icon v-if="snackbarType == 'success'" class="mr-3" color="white">mdi-check</v-icon>
        <v-icon v-else class="mr-3" color="white">mdi-alert-circle</v-icon>
        <div class="mr-2">{{ snackbarText }}</div>
        <v-spacer></v-spacer>
        <v-btn small color="white" text @click="showSnackbar = false">Close</v-btn>
      </v-layout>
    </v-snackbar>
  </v-app>
</template>

<style>
/* ::-webkit-scrollbar {
    width: 0px; 
} */
.grayAlternate {
  background-color: #ffffff;
  /* cursor: pointer; */
}
.whiteAlternate {
  background-color: #fafafc;
  /* cursor: pointer; */
}
.notification {
  margin-left: 60px;
}
</style>

<script>
import bus from '@/core/helpers/bus'
import { mapActions, mapMutations, mapState } from 'vuex'
import IdleDialog from '@/components/dialogs/IdleDialog'
import IdleDialogChart from '@/components/dialogs/IdleDialogChart'
import PostAuthNotificationDialog from '@/components/dialogs/PostAuthNotificationDialog'
import SharedRecordsDisclaimerDialog from '@/components/dialogs/SharedRecordsDisclaimerDialog'
import PostAuthNotificationErrorDialog from '@/components/dialogs/PostAuthNotificationErrorDialog'
import HelperMixin from '@/core/mixins/HelperMixin'
import ProfileMenu from '@/components/ProfileMenu'
import AppDrawerEnhanced from './components/appdrawer/AppDrawerEnhanced.vue'

export default {
  mixins: [HelperMixin],
  name: 'App',
  components: {
    IdleDialog,
    IdleDialogChart,
    SharedRecordsDisclaimerDialog,
    PostAuthNotificationDialog,
    PostAuthNotificationErrorDialog,
    ProfileMenu,
    AppDrawerEnhanced,
  },

  data: () => ({
    navTest: false,
    drawer: false,
    showSnackbar: false,
    snackbarType: '',
    snackbarText: '',
    transitioning: false,
    tryEnhancedView: false,
  }),
  computed: {
    ...mapState('patient', ['patient']),
    ...mapState('auth', ['user']),
    ...mapState('org', ['org', 'settings']),
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light'
    },
    version() {
      return process.env.VUE_APP_VERSION
    },
    showPage() {
      let show = true
      if (this.$route.meta.requireUser && !this.user) {
        show = false
        //console.log('show', this.$route.name, 'no user')
      }
      if (this.$route.meta.requireOrg && !this.org) {
        show = false
        //console.log('show', this.$route.name, 'no org')
      }
      if (this.transitioning) {
        show = false
      }
      return show
    },
    pageTitle() {
      return this.$route.meta.displayName
    },
    patientNameForPageTitle() {
      if (this.$route.meta.showPatientBar && this.patient) {
        return this.patient.firstName.charAt(0) + '. ' + this.patient.lastName
      }
      return null
    },
  },
  methods: {
    ...mapActions('auth', ['signOut']),
    ...mapActions({
      conversationOnOrgChange: 'conversation/onOrgChange',
      conversationOnPatientChange: 'conversation/onPatientChange',
    }),
    ...mapActions('patient', ['logAccess']),
    ...mapMutations('auth', ['setDemoFeaturesToTrue']),
    toast(text, type) {
      this.showSnackbar = true
      this.snackbarType = type
      this.snackbarText = text
    },
    toggleTransition(val) {
      this.transitioning = val
    },
  },
  watch: {
    $route(val) {
      if (val.query.demo === 'true') {
        this.setDemoFeaturesToTrue()
      }
      if (val.query.navtest === 'true') {
        this.navTest = true
      }
      if (val.query.token) {
        this.$refs.sharedRecordsDisclaimer.open()
      }

      if (val.meta.requirePatient) {
        if (this.user) {
          this.logAccess({
            destination: val.name,
            patientID: val.params.id,
          })
        }
      }
    },
    org(org) {
      this.conversationOnOrgChange(org)
    },
    patient(patient) {
      this.conversationOnPatientChange(patient)
    },
    tryEnhancedView(val) {
      if (val) {
        localStorage['_tryEnhancedView'] = true
      } else {
        localStorage.removeItem('_tryEnhancedView')
      }
    },
  },
  beforeMount() {
    if (localStorage['_tryEnhancedView']) {
      this.tryEnhancedView = true
    }
  },
  mounted() {
    bus.$on('toast', data => {
      this.toast(data.text, data.type)
    })
  },
}
</script>
