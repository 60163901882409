export default {
  data() {
    return {
      shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
      stateList: [
        'AL',
        'AK',
        'AZ',
        'AR',
        'CA',
        'CO',
        'CT',
        'DE',
        'DC',
        'FM',
        'FL',
        'GA',
        'GU',
        'HI',
        'ID',
        'IL',
        'IN',
        'IA',
        'KS',
        'KY',
        'LA',
        'ME',
        'MH',
        'MD',
        'MA',
        'MI',
        'MN',
        'MS',
        'MO',
        'MT',
        'NE',
        'NV',
        'NH',
        'NJ',
        'NM',
        'NY',
        'NC',
        'ND',
        'MP',
        'OH',
        'OK',
        'OR',
        'PW',
        'PA',
        'PR',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VT',
        'VI',
        'VA',
        'WA',
        'WV',
        'WI',
        'WY'
      ]
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false;
    }
  },
  methods: {
    getDateFromTimestamp(timestamp) {
      let d = new Date(timestamp)
      return ('0' + (d.getMonth() + 1)).slice(-2) + '/' + ('0' + d.getDate()).slice(-2) + '/' + d.getFullYear()
    },
    getDateAndTimeFromTimestamp(timestamp) {
      let d = new Date(timestamp)

      let hours = d.getHours();
      let minutes = d.getMinutes();
      let ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      let strTime = hours + ':' + minutes + ' ' + ampm;
      return ('0' + (d.getMonth() + 1)).slice(-2) + '/' + ('0' + d.getDate()).slice(-2) + '/' + d.getFullYear() + ' ' + strTime
    },
    getConversationalDateAndTimeFromTimestamp(timestamp) {
      let d = new Date(timestamp)

      let hours = d.getHours();
      let minutes = d.getMinutes();
      let ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      let strTime = hours + ':' + minutes + ' ' + ampm;
      return ('0' + (d.getMonth() + 1)).slice(-2) + '/' + ('0' + d.getDate()).slice(-2) + '/' + d.getFullYear() + ' at ' + strTime
    },
    getDateAndTimeObjectFromTimestamp(timestamp) {
      let d = new Date(timestamp)

      let hours = d.getHours();
      let minutes = d.getMinutes();
      let ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      let strTime = hours + ':' + minutes + ' ' + ampm;
      let date = ('0' + (d.getMonth() + 1)).slice(-2) + '/' + ('0' + d.getDate()).slice(-2) + '/' + d.getFullYear()
      return {date: date, time: strTime}
    },
    alternatingColors(index) {
      if (index % 2 === 0) {
        return 'whiteAlternate'
        } else {
        return 'grayAlternate'
      }
    },
    birthdateToTimestamp(birthdate) {
      let extractedNumbers = birthdate.replace(/\D/g, '')
      let constructedDate = new Date(extractedNumbers.slice(4, 8), extractedNumbers.slice(0, 2) - 1, extractedNumbers.slice(2, 4))
      if (constructedDate.getTime()) {
        return Date.UTC(constructedDate.getFullYear(), constructedDate.getMonth(), constructedDate.getDate(), 12) / 1000
      }
    },
    validateEmail(email) {
      if (email) {
        let regex = /(.+)@(.+){2,}\.(.+){2,}/
        return regex.test(email.toLowerCase());
      }
      return false
    },
    getMonthAndYearFromTimestamp(timestamp) {
      let d = new Date(timestamp)
      return this.shortMonths[d.getMonth()] + ' ' + d.getFullYear()
    },
  }
}
