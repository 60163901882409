<template>
  <v-navigation-drawer
    app
    fixed
    mini-variant-width="60"
    :width="450"
    :mini-variant="!this.$route.meta.expandedDrawer"
    color="white"
    mobile-breakpoint="0"
    permanent
    touchless
    stateless
    @transitionend="transitioning = false"
    floating
  >
    <v-row style="overscroll-behavior: contain" no-gutters class="fill-height flex-nowrap">
      <NavBar />
      <inbox-panel :transitioning="transitioning" />
    </v-row>
  </v-navigation-drawer>
</template>

<script>
import NavBar from '@/components/appdrawer/NavBar.vue'
import InboxPanel from '@/components/appdrawer/InboxPanel.vue'

export default {
  components: {
    NavBar,
    InboxPanel,
  },
  props: {},
  data() {
    return {
      transitioning: false,
    }
  },
  watch: {
    $route: {
      handler: function (ti, tb) {
        let currentDrawerOpen = tb.meta.expandedDrawer === true
        let destinationDrawerOpen = ti.meta.expandedDrawer === true

        if (currentDrawerOpen !== destinationDrawerOpen) {
          this.transitioning = true
        }
      },
      deep: true,
      immediate: true,
    },
    transitioning: {
      handler: function (val) {
        this.$emit('transitioning', val)
      },
    },
  },
}
</script>
