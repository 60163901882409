import client from './client'

export const rpmClient = client.HttpClientWithSso(process.env.VUE_APP_RPM_API_URL)

export default {
  namespaced: true,
  state: {
    patient: null,
    patientDevices: [],
    orgDevices: [],
    sessionId: null,
    sessionPatientId: null,
  },
  mutations: {
    setPatient(state, patient) {
      state.patient = patient
    },
    setPatientDevices(state, devices) {
      state.patientDevices = devices
    },
    setOrgDevices(state, devices) {
      state.orgDevices = devices
    },
    setSession(state, { sessionId, sessionPatientId }) {
      state.sessionId = sessionId
      state.sessionPatientId = sessionPatientId
    }
  },
  actions: {
    async assignDevice(_, { id, patientId }) {
      let { data } = await rpmClient.post('/AssignDevice', {
        id,
        patientId,
      })
      return data.device
    },
    async getOrCreateDevice(_, { orgId, kind, refId }) {
      let { data } = await rpmClient.post('/GetOrCreateDevice', {
        orgId,
        kind,
        refId,
      })
      return data.device
    },
    async getPatient({ commit }, { patientId, orgId, orgPatientId }) {
      try {
        let { data } = await rpmClient.post('/GetPatient', {
          patientId,
          orgId,
          orgPatientId,
        })
        commit('setPatient', data.patient)
        return data.patient
      } catch (e) {
        commit('setPatient', null)
        return null
      }
    },
    async getPatientDevices({ commit }, patientId) {
      let { data } = await rpmClient.post('/GetPatientDevices', {
        patientId,
        pageSize: 0,
        pageToken: '',
      })
      commit('setPatientDevices', data.devices)
      return data.devices
    },
    async getOrgDevices({ commit }, orgId) {
      let { data } = await rpmClient.post('/GetOrgDevices', {
        pageSize: 0,
        pageToken: '',
        orgId: orgId,
      })
      commit('setOrgDevices', data.devices)
      return data.devices
    },
    async setOrgDefaultAlertRules({ commit }, { orgId, alertRules } ) {
      try {
        await rpmClient.post('/SetOrgDefaultAlertRules', {
          orgId: orgId,
          alertRules: alertRules,
        })
        commit('setOrgDefaultAlertRules', { orgId, alertRules })
      } catch(e) {
        console.log("error setting org alert rules: " + e)
      }
    },
    async clearSession({ commit }) {
      commit('setSession', { sessionId: null, sessionPatientId: null })
    },
    async sessionHeartbeat({ commit, state }, { patientId }) {
      try {
        if (state.sessionPatientId === patientId) {
          await rpmClient.post('/RpmSessionHeartbeat', {
            sessionId: state.sessionId,
          })
        } else {
          let resp = await rpmClient.post('/RpmSessionStart', {
            patientId: patientId,
          })
          commit('setSession', { sessionId: resp.data.sessionId, sessionPatientId: patientId })
        }
      } catch(e) {
        console.log("error heartbeating RPM session: " + e)
      }
    },
  },
}
