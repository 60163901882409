import consts from "@/consts"

export function applyTextExpansionToString(patient, message) {
  let patientFirstNameParameter = '{patient_first_name}'

  let patientFullNameParameter = '{patient_full_name}'
  
  let measurementListParameter = '{vitals_list}'

  let firstNameReplacement = patient.firstName

  let fullNameReplacement = patient.firstName + ' ' + patient.lastName

  let vitalsListReplacement = ''

  let patientVitals = []
  Object.entries(consts.METRICS).forEach(metric => {
    const [name, details] = metric
    if (patient.rpm.lastReadings[name] && details.primaryMetric) {
      patientVitals.push(details.commonName.toLowerCase())
    }
  })
  if (patientVitals.length > 1) {
    const last = patientVitals.pop()
    vitalsListReplacement = patientVitals.join(', ') + ' and ' + last
  } else if (patientVitals.length === 1) {
    vitalsListReplacement = patientVitals[0]
  } else {
    vitalsListReplacement = ''
  }

  let result = message.replaceAll(patientFirstNameParameter, firstNameReplacement)

  result = result.replaceAll(patientFullNameParameter, fullNameReplacement)

  result = result.replaceAll(measurementListParameter, vitalsListReplacement)

  return result
}