import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import { reactive, shallowReadonly } from '@vue/composition-api'
import { orgsClient } from './clients/orgs'
import { getOrgId, getAccessToken } from './sso.js'

Vue.use(VueCompositionAPI)

const _state = reactive({
  billingReports: [],
  orgsMap: {},
  orgs: [],
  org: null,
  orgUsers: [],
  patientReports: [],
  runningReport: false,
})

export const state = shallowReadonly(_state)

export const getOrgs = async orgId => {
  if (_state.orgs.length === 0) {
    let { data } = await orgsClient.post('/GetOrgs')
    _state.orgs = data.orgs

    const orgsMap = {}
    data.orgs.forEach(({ id, name }) => {
      orgsMap[id] = name
    })
    _state.orgsMap = orgsMap
  }
  if (orgId) {
    let org = state.orgs.find(o => o.id === orgId)
    _state.org = org
  }
  return _state.orgs
}

export const getOrg = async () => {
  let id = await getOrgId()
  if (id) {
    let { data } = await orgsClient.post('/GetOrg', { id })
    _state.org = data.org
  }
  return _state.orgs
}

export const updateOrg = async org => {
  // check to make sure we are updating the current org
  if (org.id === _state.org?.id) {
    let { data } = await orgsClient.post('/UpdateOrg', org)
    return data
  }
}

export const getBillingReport = async (startDate, endDate, id, min99454DayRequirement = 1) => {
  _state.runningReport = true
  let { data } = await orgsClient.post('/GetBillingReport', {
    startDate,
    endDate,
    min99454DayRequirement,
    id,
  })
  _state.patientReports = data.patientReports
  _state.runningReport = false
  return _state.patientReports
}

export const getBillingXLSX = async ({ orgId, month, orgName }) => {
  const url = 'https://api.rpm.novo.health/GetBillingReportXlsx'
  const authHeader = await getAccessToken()
  const filename = orgName + ' ' + month + ' .xlsx'

  const options = {
    headers: {
      Authorization: 'Bearer ' + authHeader,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    mode: 'cors', // maybe superfluous for this example?
    body: JSON.stringify({ orgId: orgId, month: month }),
  }

  fetch(url, options)
    .then(resp => resp.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = filename
      document.body.appendChild(a)
      a.click()
      a.remove()
    })

  // function s2ab(s) {
  //   var buf = new ArrayBuffer(s.length)
  //   var view = new Uint8Array(buf)
  //   for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
  //   return buf
  // }

  // _state.runningReport = true
  // let response = await orgsClient.post('/GetBillingReportXlsx', {
  //   orgId,
  //   month,
  // })

  // console.log(data)
  // _state.runningReport = false

  // var bytes = new Uint8Array(data)

  // const blob = new Blob(bytes, {
  //   type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  // })
  // const downloadUrl = URL.createObjectURL(response.blob())
  // const a = document.createElement('a')
  // a.href = downloadUrl
  // a.download = orgName + ' ' + month + ' .xlsx'
  // document.body.appendChild(a)
  // a.click()

  return
}

export const getInvoices = async (startDate, endDate) => {
  let { data } = await orgsClient.post('/GetInvoices', {
    startDate,
    endDate,
  })

  _state.billingReports = data.enrolledPatients
  return _state.billingReports
}

export const setUsers = users => {
  console.log('Demo users are being set', users)
  _state.orgUsers = users
}

export const getOrgUsers = async () => {
  let id = await getOrgId()
  if (id) {
    let { data } = await orgsClient.post('/GetOrgUsers', { id })
    _state.orgUsers = data.users
  }
  return _state.orgUsers
}
