var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"fixed":"","floating":"","dark":"","color":"#001426","mini-variant":"","mini-variant-width":"60","permanent":"","stateless":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.org)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({on:{"click":function($event){return _vm.$emit('turnOffEnhanced')}},model:{value:(_vm.isDisplayingReports),callback:function ($$v) {_vm.isDisplayingReports=$$v},expression:"isDisplayingReports"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-chart-donut")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Enhanced View")])],1)],1)]}}],null,false,3494160608)},[_vm._v(" Toggle Enhanced View ")]):_vm._e()]},proxy:true}])},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"py-1",attrs:{"to":{ name: 'Enterprise' }},model:{value:(_vm.isDisplayingEnterprise),callback:function ($$v) {_vm.isDisplayingEnterprise=$$v},expression:"isDisplayingEnterprise"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-img',{attrs:{"src":require('@/assets/Arrowhealth-bug-RGB.png')}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Enterprise")])],1)],1)]}}])},[_c('span',[_vm._v("Enterprise Statistics")])]),_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":{ name: 'Inbox' }},model:{value:(_vm.isDisplayingDashboard),callback:function ($$v) {_vm.isDisplayingDashboard=$$v},expression:"isDisplayingDashboard"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-view-dashboard")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Inbox")])],1)],1)]}}])},[_c('span',[_vm._v("Inbox")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":{ name: 'Conversations' }},model:{value:(_vm.isDisplayingMessaging),callback:function ($$v) {_vm.isDisplayingMessaging=$$v},expression:"isDisplayingMessaging"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-badge',{attrs:{"value":_vm.unreadConvosCount,"content":_vm.unreadConvosCount,"color":"primary","overlap":""}},[_c('v-icon',[_vm._v("mdi-message-reply-text")])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Conversations")])],1)],1)]}}])},[_c('span',[_vm._v("Conversations")])]),(false)?_c('app-drawer-user-navigation-list'):_vm._e(),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":_vm.accountRoute},model:{value:(_vm.isDisplayingAccount),callback:function ($$v) {_vm.isDisplayingAccount=$$v},expression:"isDisplayingAccount"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-circle")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName))])],1)],1)]}}])},[_vm._v(" "+_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName)+" ")]),_c('v-fade-transition',[(_vm.org)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"exact":"","to":_vm.orgRoute},model:{value:(_vm.isDisplayingOrg),callback:function ($$v) {_vm.isDisplayingOrg=$$v},expression:"isDisplayingOrg"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-domain")])],1),_c('v-list-item-content',[_c('v-list-item-title')],1)],1)]}}],null,false,685858402)},[_vm._v(" "+_vm._s(_vm.org.name)+" ")]):_vm._e()],1),_c('v-fade-transition',[(_vm.org)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":_vm.enrollmentRoute},model:{value:(_vm.isDisplayingEnrollment),callback:function ($$v) {_vm.isDisplayingEnrollment=$$v},expression:"isDisplayingEnrollment"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',{staticClass:"rotate"},[_vm._v("mdi-account-plus")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Enrollment")])],1)],1)]}}],null,false,301659696)},[_vm._v(" Enrollment ")]):_vm._e()],1),_c('v-fade-transition',[(_vm.org)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":_vm.deviceRoute},model:{value:(_vm.isDisplayingDeviceManagement),callback:function ($$v) {_vm.isDisplayingDeviceManagement=$$v},expression:"isDisplayingDeviceManagement"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',{staticClass:"rotate"},[_vm._v("mdi-devices")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Devices")])],1)],1)]}}],null,false,3724480561)},[_vm._v(" Devices ")]):_vm._e()],1),_c('v-fade-transition',[(_vm.org)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":{ name: 'Activity' }},model:{value:(_vm.isDisplayingReports),callback:function ($$v) {_vm.isDisplayingReports=$$v},expression:"isDisplayingReports"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-google-spreadsheet")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Activity Report")])],1)],1)]}}],null,false,3708008534)},[_vm._v(" Activity Report ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }