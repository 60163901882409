import { msToUSDateTime, msToTime } from '@/helpers/time'
import * as XLSX from 'xlsx'

export function createDashboardCSV(rowArray, title, metrics) {
  // the first line is the titles
  // REPORT_TITLE: DATE
  // first, last, dob, start date, bp, hr, weight, glucose,
  let csv = ''
  csv = title + ' ' + msToUSDateTime(new Date().getTime()) + '\n'
  csv += 'Patient ID,First Name,Last Name, Gender, DOB, Enrolled,'

  if (metrics.includes('hr')) {
    csv += 'Latest HR,'
  }
  if (metrics.includes('bp')) {
    csv += 'Latest BP,'
  }
  if (metrics.includes('wt')) {
    csv += 'Latest WT,'
  }
  if (metrics.includes('glu')) {
    csv += 'Latest GLU,'
  }

  csv += 'Last Active, Time This Period\n'

  rowArray.forEach(patient => {
    csv += patient.org.patientId + ','
    csv += patient.firstName + ','
    csv += patient.lastName + ','
    csv += patient.gender + ','
    csv += patient.displayBirthdate + ','
    csv += patient.readableStartDate + ','
    if (metrics.includes('hr')) {
      csv += patient.hr + ','
    }
    if (metrics.includes('bp')) {
      csv += patient.bp + ','
    }
    if (metrics.includes('wt')) {
      csv += patient.wt + ','
    }
    if (metrics.includes('glu')) {
      csv += patient.glu + ','
    }
    csv += msToUSDateTime(patient.rpm.lastData) + ','
    csv += msToTime(patient.rpm.qhpTime.millisecondsThisPeriod) + ','
    //csv += row.join(',');
    csv += '\n'
  })
  let hiddenElement = document.createElement('a')
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
  hiddenElement.target = '_blank'
  hiddenElement.download = title + ' ' + msToUSDateTime(new Date().getTime()) + '_novorpm.csv'
  hiddenElement.click()
}

export function createDeviceCSV(rowArray, title) {
  // the first line is the titles
  // REPORT_TITLE: DATE
  // first, last, dob, start date, bp, hr, weight, glucose,
  let csv = ''
  csv += 'Device Kind,Device ID,Device Age,Status,Assignment,Patient ID,Patient First,Patient Last\n'

  rowArray.forEach(device => {
    csv += device.kind + ','
    csv += device.deviceRefId + ','
    csv += device.age + ','
    csv += device.status + ','
    csv += device.enrolledHumanReadable + ','
    csv += device.orgID + ','
    csv += device.firstName + ','
    csv += device.lastName
    csv += '\n'
  })
  let hiddenElement = document.createElement('a')
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
  hiddenElement.target = '_blank'
  hiddenElement.download = title + ' ' + msToUSDateTime(new Date().getTime()) + '_Arrowhealth.csv'
  hiddenElement.click()
}
export function createActivityCSV(rowArray, title) {
  let csv = ''
  csv +=
    'Patient ID,First Name,Last Name,Sex,Birthdate,Enrollment,First Reading,CPT 99453,CPT 99454 (1),Reading Days,CPT 99454 (2),Reading Days,CPT 99457,CPT 99458 (1),CPT 99458 (2),CPT 99458 (3), ICD-10,Invoice\n'

  console.log(csv, rowArray, title)

  rowArray.forEach(patient => {
    csv += '"' + patient.patientID + '",'
    csv += patient.firstName + ','
    csv += patient.lastName + ','
    csv += patient.sex + ','
    csv += patient.birthdate + ','
    csv += patient.enrolled + ','
    csv += patient.firstReading + ','
    csv += patient.cpt99453 + ','
    csv += patient.cpt99454 + ','
    csv += patient.cpt99454Days + ','
    csv += patient.cpt99454_2 + ','
    csv += patient.cpt99454_2Days + ','
    csv += patient.cpt99457 + ','
    csv += patient.cpt99458 + ','
    csv += patient.cpt99458_2 + ','
    csv += patient.cpt99458_3 + ','
    csv += '"' + patient.conditions + '",'
    csv += patient.invoiceable + ','
    csv += '\n'
  })

  let hiddenElement = document.createElement('a')
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
  hiddenElement.target = '_blank'
  hiddenElement.download = title + ' ' + msToUSDateTime(new Date().getTime()) + '_Arrowhealth.csv'
  hiddenElement.click()
}

export function createActivitySpreadsheet(rowArray, title, devices) {
  console.log('device exprt ', devices)
  let cleanArray = []

  let invoiceableArray = []

  rowArray.forEach(patient => {
    let cleanObject = {}

    cleanObject.patientID = patient.patientID
    cleanObject.firstName = patient.firstName
    cleanObject.lastName = patient.lastName
    cleanObject.sex = patient.sex
    cleanObject.birthdate = patient.birthdate
    cleanObject.enrolled = patient.enrolled
    cleanObject.firstReading = patient.firstReading
    cleanObject.cpt99453 = patient.cpt99453
    cleanObject.cpt99454_1 = patient.cpt99454
    cleanObject.cpt99454_1Days = patient.cpt99454Days
    cleanObject.cpt99454_2 = patient.cpt99454_2
    cleanObject.cpt99454_2Days = patient.cpt99454_2Days
    cleanObject.cpt99457 = patient.cpt99457
    cleanObject.cpt99458_1 = patient.cpt99458
    cleanObject.cpt99458_2 = patient.cpt99458_2
    cleanObject.cpt99458_3 = patient.cpt99458_3
    cleanObject.conditions = patient.conditions

    let invoiceObject = {}

    invoiceObject.patientID = patient.patientID
    invoiceObject.firstName = patient.firstName
    invoiceObject.lastName = patient.lastName
    invoiceObject.sex = patient.sex
    invoiceObject.birthdate = patient.birthdate
    invoiceObject.enrolled = patient.enrolled

    cleanArray.push(cleanObject)

    if (patient.invoiceable) {
      invoiceableArray.push(invoiceObject)
    }
  })

  const worksheet = XLSX.utils.json_to_sheet(cleanArray)

  const invoicedPatients = XLSX.utils.json_to_sheet(invoiceableArray)
  const invoicedDevices = XLSX.utils.json_to_sheet(devices)

  XLSX.utils.sheet_add_aoa(
    worksheet,
    [
      [
        'Patient ID',
        'First Name',
        'Last Name',
        'Sex',
        'Birthdate',
        'Enrollment',
        'First Reading',
        'CPT 99453',
        'CPT99454 (1)',
        'Reading Days',
        'CPT 99454 (2)',
        'Reading Days',
        'CPT 99457',
        'CPT 99458 (1)',
        'CPT 99458 (2)',
        'CPT 99458 (3)',
        'ICD-10',
      ],
    ],
    { origin: 'A1' }
  )

  XLSX.utils.sheet_add_aoa(invoicedDevices, [['Device Kind', 'Device ID', 'Device Age', 'Date Assigned']], {
    origin: 'A1',
  })

  XLSX.utils.sheet_add_aoa(
    invoicedPatients,
    [['Patient ID', 'First Name', 'Last Name', 'Sex', 'Birthdate', 'Enrollment']],
    { origin: 'A1' }
  )

  XLSX.utils.sheet_add_aoa(
    worksheet,
    [
      [
        'Patient ID',
        'First Name',
        'Last Name',
        'Sex',
        'Birthdate',
        'Enrollment',
        'First Reading',
        'CPT 99453',
        'CPT99454 (1)',
        'Reading Days',
        'CPT 99454 (2)',
        'Reading Days',
        'CPT 99457',
        'CPT 99458 (1)',
        'CPT 99458 (2)',
        'CPT 99458 (3)',
        'ICD-10',
      ],
    ],
    { origin: 'A1' }
  )

  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Billing Report')
  XLSX.utils.book_append_sheet(workbook, invoicedPatients, 'Invoiced Patients')
  XLSX.utils.book_append_sheet(workbook, invoicedDevices, 'Invoiced Devices')

  XLSX.writeFile(workbook, title + '.xlsb')

  // let csv = ''
  // csv +=
  //   'Patient ID,First Name,Last Name,Sex,Birthdate,Enrollment,First Reading,CPT 99453,CPT 99454 (1),Reading Days,CPT 99454 (2),Reading Days,CPT 99457,CPT 99458 (1),CPT 99458 (2),CPT 99458 (3), ICD-10,Invoice\n'

  // console.log(csv, rowArray, title)

  // rowArray.forEach(patient => {
  //   csv += '"' + patient.patientID + '",'
  //   csv += patient.firstName + ','
  //   csv += patient.lastName + ','
  //   csv += patient.sex + ','
  //   csv += patient.birthdate + ','
  //   csv += patient.enrolled + ','
  //   csv += patient.firstReading + ','
  //   csv += patient.cpt99453 + ','
  //   csv += patient.cpt99454 + ','
  //   csv += patient.cpt99454Days + ','
  //   csv += patient.cpt99454_2 + ','
  //   csv += patient.cpt99454_2Days + ','
  //   csv += patient.cpt99457 + ','
  //   csv += patient.cpt99458 + ','
  //   csv += patient.cpt99458_2 + ','
  //   csv += patient.cpt99458_3 + ','
  //   csv += '"' + patient.conditions + '",'
  //   csv += patient.invoiceable + ','
  //   csv += '\n'
  // })

  // let hiddenElement = document.createElement('a')
  // hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
  // hiddenElement.target = '_blank'
  // hiddenElement.download = title + ' ' + msToUSDateTime(new Date().getTime()) + '_Arrowhealth.csv'
  // hiddenElement.click()
}
