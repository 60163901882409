export default {
  // auth module is ready once it gets first hook from Firebase SDK's onAuthStateChanged; means auth module is in-sync with Firebase now
  ready: false,

  // Novo user
  user: null,
  selectedOrgId: null,

  demoFeatures: false,
}
