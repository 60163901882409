<template>
  <v-dialog v-model="show" max-width="450" scrollable hide-on-leave :persistent="saveOnSubmit">
    <template v-slot:activator="{ on: show, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-scale-transition hide-on-leave origin="center">
            <v-btn
              class="mr-1"
              icon
              v-bind="attrs"
              v-on="{ ...tooltip, ...show }"
              v-show="selectedPatients.length > 0 || alwaysOn"
              :disabled="selectedPatients.length === 0"
            >
              <v-icon size="23">mdi-tag-outline</v-icon>
            </v-btn>
          </v-scale-transition>
        </template>
        <span>Add or Remove Tags</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar flat>
        <v-row no-gutters justify="start">
          <span class="text-truncate" style="width: 290px">
            <span
              class="font-weight-bold text-h6"
              v-if="selectedPatients.length > 0 && selectedPatients[0].firstName && selectedPatients[0].lastName"
              >{{ selectedPatients[0].firstName }} {{ selectedPatients[0].lastName }}
            </span>
            <span
              class="font-weight-bold text-h6"
              v-if="selectedPatients.length > 1 && selectedPatients[1].firstName && selectedPatients[1].lastName"
              >, {{ selectedPatients[1].firstName }} {{ selectedPatients[1].lastName }}
            </span>
          </span>
          <span class="font-weight-bold text-h6" v-if="selectedPatients.length > 2">
            , + {{ selectedPatients.length - 2 }} more
          </span>
        </v-row>
      </v-toolbar>
      <v-row no-gutters class="mx-4">
        <v-text-field
          dense
          class="mb-3"
          ref="tagBox"
          v-model="newTag"
          solo
          flat
          hide-details
          outlined
          placeholder="Create new tag"
          @keyup.enter.stop.prevent="submitNewTag"
          @keyup.188.stop.prevent="submitNewTag"
          prepend-inner-icon="mdi-tag-plus-outline"
          color="primary"
        >
        </v-text-field>
      </v-row>

      <v-card-text class="pa-4 pt-0">
        <v-row no-gutters>
          <v-chip
            class="mr-2 mb-2"
            label
            v-for="(tag, index) in tagCloud"
            :key="index + tag"
            :color="tag.color"
            @click="handleTagClick(tag)"
          >
            {{ tag.text }}</v-chip
          >
        </v-row>
      </v-card-text>
      <v-card-actions style="height: 80px">
        <v-row justify="end" no-gutters>
          <v-scale-transition origin="center, center">
            <v-btn v-if="saveOnSubmit" color="primary" depressed :disabled="!saveOnSubmit" @click="submitChanges()"
              >Save Changes</v-btn
            >
          </v-scale-transition>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogMixin from '@/core/mixins/DialogMixin'
import { mapActions, mapState } from 'vuex'
import HelperMixin from '@/core/mixins/HelperMixin'
import { mask } from 'vue-the-mask'
import bus from '@/core/helpers/bus'
//import get from 'lodash/get'

export default {
  name: 'PatientTagManagerDialog',
  mixins: [DialogMixin, HelperMixin],
  components: {},
  directives: {
    mask,
  },
  props: {
    selectedPatients: {
      type: Array,
      default: null,
    },
    alwaysOn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newTag: null,
      saveOnSubmit: false,
      selectedPatientsCopy: [],
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.saveOnSubmit = false
        this.selectedPatientsCopy = JSON.parse(JSON.stringify(this.selectedPatients))
      }
    },
  },
  methods: {
    ...mapActions('patient', ['oneOffUpdateRemoteOnly']),
    updateLocalPatient() {
      let found = this.enrolledPatients.find(patient => patient.id === this.options.patient.id)
      if (found) {
        found.tags = this.options.patient.tags
      }
      // updateDashboard Key?
      bus.$emit('updateDataTableKeys')
    },
    submitNewTag() {
      this.handleTagClick({ text: this.newTag.replace(',', ''), partial: false, full: false })
      this.newTag = null
    },
    clearChartTags() {
      this.options.patient.tags = []
      //this.submitChanges()
      //this.updateLocalPatient()
    },
    handleTagClick(tag) {
      this.saveOnSubmit = true
      if (tag.full) {
        // go and give every patient the tag
        this.selectedPatientsCopy.forEach(patient => {
          if (patient.tags.includes(tag.text)) {
            let index = patient.tags.indexOf(tag.text)
            if (index > -1) {
              patient.tags.splice(index, 1)
            }
          }
        })
      } else {
        // go and give every patient the tag
        this.selectedPatientsCopy.forEach(patient => {
          if (!patient.tags.includes(tag.text)) {
            patient.tags.push(tag.text)
          }
        })
      }
    },
    submitChanges() {
      if (this.saveOnSubmit) {
        if (this.newTag !== null) {
          this.handleTagClick({ text: this.newTag.replace(',', ''), partial: false, full: false })
          this.newTag = null
        }
        this.selectedPatients.forEach(patient => {
          let patientIndex = this.selectedPatientsCopy.findIndex(object => {
            return object.id === patient.id
          })
          let enrolledIndex = this.enrolledPatients.findIndex(object => {
            return object.id === patient.id
          })
          patient.tags = this.selectedPatientsCopy[patientIndex].tags
          this.enrolledPatients[enrolledIndex].tags = this.selectedPatientsCopy[patientIndex].tags

          this.oneOffUpdateRemoteOnly({ patientId: patient.id, payload: { tags: { value: patient.tags } } }).catch(
            error => {
              console.log(error)
              bus.$emit('toast', { type: 'error', text: 'Error Tagging Chart' })
            }
          )
        })
        // refresh all enrolled?
      }
      this.show = false
    },
  },
  computed: {
    ...mapState('org', ['enrolledPatients']),
    patientTags() {
      let arrayOfArrayOfTags = this.enrolledPatients.map(a => a.tags)
      let tags = []
      this.selectedPatientsCopy.forEach(patient => {
        patient.tags.forEach(tag => {
          if (!tags.includes(tag)) {
            tags.push(tag)
          }
        })
      })
      arrayOfArrayOfTags.forEach(patientTags => {
        patientTags.forEach(tag => {
          if (!tags.includes(tag)) {
            tags.push(tag)
          }
        })
      })
      return tags
    },
    tagCloud() {
      // generate a list that has three settings for each tag. 1: everybody has that tag, blue 2: some people have the tag, outlined 3: nobody has the tag. gray
      let tagsToReturn = []
      this.patientTags.forEach(tag => {
        let tagToBeAdded = { text: tag, partial: false, full: false, color: 'grey lighten-4' }
        let patientCountForTagChecking = 0
        this.selectedPatientsCopy.forEach(patient => {
          if (patient.tags.includes(tag)) {
            tagToBeAdded.partial = true
            patientCountForTagChecking = patientCountForTagChecking + 1
            tagToBeAdded.color = 'primary lighten-2'
          }
        })
        if (patientCountForTagChecking === this.selectedPatientsCopy.length) {
          tagToBeAdded.partial = false
          tagToBeAdded.full = true
          tagToBeAdded.color = 'primary'
        }

        tagsToReturn.push(tagToBeAdded)
      })
      return tagsToReturn
    },
  },
  mounted() {},
}
</script>
