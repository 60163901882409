import { clearTokens, getRefreshToken, getUser, getUserId, updateUser } from '@/shared-ui/store/sso'

import bus from '@/core/helpers/bus'
import client from '@/store/v2/client'
import firebase from 'firebase/app'

const orgsClient = client.HttpClientWithSso(process.env.VUE_APP_ORGS_API_URL)

export default {
  async reset({ commit, dispatch }) {
    clearTokens()
    commit('_reset')
    commit('org/reset', null, { root: true })
    commit('patient/reset', null, { root: true })
    dispatch('rpm/clearSession', null, { root: true })
  },
  /**
   * Hook for Firebase SDK client's onAuthStateChanged.
   * Updates auth firebase token, and in turn, the auth state.
   *
   * @param commit
   * @param dispatch
   * @param firebaseUser
   * @returns {Promise<void>}
   */
  async firebaseOnAuthStateChangedHook({ state, commit, dispatch }, firebaseUser) {
    await dispatch('firebaseOnAuthStateChangedHookBody', firebaseUser)
    if (!state.ready) {
      commit('_setReady') // Auth module is ready after first call to this.
    }
  },
  async firebaseOnAuthStateChangedHookBody({ dispatch }, firebaseUser) {
    // Unauthed. Reset auth state.
    if (!firebaseUser || !firebase.auth().currentUser) {
      dispatch('reset')
      return
    }

    // Newly authed. Get Firebase token and Novo SSO refresh token.
    const firebaseAuthToken = await firebase.auth().currentUser.getIdToken(true)
    await getRefreshToken(firebaseAuthToken)

    // Fetch Novo SSO user data. Set org to lowest IDed org (we can change this later)
    const userId = await getUserId()
    await dispatch('_loadUser', userId)
  },
  async _loadUser({ commit, dispatch, state }, userId) {
    commit('_setUser', await getUser({ userId: userId }))

    // initialize user's active org: the org they were on before or the first org in their list
    let selectedOrgId = localStorage[userId+'_selectedOrgId']
    if (selectedOrgId && state.user.orgs.every(org => { return org.orgId !== selectedOrgId})) selectedOrgId = null  // check that user is still member of org
    selectedOrgId = selectedOrgId || state.user.orgs[0].orgId  // default to first org in list
    commit('setSelectedOrgId', selectedOrgId)
    dispatch('org/loadOrg', selectedOrgId, { root: true })
  },
  async updateUser({ dispatch, state }, { firstName, lastName, settings }) {
    await updateUser({ firstName, lastName, settings})
    await dispatch('_loadUser', state.user.id)
  },
  signOut({ commit, dispatch }) {
    commit('_reset')
    dispatch('reset')
    firebase.auth().signOut()
  },
  async resetPassword(_, email) {
    await firebase.auth().sendPasswordResetEmail(email)
  },
  async userActivity(_, { type, patient, file, payload }) {
    let action = {}
    if (type) {
      action.type = type
    }
    if (patient) {
      action.patient = patient
    }
    if (file) {
      action.file = file
    }
    if (payload) {
      action.payload = payload
    }
    action.timestamp = new Date().getTime()

    if (firebase.auth().currentUser && firebase.auth().currentUser.uid) {
      action.user = firebase.auth().currentUser.uid
      let db = firebase.firestore()
      try {
        return await db.collection('userActivity').add(action)
      } catch (e) {
        console.error('Error adding document: ', e)
      }
    }
  },
  async acceptOrgInvite({ dispatch, state }, orgInviteToken) {
    try {
      await orgsClient.post('/AcceptOrgInvite', { jwt: orgInviteToken })
    } catch(e) {
      bus.$emit('toast', { type: 'error', text: 'Could not apply token: ' + e })
      return
    }
    await dispatch('_loadUser', state.user.id)
    bus.$emit('toast', { type: 'success',text: 'You now have access to a new organization' })
  },
}
