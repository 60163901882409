import { getAccessToken } from '@/shared-ui/store/sso'
import { HttpClient } from '@/shared-ui/helpers/httpClient'
import store from '@/store';

const requestHandler = async (ctx, request) => {
    const accessToken = await getAccessToken()
    if (accessToken) {
        request.headers['Authorization'] = `Bearer ${accessToken}`
    } else {
        await store.dispatch('auth/logout', { error: 'Invalid refresh token' })
    }
    return request
}

export default {
    HttpClientWithSso(baseUrl) {
        return HttpClient.create({
            baseUrl: baseUrl,
            requestHandler,
        })
    }
}