import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)
import firebase from 'firebase/app'

export default {
  namespaced: true,
  state: {
    patientList: null,
    orgNotifications: null,
    filters: [],
    complexFilters: [],
    filterObject: { lastReadingCategory: [], qhpCategory: [], onTrackCategory: [], enrollmentAgeCategory: [] },
    careTeamReviewTasks: [],
    providerReviewTasks: [],
    thresholdBreachTasks: [],
    mentions: [],
    ticklers: [],
    needContactedTasks: [],
    inboxSections: {},
    orgUserDict: null,
    tryPopulationView: false,
    inboxCategorySync: 'EnrolledPatients',
    orgNotificationsByPatient: {},
    orgNotificationLists: {},
    patientTagFilters: [],
  },
  mutations: {
    setCategorySync(state, categorySync) {
      console.log('setting category sync')
      state.inboxCategorySync = categorySync
    },
    setPatientList(state, patientList) {
      state.patientList = patientList
    },
    togglePopView(state) {
      state.tryPopulationView = !state.tryPopulationView
      if (state.tryPopulationView) {
        localStorage['_tryPopView'] = state.tryPopulationView
      } else {
        localStorage.removeItem('_tryPopView')
      }
    },
    setPopView(state, popview) {
      state.tryPopulationView = popview
    },
    setOrgNotifications(state, notifications) {
      state.orgNotifications = notifications
    },
    clearInboxFilters(state) {
      state.patientTagFilters = []
      state.complexFilters = []
      state.filters = []
    },
    setPatientTagFilters(state, filters) {
      state.patientTagFilters = filters
    },
    toggleInboxFilter(state, filter) {
      let needsHandling = true
      state.complexFilters.forEach(filterObject => {
        if (filterObject.name === filter.category) {
          needsHandling = false
          if (filterObject.tags.includes(filter.tag)) {
            filterObject.tags = filterObject.tags.filter(function (e) {
              return e !== filter.tag
            })
          } else {
            filterObject.tags.push(filter.tag)
          }
        }
      })

      if (needsHandling) {
        state.complexFilters.push({ name: filter.category, tags: [filter.tag] })
      }

      if (state.filters.includes(filter.tag)) {
        state.filters = state.filters.filter(function (e) {
          return e !== filter.tag
        })
      } else {
        state.filters.push(filter.tag)
      }
    },
    setCareTeamReview(state, patientList) {
      state.careTeamReviewTasks = patientList
    },
    setProviderReview(state, patientList) {
      state.providerReviewTasks = patientList
    },
    setThresholdBreaches(state, patientList) {
      state.thresholdBreachTasks = patientList
    },
    setMentions(state, patientList) {
      state.mentions = patientList
    },
    setTicklers(state, patientList) {
      state.ticklers = patientList
    },
    setNeedContacted(state, patientList) {
      state.needContactedTasks = patientList
    },
    setInboxSections(state, inboxSections) {
      state.inboxSections = inboxSections
    },
    setOrgUserDict(state, dict) {
      state.orgUserDict = dict
    },
    setOrgNotificationsByPatient(state, dict) {
      state.orgNotificationsByPatient = dict
    },
    setOrgNotificationLists(state, dict) {
      state.orgNotificationLists = dict
    },
  },
  actions: {
    refreshOrgNotifications({ commit, dispatch, rootState }) {
      let orgNotificationsByPatient = {}
      let orgNotificationLists = {}
      commit('setOrgNotificationLists', orgNotificationLists)
      commit('setOrgNotificationsByPatient', orgNotificationsByPatient)
      // this should generate the notifications lists: alerts, welcomes, mentions, review, provider review
      if (rootState.org.enrolledPatients.length) {
        // handle new patients
        let newPatientsToWelcome = rootState.org.enrolledPatients.filter(function (e) {
          const thirtyDays = new Date()
          thirtyDays.setDate(thirtyDays.getDate() - 90)
          thirtyDays.setHours(0, 0, 0, 0)

          return (
            e.rpm &&
            e.rpm.enrollment &&
            e.rpm.enrollment.start &&
            Date.parse(e.rpm.enrollment.start) > thirtyDays.getTime() &&
            !e.tags.includes('welcomed')
          )
        })

        orgNotificationLists.newPatients = newPatientsToWelcome

        newPatientsToWelcome.forEach(patient => {
          if (!orgNotificationsByPatient[patient.id]) {
            orgNotificationsByPatient[patient.id] = []
          }
          let notification = {
            title: 'New Patient',
            text: patient.firstName + ' has been enrolled recently but may need initial outreach.',
            timestamp: Date.parse(patient.rpm.enrollment.start),
            type: 'newPatient',
          }
          orgNotificationsByPatient[patient.id].push(notification)
        })

        // handle alerts?

        let unseenAlerts = rootState.org.enrolledPatients.filter(function (e) {
          return e.unseenAlert
        })

        orgNotificationLists.alerts = unseenAlerts

        unseenAlerts.forEach(patient => {
          if (!orgNotificationsByPatient[patient.id]) {
            orgNotificationsByPatient[patient.id] = []
          }

          let alertSentence = ''
          let alertsForClearing = []
          patient.rpm.newAlertsArray.forEach(alert => {
            const reading = patient.rpm.lastReadings[alert]
            alertsForClearing.push(reading.id)
            alertSentence =
              alertSentence + reading.longName + ' was ' + reading.displayValue + ' on ' + reading.readableDate + '. '
          })

          let notification = {
            title: 'Threshold Breach',
            text: patient.firstName + "'s " + alertSentence,
            timestamp: Date.parse(patient.rpm.enrollment.start),
            type: 'thresholdBreach',
            payload: alertsForClearing,
            notePrompt: patient.firstName + "'s " + alertSentence,
          }
          orgNotificationsByPatient[patient.id].push(notification)
        })

        /// handle provider team review
        let providerReviewNotifications = []
        rootState.org.providerReviewNotifications.forEach(notification => {
          let patient = rootState.org.enrolledPatients.find(pt => pt.id === notification.patient)
          if (patient) {
            let notificationObject = {}
            notificationObject.patient = patient

            notificationObject.firstName = patient.firstName
            notificationObject.lastName = patient.lastName
            notificationObject.org = { patientId: patient.org.patientId }
            notificationObject.notificationTimestamp = notification.notificationTimestamp
            // get the text of the note

            dispatch('generateNoteForDisplay', {
              objectToUpdate: notificationObject,
              patientObject: patient,
              notification,
            })

            notificationObject.firebaseNotification = notification

            providerReviewNotifications.push(notificationObject)
          }
        })

        orgNotificationLists.providerReview = providerReviewNotifications.sort(
          (a, b) => b.notificationTimestamp - a.notificationTimestamp
        )

        // providerReviewNotifications.forEach(notificationObject => {
        //   if (!orgNotificationsByPatient[notificationObject.patient.id]) {
        //     orgNotificationsByPatient[notificationObject.patient.id] = []
        //   }

        //   let truncatedText = ''

        //   if (notificationObject.noteForDisplay.text.length < 149) {
        //     truncatedText = notificationObject.noteForDisplay.text
        //   } else {
        //     truncatedText = notificationObject.noteForDisplay.text.slice(0, 150)
        //   }
        //   let notification = {
        //     title: 'Provider Review',
        //     text: notificationObject.noteForDisplay.author + ': ' + truncatedText,
        //     timestamp: new Date(),
        //     type: 'providerReview',
        //     notificationForClearing: notificationObject.firebaseNotification,
        //   }
        //   orgNotificationsByPatient[notificationObject.patient.id].push(notification)
        // })

        /// handle care team review
        let careTeamReviewNotificationObjects = []
        rootState.org.careTeamReviewNotifications.forEach(notification => {
          let patient = rootState.org.enrolledPatients.find(pt => pt.id === notification.patient)
          if (patient) {
            let notificationObject = {}
            notificationObject.patient = patient

            notificationObject.firstName = patient.firstName
            notificationObject.lastName = patient.lastName
            notificationObject.org = { patientId: patient.org.patientId }
            notificationObject.notificationTimestamp = notification.notificationTimestamp
            // get the text of the note

            dispatch('generateNoteForDisplay', {
              objectToUpdate: notificationObject,
              patientObject: patient,
              notification,
            })

            notificationObject.firebaseNotification = notification

            careTeamReviewNotificationObjects.push(notificationObject)
          }
        })

        orgNotificationLists.careTeamReview = careTeamReviewNotificationObjects.sort(
          (a, b) => b.notificationTimestamp - a.notificationTimestamp
        )

        careTeamReviewNotificationObjects.forEach(notificationObject => {
          if (!orgNotificationsByPatient[notificationObject.patient.id]) {
            orgNotificationsByPatient[notificationObject.patient.id] = []
          }

          //let truncatedText = ''

          // if (notificationObject.noteForDisplay.text.length < 149) {
          //   truncatedText = notificationObject.noteForDisplay.text
          // } else {
          //   truncatedText = notificationObject.noteForDisplay.text.slice(0, 150)
          // }
          let notification = {
            title: 'Care Team Review',
            text: 'generate on the fly',
            timestamp: new Date(),
            type: 'careTeamReview',
            notificationForClearing: notificationObject.firebaseNotification,
          }
          orgNotificationsByPatient[notificationObject.patient.id].push(notification)
        })

        /// mentions
        let mentionsNotificationsObjects = []
        rootState.org.mentions.forEach(notification => {
          let patient = rootState.org.enrolledPatients.find(pt => pt.id === notification.patient)
          if (patient) {
            let notificationObject = {}
            notificationObject.patient = patient

            notificationObject.firstName = patient.firstName
            notificationObject.lastName = patient.lastName
            notificationObject.org = { patientId: patient.org.patientId }
            notificationObject.notificationTimestamp = notification.notificationTimestamp
            // get the text of the note

            dispatch('generateNoteForDisplay', {
              objectToUpdate: notificationObject,
              patientObject: patient,
              notification,
            })

            notificationObject.firebaseNotification = notification

            mentionsNotificationsObjects.push(notificationObject)
          }
        })

        orgNotificationLists.mentions = mentionsNotificationsObjects

        mentionsNotificationsObjects.forEach(notificationObject => {
          if (!orgNotificationsByPatient[notificationObject.patient.id]) {
            orgNotificationsByPatient[notificationObject.patient.id] = []
          }

          // let truncatedText = ''

          // if (notificationObject.noteForDisplay.text.length < 149) {
          //   truncatedText = notificationObject.noteForDisplay.text
          // } else {
          //   truncatedText = notificationObject.noteForDisplay.text.slice(0, 150)
          // }
          let notification = {
            title: 'Mention',
            text: 'generate on the fly',
            timestamp: notificationObject.firebaseNotification.ts,
            type: '@mention',
            notificationForClearing: notificationObject.firebaseNotification,
          }
          orgNotificationsByPatient[notificationObject.patient.id].push(notification)
        })

        /// Ticklers
        let ticklersNotificationsObjects = []
        rootState.org.ticklers.forEach(notification => {
          let patient = rootState.org.enrolledPatients.find(pt => pt.id === notification.patient)
          if (patient) {
            let notificationObject = {}
            notificationObject.patient = patient

            notificationObject.firstName = patient.firstName
            notificationObject.lastName = patient.lastName
            notificationObject.org = { patientId: patient.org.patientId }
            notificationObject.notificationTimestamp = notification.notificationTimestamp
            // get the text of the note

            dispatch('generateNoteForDisplay', {
              objectToUpdate: notificationObject,
              patientObject: patient,
              notification,
            })

            notificationObject.firebaseNotification = notification

            ticklersNotificationsObjects.push(notificationObject)
          }
        })

        orgNotificationLists.ticklers = ticklersNotificationsObjects.sort(
          (a, b) => b.notificationTimestamp - a.notificationTimestamp
        )

        ticklersNotificationsObjects.forEach(notificationObject => {
          if (!orgNotificationsByPatient[notificationObject.patient.id]) {
            orgNotificationsByPatient[notificationObject.patient.id] = []
          }

          // let truncatedText = ''

          // if (notificationObject.noteForDisplay.text.length < 149) {
          //   truncatedText = notificationObject.noteForDisplay.text
          // } else {
          //   truncatedText = notificationObject.noteForDisplay.text.slice(0, 150)
          // }
          let notification = {
            title: 'Follow Up',
            text: 'generate on the fly',
            timestamp: notificationObject.firebaseNotification.ts,
            type: 'followUp',
            notificationForClearing: notificationObject.firebaseNotification,
          }
          orgNotificationsByPatient[notificationObject.patient.id].push(notification)
        })

        commit('setOrgNotificationLists', orgNotificationLists)
        commit('setOrgNotificationsByPatient', orgNotificationsByPatient)
      }
    },

    async updateOrgStats(_, orgStats) {
      firebase
        .firestore()
        .collection('orgStatistics')
        .doc(orgStats.org)
        .set(orgStats)
        .then(() => {})
        .catch(error => {
          console.error('Error writing document: ', error)
        })
    },
    fetchOrgStats(_, listOfOrgs) {
      let stats = []
      let ref = firebase.firestore().collection('orgStatistics')
      listOfOrgs.forEach(org => {
        let organization = { id: org, stats: null }
        let docRef = ref.doc(org)
        docRef
          .get()
          .then(doc => {
            if (doc.exists) {
              organization.stats = doc.data()
              organization.lastBridgeAccess = null
              // find out when it was last accessed?
              organization.done = true
              let accessQuery = firebase
                .firestore()
                .collection('lastBridgeAccess')
                .where('org', '==', org)
                .orderBy('timestamp')
                .limit(1)
              accessQuery
                .get()
                .then(querySnapshot => {
                  console.log(querySnapshot.size)
                  if (querySnapshot.size > 0) {
                    querySnapshot.forEach(doc => {
                      console.log('bridge access back', doc.data().timestamp)
                      organization.lastBridgeAccess = doc.data().timestamp
                      organization.done = true
                      stats.push(organization)
                    })
                  } else {
                    organization.done = true
                    stats.push(organization)
                  }
                })
                .catch(error => {
                  organization.done = true
                  console.log('Error getting documents: ', error)
                  stats.push(organization)
                })
            } else {
              // doc.data() will be undefined in this case
              console.log('No such document!')
              organization.done = true
              stats.push(organization)
            }
          })
          .catch(error => {
            console.log('Error getting document:', error)
            organization.done = true
            stats.push(organization)
          })
      })
      return stats
    },
    updateBreaches({ commit, rootState }) {
      if (!rootState.org.enrolledPatients || rootState.org.enrolledPatients.length === 0) {
        commit('setThresholdBreaches', [])
        commit('setProviderReview', [])
        commit('setCareTeamReview', [])
        commit('setMentions', [])
      }
      let unseenAlerts = rootState.org.enrolledPatients.filter(function (e) {
        return e.unseenAlert
      })

      commit('setThresholdBreaches', unseenAlerts)
    },
    async generateNoteForDisplay({ rootState, dispatch }, { objectToUpdate, patientObject, notification }) {
      const orgUsers = {}
      if (rootState.org.users && rootState.org.users.length > 0) {
        rootState.org.users.forEach(user => {
          orgUsers[user.id] = { name: 'Unknown', initials: 'UK' }
          if (user.firstName && user.lastName) {
            orgUsers[user.id].name = user.firstName + ' ' + user.lastName
            orgUsers[user.id].initials = user.firstName.charAt(0) + user.lastName.charAt(0)
          }
        })
      }

      if (notification.notificationTimestamp > patientObject.lastUpdatedLocally) {
        console.log('generating note for display updating based on: ', notification.notificationTimestamp)
        console.log('patient update: ', patientObject.lastUpdatedLocally)
        await dispatch(
          'patient/oneOffUpdate',
          {
            patientId: patientObject.id,
          },
          { root: true }
        )
        if (patientObject.rpm.notes.length > 0) {
          let timestampOfCorrectNote = notification.ts
          let latestNote = patientObject.rpm.notes[0]

          if (latestNote.ts !== timestampOfCorrectNote) {
            patientObject.rpm.notes.forEach(note => {
              if (note.ts === timestampOfCorrectNote) {
                latestNote = note
              }
            })
          }

          if (notification.comment) {
            if (latestNote.comments.length > 0) {
              latestNote = latestNote.comments[0]
            }
          }
          let displayObject = { text: 'Note', author: 'Author', date: 'date' }

          if (orgUsers[latestNote.userId]) {
            displayObject.author = orgUsers[latestNote.userId].name
          }
          displayObject.text = latestNote.text
          displayObject.date = Date.parse(timestampOfCorrectNote)
          objectToUpdate.noteForDisplay = displayObject
        }
      } else {
        if (patientObject.rpm.notes.length > 0) {
          let timestampOfCorrectNote = notification.ts
          let latestNote = patientObject.rpm.notes[0]

          if (latestNote.ts !== timestampOfCorrectNote) {
            patientObject.rpm.notes.forEach(note => {
              if (note.ts === timestampOfCorrectNote) {
                latestNote = note
              }
            })
          }

          if (notification.comment) {
            if (latestNote.comments.length > 0) {
              latestNote = latestNote.comments[0]
            }
          }
          let displayObject = { text: 'Note', author: 'Author', date: 'date' }

          if (orgUsers[latestNote.userId]) {
            displayObject.author = orgUsers[latestNote.userId].name
          }
          displayObject.text = latestNote.text
          displayObject.date = Date.parse(timestampOfCorrectNote)
          objectToUpdate.noteForDisplay = displayObject
        }
      }
    },
    legacyProviderReviewNoteForDisplay({ store }, patientObject) {
      if (patientObject.rpm.notes.length > 0 && patientObject.tags.includes('Provider Review')) {
        let latestNote = patientObject.rpm.notes[0]
        let displayObject = { text: 'Note', author: 'Author', date: 'date' }

        if (latestNote.comments.length > 0) {
          latestNote = latestNote.comments[0]
        }
        if (store.userOrgDict[latestNote.userId]) {
          displayObject.author = store.userOrgDict[latestNote.userId].name
        }
        displayObject.text = latestNote.text
        displayObject.date = dayjs().to(dayjs(latestNote.ts))
        return displayObject
      }

      return null
    },
    legacyCareTeamNoteForDisplay({ store }, patientObject) {
      if (patientObject.rpm.notes.length > 0 && patientObject.tags.includes('Care Navigator Review')) {
        let latestNote = patientObject.rpm.notes[0]
        let displayObject = { text: 'Note', author: 'Author', date: 'date' }

        if (latestNote.comments.length > 0) {
          latestNote = latestNote.comments[0]
        }
        if (store.userOrgDict[latestNote.userId]) {
          displayObject.author = store.userOrgDict[latestNote.userId].name
        }
        displayObject.text = latestNote.text
        displayObject.date = dayjs().to(dayjs(latestNote.ts))
        return displayObject
      }

      return null
    },
  },
}
