export default {
  reset(state) {
    for (const hook of state._resetHooks) hook()
    state._resetHooks = []
    
    state.patient = null
    state.patientUpdating = false
    state.deprecatedNotes = []
    state.deprecatedNotesLoading = false
    state.accessLogs = []
    state.accessLogsLoading = false
    state._yearOfMetricsKindsPulled = []
    state._yearOfMetricsKindsToPull = []
    state.yearOfMetrics = {}
    state.yearOfMetricsLoaded = false
  },
  _addResetHook(state, hook) {
    state._resetHooks.push(hook)
  },
  _setPatient(state, patient) {
    state.patient = patient
  },
  _setDeprecatedNotes(state, notes) {
    state.deprecatedNotes = notes
  },
  _setDeprecatedNotesLoading(state, bool) {
    state.deprecatedNotesLoading = bool
  },
  _setPatientUpdating(state, bool) {
    state.patientUpdating = bool
  },
  _setAccessLogs(state, logs) {
    state.accessLogs = logs
  },
  _setAccessLogsLoading(state, bool) {
    state.accessLogsLoading = bool
  },
  _setYearOfMetricsForKind(state, { kind, readings }) {
    state._yearOfMetricsKindsPulled.push(kind)
    state.yearOfMetrics[kind] = readings
    // hack, vue doesn't react to deep changes, so "change" the full object
    const yor = state.yearOfMetrics
    state.yearOfMetrics = yor
    state.yearOfMetricsLoaded = state._yearOfMetricsKindsPulled.length === state._yearOfMetricsKindsToPull.length
  },
  _setYearOfMetricsKindsToPull(state, kindsToPull) {
    state._yearOfMetricsKindsToPull = kindsToPull
    state.yearOfMetricsLoaded = kindsToPull.length === 0
  },
}
