/**
 * Ported from Go's sort.Search
 * let "n" equal array.length
 * 
 * binarySearch uses binary search to find and return the smallest index i in [0, n) at which f(i) is true, assuming that on the range [0, n), f(i) == true implies f(i+1) == true.
 * That is, binarySearch requires that f is false for some (possibly empty) prefix of the input range [0, n) and then true for the (possibly empty) remainder; Search returns the
 * first true index. If there is no such index, Search returns n. (Note that the "not found" return value is not -1 as in, for instance, strings.Index.) Search calls f(i) only for
 * i in the range [0, n).

 * A common use of Search is to find the index i for a value x in a sorted, indexable data structure such as an array or slice. In this case, the argument f, typically a closure,
 * captures the value to be searched for, and how the data structure is indexed and ordered.
 * 
 * @param {Array<T>} array 
 * @param {function(T):boolean} f
 * @returns {number}
 */
export function binarySearch(array, f) {
  let [i, j] = [0, array.length]
  while (i < j) {
    const h = (i + j) >>> 1
    if (!f(array[h])) i = h + 1
    else j = h
  }
  return i
}