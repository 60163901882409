import CONSTS from '@/consts'
import client from '@/store/v2/client'
import firebase from 'firebase/app'
import { msToUSDateTime } from '@/helpers/time'
import { prepMetric } from '@/helpers/metric'
import { prepPat } from '@/helpers/patient'
import { generateFacesheet } from '@/store/modules/patient/facesheet'
import { binarySearch } from '@/helpers/array'

const patientsClient = client.HttpClientWithSso(process.env.VUE_APP_PATIENTS_API_URL)
const rpmClient = client.HttpClientWithSso(process.env.VUE_APP_RPM_API_URL)

export default {
  async loadPatient({ commit, dispatch, state, rootState }, patientId) {
    if (state.patient?.id === patientId) return
    commit('reset')
    if (!patientId) return
    const patient = rootState.org.patientsDict[patientId]
    if (!patient) return
    commit('_setPatient', patient)
    dispatch('_loadDeprecatedNotes')
    dispatch('_watchAccessLogsInFirebase')
    dispatch('_loadYearOfMetrics')
  },
  async _loadDeprecatedNotes({ commit, state }) {
    commit('_setDeprecatedNotesLoading', true)

    // Get last 6 months of notes.
    const end = new Date()
    const start = new Date()
    start.setMonth(end.getMonth() - 6)
    const resp = await rpmClient.post('/GetPatientEvents', {
      patientId: state.patient.id,
      eventKind: 'DeprecatedRpmNote',
      start: start.toISOString(),
      end: end.toISOString(),
    })

    // this should get each note then categorize it
    // auto (maybe discard for now)
    // event post (display the event in big bold glory)
    // medication post
    // condition post?
    // response post

    const notesDictionary = {}

    for (const note of resp.data.events) {
      const noteObject = {
        id: note.id,
        author: note.data.authorName,
        timestamp: note.timestamp,
        fullNote: note,
        unprocessedNote: note,
        mentions: [],
        media: null,
        text: note.data.note,
        replies: [],
        templateID: note.data.templateID,
      }

      if (note.data?.mentions && note.data.mentions.length > 0) {
        noteObject.mentionText = note.data.mentions[0].name
        const remaining = note.data.mentions.length - 1
        if (remaining > 0) {
          noteObject.additionalMentions = '+' + remaining
        }
      }

      notesDictionary[note.id] = noteObject
    }

    // run through all notes, nest replies under their parent notes
    for (const note of Object.values(notesDictionary)) {
      if (note.fullNote.data?.referenceData?.type === 'response') {
        const theNoteThisBelongsTo = notesDictionary[note.fullNote.data.referenceData.thread]
        if (theNoteThisBelongsTo) {
          theNoteThisBelongsTo.replies.push(note)
          delete notesDictionary[note.id]
        }
      }
    }
    // Sort replies on notes
    for (const note of Object.values(notesDictionary)) {
      note.replies.sort((a, b) => {
        return (a.timestamp > b.timestamp) - (a.timestamp < b.timestamp)
      }) // Sort replies time ascending: oldest first
    }

    const sortedNotes = Object.values(notesDictionary).sort((a, b) => {
      return (a.timestamp < b.timestamp) - (a.timestamp > b.timestamp)
    }) // Sort notes time descending: newest first
    commit('_setDeprecatedNotes', sortedNotes)
    commit('_setDeprecatedNotesLoading', false)
  },
  async _loadYearOfMetrics({ commit, dispatch, state }) {
    // pull the last year of data for each kind of data a patient has
    const yearAgo = new Date()
    yearAgo.setHours(0, 0, 0, 0)
    yearAgo.setFullYear(yearAgo.getFullYear() - 1)

    const kindsToPull = []
    const dontPull = ['RPM_NOTE']
    for (const kind in state.patient.rpm?.lastReadings) {
      // pull kinds that aren't specifically excluded AND that have data within the past year
      if (!dontPull.includes(kind) && Date.parse(state.patient.rpm.lastReadings[kind].ts) > yearAgo) {
        kindsToPull.push(kind)
        dispatch('_loadYearOfMetricsForKind', { kind, since: yearAgo })
      }
    }
    commit('_setYearOfMetricsKindsToPull', kindsToPull)
  },
  async _loadYearOfMetricsForKind({ commit, state }, { kind, since }) {
    const resp = await rpmClient.post('/GetPatientEvents', {
      patientId: state.patient.id,
      eventKind: kind,
      start: since.toISOString(),
    })
    for (const event of resp.data.events) {
      prepMetric(event)
    }
    commit('_setYearOfMetricsForKind', { kind, readings: resp.data.events })
  },
  async _watchAccessLogsInFirebase({ commit, rootState, state }) {
    const cutoff = new Date().getTime() / 1000 - 86400 * 7
    commit('_setAccessLogsLoading', true)
    const unsub = firebase
      .firestore()
      .collection('patientDataAccessLog')
      .where('patient', '==', state.patient.id)
      .orderBy('timestamp')
      .where('timestamp', '>', cutoff)
      .onSnapshot(
        snap => {
          const entries = []
          for (let entry of snap.docs) {
            entry = entry.data()
            prepAccessLog(entry, rootState.org.usersDict)
            entries.push(entry)
          }
          commit('_setAccessLogs', entries.reverse())
          commit('_setAccessLogsLoading', false)
        },
        e => {
          console.error('patientDataAccessLog.onSnapshot error: ', e)
        }
      )
    commit('_addResetHook', unsub)
  },
  async _doThenRefresh({ commit, state }, fn) {
    commit('_setPatientUpdating', true)
    const result = await fn()
    const resp = await patientsClient.post('/GetPatient', { id: state.patient.id })
    prepPat(resp.data.patient)
    commit('org/replacePatient', { id: state.patient.id, patient: resp.data.patient }, { root: true })
    commit('_setPatient', resp.data.patient)
    commit('_setPatientUpdating', false)
    return result
  },
  async ackAlert({ dispatch }, patientEventId) {
    await dispatch('_doThenRefresh', async () => {
      await rpmClient.post('/AckAlert', { patientEventId })
    })
  },
  async flagNoteForProviderReview(_, noteToFlag) {
    console.log('flagging for review', noteToFlag)
    firebase
      .firestore()
      .collection('review')
      .doc(noteToFlag.patient + '' + noteToFlag.ts)
      .set(noteToFlag)
      .then(() => {
        //console.log('Document successfully written!')
      })
      .catch(error => {
        console.error('Error writing document: ', error)
      })
  },

  async convertLegacyReviewNotesToFirebaseReviewNotes({ rootState, dispatch }, patientObject) {
    if (patientObject.rpm.notes.length > 0 && patientObject.tags.includes('Provider Review')) {
      console.log('converting', patientObject)
      let latestNote = patientObject.rpm.notes[0]

      if (!latestNote.tags.includes('Provider Review')) {
        patientObject.rpm.notes.every(note => {
          if (note.tags.includes('Provider Review')) {
            latestNote = note
            return false
          }
          return true
        })
      }
      if (latestNote.tags.includes('Provider Review')) {
        console.log('converting a note!')
        let notification = {
          org: rootState.org.org.id,
          noteAuthor: latestNote.userId,
          patient: patientObject.id,
          ts: latestNote.ts,
          notificationTimestamp: new Date().getTime(),
          providerReview: true,
        }
        await dispatch('flagNoteForProviderReview', notification)
        await dispatch('oneOffUpdate', {
          patientId: patientObject.id,
          payload: { tags: { value: patientObject.tags.filter(e => e !== 'Provider Review') } },
        })

        // remove provider review tag
      }
    }

    if (patientObject.rpm.notes.length > 0 && patientObject.tags.includes('Care Navigator Review')) {
      let latestNote = patientObject.rpm.notes[0]
      if (latestNote.tags.includes('Care Navigator Review')) {
        let notification = {
          org: rootState.org.org.id,
          noteAuthor: latestNote.userId,
          patient: patientObject.id,
          ts: latestNote.ts,
          notificationTimestamp: new Date().getTime(),
          providerReview: false,
        }

        await dispatch('flagNoteForProviderReview', notification)
        await dispatch('oneOffUpdate', {
          patientId: patientObject.id,
          payload: { tags: { value: patientObject.tags.filter(e => e !== 'Care Navigator Review') } },
        })
      }
    }
  },

  async flagNoteForMention(_, noteToFlag) {
    firebase
      .firestore()
      .collection('mentions')
      .doc(noteToFlag.patient + '' + noteToFlag.ts + '' + noteToFlag.user)
      .set(noteToFlag)
      .then(() => {
        //console.log('Document successfully written!')
      })
      .catch(error => {
        console.error('Error writing document: ', error)
      })
  },

  async flagNoteForTickler(_, noteToFlag) {
    firebase
      .firestore()
      .collection('ticklers')
      .doc(noteToFlag.patient + '' + noteToFlag.ts)
      .set(noteToFlag)
      .then(() => {
        //console.log('Document successfully written!')
      })
      .catch(error => {
        console.error('Error writing document: ', error)
      })
  },

  async dismissTickler(_, notificationToRemove) {
    firebase
      .firestore()
      .collection('ticklers')
      .doc(notificationToRemove.notificationIDForEditing)
      .delete()
      .then(() => {
        console.log('Document successfully deleted!')
      })
      .catch(error => {
        console.error('Error writing document: ', error)
      })
  },

  async dismissCareTeamReview(_, notificationToRemove) {
    firebase
      .firestore()
      .collection('review')
      .doc(notificationToRemove.notificationIDForEditing)
      .delete()
      .then(() => {
        console.log('Document successfully deleted!')
      })
      .catch(error => {
        console.error('Error writing document: ', error)
      })
  },
  async dismissMention(_, notificationToRemove) {
    firebase
      .firestore()
      .collection('mentions')
      .doc(notificationToRemove.notificationIDForEditing)
      .delete()
      .then(() => {
        console.log('Document successfully deleted!')
      })
      .catch(error => {
        console.error('Error writing document: ', error)
      })
  },

  async addNote({ dispatch, state, rootState }, { text, tags, userMentions, alertsToClear, tickler }) {
    await dispatch('_doThenRefresh', async () => {
      await rpmClient.post('/AddNote', { patientId: state.patient.id, text, tags })
      // get the timestamp of the note.
      let resp = await patientsClient.post('/GetPatient', { id: state.patient.id })

      // this should return maybe?
      if (alertsToClear) {
        for (const eventId of alertsToClear) {
          dispatch('ackAlert', eventId)
        }
      }

      if (tags.includes('Provider Review')) {
        // get the most recent note.
        let mostRecentNote = resp.data.patient.rpm.notes.sort((a, b) => b.ts - a.ts)[0]
        console.log('orgId', rootState.org.org.id)
        let notification = {
          org: rootState.org.org.id,
          noteAuthor: mostRecentNote.userId,
          patient: state.patient.id,
          ts: mostRecentNote.ts,
          notificationTimestamp: new Date().getTime(),
          providerReview: true,
        }
        dispatch('flagNoteForProviderReview', notification)
      }
      if (userMentions && userMentions.length > 0) {
        let mostRecentNote = resp.data.patient.rpm.notes.sort((a, b) => b.ts - a.ts)[0]
        userMentions.forEach(async mention => {
          let notification = {
            org: rootState.org.org.id,
            noteAuthor: mostRecentNote.userId,
            patient: state.patient.id,
            ts: mostRecentNote.ts,
            notificationTimestamp: new Date().getTime(),
            user: mention,
          }
          dispatch('flagNoteForMention', notification)
        })
      }

      if (tickler) {
        let mostRecentNote = resp.data.patient.rpm.notes.sort((a, b) => b.ts - a.ts)[0]
        let notification = {
          org: rootState.org.org.id,
          noteAuthor: mostRecentNote.userId,
          patient: state.patient.id,
          ts: mostRecentNote.ts,
          notificationTimestamp: new Date().getTime(),
          ticklerDate: tickler,
        }
        dispatch('flagNoteForTickler', notification)
      }
    })
  },
  async addNoteComment(
    { dispatch, state, rootState },
    { noteUserId, noteTs, text, providerReview, dismissMention, dismissCareTeamReview, dismissTickler }
  ) {
    await dispatch('_doThenRefresh', async () => {
      await rpmClient.post('/AddNoteComment', { patientId: state.patient.id, noteUserId, noteTs, text })
      // dismiss any mentions for this note?
      if (dismissMention !== undefined) {
        console.log('dismiss mention is not null', dismissMention)
        await dispatch('dismissMention', dismissMention)
      }
      if (dismissTickler !== undefined) {
        await dispatch('dismissTickler', dismissTickler)
      }
      if (dismissCareTeamReview !== undefined) {
        console.log('dismiss careteam is not null', dismissCareTeamReview)
        await dispatch('dismissCareTeamReview', dismissCareTeamReview)
      }
      if (providerReview) {
        let notification = {
          org: rootState.org.org.id,
          noteAuthor: noteUserId,
          patient: state.patient.id,
          ts: noteTs,
          comment: true,
          notificationTimestamp: new Date().getTime(),
          providerReview: true,
        }
        await dispatch('flagNoteForProviderReview', notification)
      }
    })
  },
  async addReading({ state }, { ts, data }) {
    return await rpmClient.post('/AddPatientEvent', {
      patientId: state.patient.id,
      ts: (ts || new Date()).toISOString(),
      deviceName: 'Manual Entry',
      ...data,
    })
  },
  async logAccess({ commit, rootState }, { destination, patientID }) {
    const newAccessLogEntry = {
      patient: patientID,
      user: rootState.auth.user.id,
      timestamp: new Date().getTime(),
    }
    if (destination) {
      newAccessLogEntry.destination = destination
    }

    try {
      commit('_setAccessLogsLoading', true)
      await firebase.firestore().collection('patientDataAccessLog').add(newAccessLogEntry)
    } catch (e) {
      commit('_setAccessLogsLoading', false)
      console.error('Error adding patient access log: ', e)
    }
  },
  async update({ dispatch, state }, { payload }) {
    await dispatch('_doThenRefresh', async () => {
      console.log('updating current patient')
      await patientsClient.post('/Update', {
        id: state.patient.id,
        ...payload,
      })
    })
  },
  async updateTags({ dispatch }, tagList) {
    await dispatch('update', { payload: { tags: { value: tagList } } })
  },
  async updateCarePlan({ dispatch }, { rpmCarePlan }) {
    await dispatch('update', { payload: { rpmCarePlan: { value: rpmCarePlan } } })
  },
  async updateGeneralNotes({ dispatch }, { notes }) {
    await dispatch('update', { payload: { generalNotes: { value: notes } } })
  },
  async updateThresholdSettings({ dispatch }, { thresholds }) {
    await dispatch('update', { payload: { eventRules: { value: thresholds } } })
  },
  // oneOffUpdate enables updating a different patient other than the loaded patient, kinda hacky
  async oneOffUpdate({ commit, dispatch, state }, { patientId, payload }) {
    console.log('one off')
    if (patientId === state.patient?.id) return await dispatch('update', { payload }) // same as loaded patient, just do normal update
    await patientsClient.post('/Update', {
      id: patientId,
      ...payload,
    })
    const resp = await patientsClient.post('/GetPatient', { id: patientId })
    prepPat(resp.data.patient)
    commit('org/replacePatient', { id: patientId, patient: resp.data.patient }, { root: true })
  },
  // oneOffUpdateREmoteOnly enables updating a different patient other than the loaded patient and doesnt pull the new data back down. it assumes that youve made the changes locally, kinda hacky
  async oneOffUpdateRemoteOnly(_, { patientId, payload }) {
    console.log('one off remote')

    await patientsClient.post('/Update', {
      id: patientId,
      ...payload,
    })
  },
  async exportFacesheet({ dispatch }, { patient: patient, start: start }) {
    // this should also set the order. BP goes first, then HR, then glucose, then weight.
    const metricKindOrder = ['BloodPressure', 'Pulse', 'BloodGlucose', 'BodyWeight', 'Ecg']

    const displayedKinds = []
    const patientMetricKinds = Object.keys(patient.rpm.lastReadings || {})
    for (const kind of metricKindOrder) {
      if (!patientMetricKinds.includes(kind)) continue
      displayedKinds.push(kind)
    }

    // this function creates the table headers, and calculates the column widths specific to the metrics this particular patient has.
    function createHeaders(keys) {
      const result = []
      for (var i = 0; i < keys.length; i += 1) {
        let standardWidth = 80
        let w = standardWidth
        if (i == 0) {
          w = 735 - standardWidth * displayedKinds.length
        }
        let headerTitle = 'Date'
        if (CONSTS.METRICS[keys[i]]) {
          headerTitle = CONSTS.METRICS[keys[i]].dashboardAbbreviation
        }

        result.push({
          id: keys[i],
          name: keys[i],
          prompt: headerTitle,
          width: w,
          align: 'left',
          padding: 10,
        })
      }
      return result
    }

    // this section fetches the latest metrics within the date range for each of the metrics that are available for this patient
    const headersProto = ['date']
    const tsToData = {}
    for (const kind of displayedKinds) {
      headersProto.push(kind)
      const data = await dispatch('getMetricsInRange', {
        kind: kind,
        afterMs: start,
      })
      for (const dataPoint of data) {
        if (tsToData[dataPoint.tsMs]) {
          tsToData[dataPoint.tsMs][kind] = dataPoint.displayValue.toString()
          continue
        }
        const objectForPlacement = {}
        for (const displayedKind of displayedKinds) {
          objectForPlacement[displayedKind] = '-'
        }
        objectForPlacement[kind] = dataPoint.displayValue.toString()
        objectForPlacement.date = msToUSDateTime(dataPoint.tsMs)
        tsToData[dataPoint.tsMs] = objectForPlacement
      }
    }

    const data = []
    let id = 0
    const keys = Object.keys(tsToData).sort((a, b) => {
      return b - a
    })
    for (const key of keys) {
      let obj = tsToData[key]
      obj.id = id.toString()
      data.push(obj)
      id = id + 1
    }

    generateFacesheet(patient, start, new Date().getTime(), data, createHeaders(headersProto))
  },
  getMetricsInRange({ state }, { kind, beforeMs, afterMs }) {
    const events = state.yearOfMetrics[kind] || [] // time-descending array of events
    const start = !beforeMs
      ? 0
      : binarySearch(events, event => {
          return event.tsMs <= beforeMs
        }) // indexes HERE AND AFTER this will be before or equal to `before`
    const end = binarySearch(events, event => {
      return event.tsMs < afterMs
    }) // indexes BEFORE this will be after or equal to `after`
    return events.slice(start, end)
  },
  async globalPatientSearch(_, searchTerm) {
    // is this just a phone number?
    // could this be a name?
    //could this be a patient ID?
    // maybe just focus on phone and last nameat the moment?
    let body = { lastNameFuzzyPrefix: null, orgPtIdPrefix: null, phonePrefix: null }
    let onlyNumbers = searchTerm.replace(/\D/g, '')

    if (onlyNumbers.length > 0) {
      // this has to be a phone number
      // add in the right dashes
      let formattedPhone
      if (onlyNumbers.length > 6) {
        formattedPhone = onlyNumbers.substring(0, 3) + '-' + onlyNumbers.substring(3, 6) + '-'
      } else if (onlyNumbers.length > 3) {
        formattedPhone = onlyNumbers.substring(0, 3) + '-' + onlyNumbers.substring(3, 6)
      } else {
        formattedPhone = onlyNumbers
      }
      body.phonePrefix = formattedPhone
    } else {
      body.lastNameFuzzyPrefix = searchTerm.trim()
    }

    const resp = await patientsClient.post('/Search', body)
    console.log('global PatientSearch', resp)
    return resp
  },
}

function prepAccessLog(entry, orgUsersDict) {
  if (!entry.user) {
    entry.user = 'Unauthenticated'
  } else {
    entry.userDisplayName = 'User ' + entry.user
    const u = orgUsersDict[entry.user]
    if (u) {
      entry.userDisplayName = u.firstName + ' ' + u.lastName
    }
  }

  entry.timestampString = msToUSDateTime(entry.timestamp)
  entry.description = entry.destination
  switch (entry.destination) {
    case 'Patient':
      entry.description = 'Patient Chart'
      break
    case 'PatientInfo':
      entry.description = 'Patient Information'
      break
    case 'Messages':
      entry.description = 'Messages'
      break
    default:
      entry.description = entry.destination
      break
  }
}
