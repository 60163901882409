<template>
  <v-dialog v-model="show" :max-width="900" scrollable>
    <template v-slot:activator="{ on: show, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-scale-transition hide-on-leave origin="center">
            <v-btn
              class="mr-1"
              icon
              v-bind="attrs"
              v-on="{ ...tooltip, ...show }"
              v-show="selectedPatients.length > 0 || alwaysOn"
              :disabled="selectedPatients.length === 0"
            >
              <v-icon size="23">mdi-message-text-outline</v-icon>
            </v-btn>
          </v-scale-transition>
        </template>
        <span>Send Texts to Group</span>
      </v-tooltip>
    </template>

    <v-card style="height: 500px; max-height: 500px; position: relative" rounded="lg">
      <!-- Content -->
      <v-card-text class="ma-0 pa-0">
        <v-row no-gutters>
          <v-col cols="4" style="height: 500px; overflow: auto">
            <v-card height="500" style="overflow: auto" tile color="background">
              <v-toolbar color="background" flat>
                <v-row no-gutters justify="start">
                  <span class="font-weight-bold text-h6">Recipients </span>
                </v-row>
              </v-toolbar>
              <v-slide-y-transition class="py-0" group>
                <v-list-item
                  style="border-bottom: 1px solid #00000008"
                  v-for="(item, index) in ptsWithMeta"
                  :key="index"
                >
                  <v-list-item-icon class="mr-2">
                    <div>
                      <v-icon v-if="item.sendDone && item.sendSuccess" color="green">mdi-check-circle</v-icon>
                      <v-icon v-else-if="item.sendDone" color="red">mdi-close-circle</v-icon>
                      <v-icon v-else-if="!item.eligible" color="red">mdi-close-circle-outline</v-icon>
                      <v-icon v-else-if="!item.sending" color="green">mdi-checkbox-blank-circle-outline</v-icon>
                      <v-progress-circular v-else-if="item.sending" indeterminate size="18" width="2" color="primary">
                      </v-progress-circular>
                    </div>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span class="font-weight-regular text-body-2 d-block text-truncate">
                        {{ item.pt.firstName }}
                        <span class="font-weight-bold text-body-2">{{ item.pt.lastName }}</span>
                        <span class="text--secondary ml-1">{{ item.pt.org.patientId }}</span>
                      </span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-row v-if="item.eligible" class="text-truncate text-body-2" no-gutters>
                        <span> {{ item.pt.smsPhone }}</span>
                      </v-row>
                      <v-row v-else-if="!item.pt.smsPhone" no-gutters>
                        <span class="text-body-2" style="font-weight: 700; color: red">Missing Phone</span>
                      </v-row>
                      <v-row v-else-if="item.activeConvo && !item.sendSuccess" no-gutters>
                        <span class="text-body-3" style="font-weight: 700; color: red">Active Conversation</span>
                      </v-row>
                      <v-row v-else-if="item.activeConvo && item.sendSuccess" no-gutters>
                        <span class="text-body-3" style="font-weight: 700; color: green">Message Sent</span>
                      </v-row>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-slide-y-transition>
            </v-card>
          </v-col>

          <v-col>
            <v-card flat tile>
              <v-toolbar color="white" flat>
                <v-row no-gutters justify="start" align="center">
                  <span class="font-weight-bold text-h6">Message </span>
                  <v-spacer />
                  <span v-if="sendingTexts" class="font-weight-medium primary--text text-body-2" style="opacity: 0.9">
                    {{ textCounter }} of {{ eligiblePtsWithMeta.length }}
                  </span>
                  <span v-else class="font-weight-regular text-body-2 primary--text" style="opacity: 0.9">
                    {{ eligiblePtsWithMeta.length }} Eligible Recipient<span v-if="eligiblePtsWithMeta.length !== 1"
                      >s</span
                    >
                  </span>
                </v-row>
              </v-toolbar>
              <v-progress-linear
                absolute
                background-color="white"
                height="2"
                :value="(textCounter / eligiblePtsWithMeta.length) * 100"
                :indeterminate="textCounter === 0 && sendingTexts"
              />

              <v-row class="pt-3 px-4" no-gutters>
                <v-card outlined rounded="lg" flat height="40">
                  <v-row align="center" no-gutters>
                    <v-col>
                      <v-select
                        height="30"
                        :items="messageTemplates"
                        background-color="transparent"
                        item-text="title"
                        dense
                        hide-details
                        solo
                        flat
                        placeholder="Templates"
                        v-model="selectedTemplate"
                        return-object
                        full-width
                        prepend-inner-icon="mdi-star-outline"
                        clearable
                      >
                      </v-select>
                    </v-col>
                    <v-divider vertical />
                    <v-btn tile text small @click="insertFirstNameAtCursor()" depressed height="40"
                      ><v-icon size="20" left>mdi-code-braces</v-icon>pt f. name</v-btn
                    >
                    <v-divider class="mx-0 px-0" vertical />
                    <v-btn tile small height="40" @click="insertVitalsListAtCursor()" depressed text
                      ><v-icon size="20" left>mdi-code-braces</v-icon>pt vitals</v-btn
                    >
                    <v-divider class="mx-0 px-0" vertical />

                    <v-dialog v-model="previewDialog" width="320" ref="previewDialog">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          :disabled="exampleOutput === null"
                          tile
                          small
                          height="40"
                          depressed
                          text
                        >
                          <v-icon size="20" left>mdi-cellphone-message</v-icon>Preview
                        </v-btn>
                      </template>

                      <v-card>
                        <v-toolbar class="font-weight-bold text-h6 text--secondary" flat> Message Preview </v-toolbar>
                        <v-row no-gutters class="mx-3 pt-1 pb-6">
                          <v-card style="border-radius: 15px" class="px-3 py-2" color="#00D549" flat>
                            <span class="white--text">{{ exampleOutput }}</span>
                          </v-card>
                        </v-row>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn depressed @click="previewDialog = false"> close </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-row>
                </v-card>
              </v-row>
              <v-card flat height="316">
                <v-textarea
                  color="green"
                  ref="textarea"
                  class="font-weight-regular text-body-1 px-4"
                  no-resize
                  v-model="textMessage"
                  counter
                  placeholder="Enter your message here..."
                  flat
                  hide-details
                  rows="11"
                  height="316"
                >
                </v-textarea>
              </v-card>
            </v-card>
            <v-card flat tile height="64">
              <v-row no-gutters class="fill-height px-4" align="center">
                <v-btn @click="cancel" depressed>cancel</v-btn>
                <v-spacer />
                <v-dialog v-model="saveTemplateDialog" width="500" ref="saveTemplateDialog">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      outlined
                      v-show="showSaveAsTemplate && isOrgAdmin"
                      @click="savedTemplateTitle = null"
                    >
                      Save as template
                    </v-btn>
                  </template>

                  <v-card>
                    <v-row
                      align="center"
                      no-gutters
                      justify="space-between"
                      class="font-weight-bold text-h6 px-2"
                      style="height: 60px"
                    >
                      <v-text-field
                        ref="templateNameText"
                        v-model="savedTemplateTitle"
                        style="font-size: 20px"
                        solo
                        flat
                        hide-details
                        placeholder="Template Name"
                      >
                      </v-text-field>
                    </v-row>

                    <v-textarea
                      class="font-weight-light text-body-1 px-2"
                      no-resize
                      v-model="textMessage"
                      hide-details
                      placeholder="Message"
                      solo
                      flat
                      height="150"
                    >
                    </v-textarea>

                    <v-card-actions>
                      <v-btn depressed @click="saveTemplateDialog = false"> cancel </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn depressed color="primary" @click="saveTemplateWithTitle()"> Save Template </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-btn
                  :disabled="!allDoneSending && (eligiblePtsWithMeta.length === 0 || textMessage === '')"
                  @click="actionClick"
                  depressed
                  color="primary"
                >
                  <v-scale-transition hide-on-leave origin="center">
                    <v-progress-circular
                      indeterminate
                      size="20"
                      width="3"
                      color="white"
                      v-show="sendingTexts && !allDoneSending"
                      class="mr-3"
                    />
                  </v-scale-transition>

                  <v-scale-transition hide-on-leave origin="center">
                    <span v-show="!sendingTexts && !allDoneSending && eligiblePtsWithMeta.length > 0">
                      Send {{ eligiblePtsWithMeta.length }} Message<span v-if="eligiblePtsWithMeta.length > 1">s</span>
                    </span>
                  </v-scale-transition>
                  <v-scale-transition hide-on-leave origin="center">
                    <span v-show="!sendingTexts && !allDoneSending && eligiblePtsWithMeta.length === 0"
                      >No Eligible Recipients</span
                    >
                  </v-scale-transition>
                  <v-scale-transition hide-on-leave origin="center">
                    <span v-show="allDoneSending">Done</span>
                  </v-scale-transition>
                  <v-scale-transition hide-on-leave origin="center">
                    <span v-show="sendingTexts">Sending</span>
                  </v-scale-transition>
                </v-btn>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogMixin from '@/core/mixins/DialogMixin'
import { mapGetters, mapState, mapActions } from 'vuex'
import bus from '@/core/helpers/bus'
import { applyTextExpansionToString } from '@/helpers/text'

function data() {
  return {
    ptsWithMeta: [], // [{ pt: patient, activeConvo: bool, eligible: bool, sending: bool, sendDone: bool, sendSuccess: bool }]
    eligiblePtsWithMeta: [], // those of ptsWithMeta with eligible: true.
    textMessage: '',
    allDoneSending: false,
    textCounter: 0,
    sendingTexts: false,
    saveTemplateDialog: false,
    savedTemplateTitle: null,
    selectedTemplate: null,
    previewDialog: false,
  }
}

export default {
  mixins: [DialogMixin],
  data() {
    return data()
  },
  props: {
    selectedPatients: Array,
    alwaysOn: Boolean,
  },
  methods: {
    ...mapActions('conversation', ['sendMessage']),
    ...mapActions('org', ['addMessageTemplate']),
    resetData() {
      Object.assign(this, data())
    },
    actionClick() {
      if (this.allDoneSending) this.cancel()
      else if (!this.sendingTexts) this.sendTheMessages()
    },
    insertTemplate(template) {
      this.textMessage = template.content || ''
    },
    insertFirstNameAtCursor() {
      this.execInsertText('{patient_first_name}')
    },
    insertVitalsListAtCursor() {
      this.execInsertText('{vitals_list}')
    },
    execInsertText(insertText) {
      if (!insertText.length) return

      const textarea = this.$refs.textarea.$refs.input
      const sentence = textarea.value

      const len = sentence.length
      let pos = textarea.selectionStart
      if (pos === undefined) {
        pos = 0
      }

      let positionEnd = pos

      if (textarea.selectionEnd && textarea.selectionEnd !== pos) {
        // you've chosen to replace an entire selection with a text expansion prompt
        // remove the entire word that was selected and replace it with the correct text
        positionEnd = textarea.selectionEnd
      }

      let before = sentence.substr(0, pos)
      let after = sentence.substr(positionEnd, len)

      // add a space if there isnt one?

      if (before.length && before.slice(-1) !== null && before.slice(-1) !== ' ') {
        before = before + ' '
      }

      if (
        after.charAt(0).length &&
        after.charAt(0) !== ' ' &&
        after.charAt(0) !== '!' &&
        after.charAt(0) !== '.' &&
        after.charAt(0) !== '?' &&
        after.charAt(0) !== ','
      ) {
        after = ' ' + after
      }

      this.textMessage = before + insertText + after
      const vm = this
      this.$nextTick().then(() => {
        textarea.selectionStart = before.length + insertText.length
        textarea.selectionEnd = before.length + insertText.length
        vm.$refs.textarea.focus()
      })
    },
    async sendTheMessages() {
      if (!this.textMessage) {
        bus.$emit('toast', { type: 'error', text: 'No message entered' })
        return
      }

      // send to each recipient
      this.sendingTexts = true
      for (const pwm of this.eligiblePtsWithMeta) {
        pwm.sending = true
        this.textCounter++
        const msg = applyTextExpansionToString(pwm.pt, this.textMessage)

        const data = await this.sendToPatient(pwm.pt.id, msg)
        pwm.sending = false
        pwm.sendDone = true
        pwm.sendSuccess = data.status === 200

        // hacky, update patient's lastMsg, NOTE this is not reactive, so other things
        // looking at patient's lastMsg will not be aware of this change
        pwm.pt.tele.messaging.lastMsg = data.msg
        pwm.activeConvo = true
      }
      this.sendingTexts = false
      this.allDoneSending = true
      bus.$emit('toast', { type: 'success', text: 'Messages Sent' })
    },
    async sendToPatient(patientId, message) {
      try {
        return { status: 200, msg: await this.sendMessage({ patientId: patientId, content: message }) }
      } catch {
        return { status: 400 }
      }
    },
    saveTemplateWithTitle() {
      if (!this.savedTemplateTitle) return
      this.addMessageTemplate({
        title: this.savedTemplateTitle,
        content: this.textMessage,
      })
      this.saveTemplateDialog = false
      bus.$emit('toast', { type: 'success', text: 'Template saved' })
    },
  },
  watch: {
    selectedTemplate() {
      if (this.selectedTemplate) this.insertTemplate(this.selectedTemplate)
    },
    showSaveAsTemplate() {
      if (this.showSaveAsTemplate) this.selectedTemplate = null
    },
    // on show, initialize stuff
    show() {
      if (!this.show) return
      this.resetData()

      const threeHoursAgo = new Date().getTime() - 3600000 * 3
      for (const pt of this.selectedPatients) {
        const lastMsgCreated = pt.tele.messaging.lastMsg?.created
        const activeConvo = !!lastMsgCreated && new Date(lastMsgCreated).getTime() > threeHoursAgo
        const eligible = !!pt.smsPhone && !activeConvo
        const ptWithMeta = { pt, activeConvo, eligible }
        this.ptsWithMeta.push(ptWithMeta)
        if (!eligible) continue
        Object.assign(ptWithMeta, { sending: false, sendDone: false, sendSuccess: false })
        this.eligiblePtsWithMeta.push(ptWithMeta)
      }
    },
    async saveTemplateDialog() {
      await this.$nextTick()
      this.$refs.templateNameText.$refs.input.focus()
    },
  },
  computed: {
    ...mapGetters('auth', ['isOrgAdmin']),
    ...mapState('patient', ['patient']),
    ...mapState('auth', ['user']),
    ...mapState('org', ['enrolledPatients', 'settings']),
    exampleOutput() {
      if (!this.textMessage || this.eligiblePtsWithMeta.length === 0) return null
      return applyTextExpansionToString(this.eligiblePtsWithMeta[0].pt, this.textMessage)
    },
    messageTemplates() {
      if (this.settings && this.settings.messageTemplates) {
        return Object.values(this.settings.messageTemplates).sort((a, b) => a.title.localeCompare(b.title))
      }
      return []
    },
    showSaveAsTemplate() {
      if (!this.textMessage || this.sendingTexts || this.allDoneSending) return false
      return !this.messageTemplates.some(tmpl => tmpl.content === this.textMessage) // true iff no templates have the same content
    },
  },
}
</script>
