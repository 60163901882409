import { cToF, kgToLbs } from '@/helpers/conversions'

import CONSTS from '@/consts'
import { msToUSDateTime } from '@/helpers/time'
import { specificRelativeTimeWithHoursAndMinutes } from '@/helpers/time'

// castMetricKind casts k to to new metric kinds (if already a new metric)
export function castMetricKind(k) {
  if (CONSTS.METRICS_KINDS.has(k)) {
    return k
  }
  const newK = CONSTS.METRICS_KINDS_OldToNew[k]
  if (!newK) {
    console.warn('unknown metric kind', k)
  }
  return newK
}

export function prepMetric(metric) {
  metric.kind = castMetricKind(metric.kind)
  Object.assign(metric, CONSTS.METRICS[metric.kind] || { icon: 'mdi-help', units: '' })
  metric.tsDate = new Date(metric.ts)
  metric.tsMs = metric.tsDate.getTime()
  metric.readableDate = msToUSDateTime(metric.tsMs)
  metric.source = metric.peripheralName
  metric.timeAgo = specificRelativeTimeWithHoursAndMinutes(metric.tsMs)

  switch (metric.kind) {
    case 'BloodGlucose':
      metric.displayValue = Math.round(metric.data.value * 100) / 100
      metric.graphValue = metric.displayValue
      break
    case 'BloodOxygen':
      metric.displayValue = metric.data.value
      metric.graphValue = metric.displayValue
      break
    case 'BloodPressure':
      metric.data.systolicValue = Math.round(metric.data.systolicValue)
      metric.data.diastolicValue = Math.round(metric.data.diastolicValue)
      metric.displayValue = metric.data.systolicValue + '/' + metric.data.diastolicValue
      metric.graphValue = null // special handling
      break
    case 'BodyWeight':
      metric.displayValue = Math.round(kgToLbs(metric.data.value) * 10) / 10
      metric.graphValue = metric.displayValue
      break
    case 'BodyTemp':
      metric.displayValue = Math.round(cToF(metric.data.value) * 10) / 10
      metric.graphValue = metric.displayValue
      break
    case 'Pulse':
      metric.displayValue = Math.round(metric.data.value)
      metric.graphValue = metric.displayValue
      break
    case 'Ecg':
      metric.displayValue = metric.data.afib ? 'Possible Afib' : 'No Afib'
      metric.graphValue = 0
      break
    default:
      metric.displayValue = metric.data.value
      break
  }
  metric.value = metric.displayValue // back compat?
  metric.eventString = (metric.displayValue + ' ' + metric.units).trim()
}
