<template>
  <v-card class="py-5" flat>
    <portal v-if="appliedFilters.length === 0" to="appBarContentLeft">
      <v-row class="flex-nowrap primary--text" align="center" no-gutters>
        <span style="font-weight: bold"> Population View </span>
      </v-row>
    </portal>

    <portal v-if="appliedFilters.length > 0" to="appBarContentLeft">
      <v-toolbar-items>
        <v-btn @click="clearInboxFilters()" dark style="margin-left: -18px; font-size: 14px" depressed color="primary"
          ><span> {{ appliedFilters.length }} filter<span v-if="appliedFilters.length !== 1">s</span> applied</span
          ><v-icon v-if="appliedFilters.length !== 0" right>mdi-close-circle</v-icon></v-btn
        >
      </v-toolbar-items>
    </portal>
    <div v-if="showGraphs">
      <v-row class="px-4 mb-4" justify="space-between" no-gutters>
        <enrollment-age
          @selectedFilter="toggleFilter"
          patientSortKey="enrollmentAgeCategory"
          :filterCategory="categoriesForSorting.enrollmentAge"
          :population="filteredArray"
          chartTitle="Weeks Enrolled"
          :filters="appliedFilters"
        />
      </v-row>

      <v-row class="px-2" justify="space-between" no-gutters>
        <v-col cols="4" class="px-2">
          <donut-filter
            @selectedFilter="toggleFilter"
            patientSortKey="qhpCategory"
            :filterCategory="categoriesForSorting.qhpTimeThisMonth"
            :population="filteredArray"
            chartTitle="Health Coaching"
            :filters="appliedFilters"
          />
        </v-col>
        <v-col class="px-2" cols="4">
          <donut-filter
            @selectedFilter="toggleFilter"
            patientSortKey="lastReadingCategory"
            :filterCategory="categoriesForSorting.timeSinceLastReading"
            :population="filteredArray"
            chartTitle="Last Reading"
            :filters="appliedFilters"
          />
        </v-col>
        <v-col class="px-2" cols="4">
          <donut-filter
            @selectedFilter="toggleFilter"
            patientSortKey="onTrackCategory"
            :filterCategory="categoriesForSorting.engagementPerfectionNeeded"
            :population="filteredArray"
            chartTitle="16 Day Forecast"
            :filters="appliedFilters"
          />
        </v-col>
      </v-row>

      <v-row v-if="tagCloud.length > 0" no-gutters class="px-4 mt-4">
        <v-card color="grey lighten-5" class="py-4 px-3 flex" min-height="100" flat>
          <span class="font-weight-bold">Patient Tags</span>
          <v-chip-group v-model="selectedTags" column multiple>
            <v-chip
              label
              :color="patientTagFilters.includes(tag.tag) ? 'primary' : 'grey lighten-2'"
              v-for="(tag, index) in tagCloud"
              :key="index"
            >
              {{ tag.tag }} ({{ tag.count }})
            </v-chip>
          </v-chip-group>
        </v-card>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import moment from 'moment-timezone'
import HelperMixin from '@/core/mixins/HelperMixin'
import DonutFilter from '@/components/summarycharts/DonutFilter.vue'
import EnrollmentAge from '@/components/summarycharts/EnrollmentAge.vue'
import CONSTS from '@/consts'

export default {
  mixins: [HelperMixin],
  components: { DonutFilter, EnrollmentAge },
  props: {
    filteredArray: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      stats: null,
      circleDiameter: 360,
      funimate: false,
      showCaracol: false,
      showGraphs: true,
      selectedTags: [],
    }
  },
  watch: {
    patientTagFilters(val) {
      if (val.length === 0 && this.selectedTags.length > 0) {
        this.selectedTags = []
      }
    },
    selectedTags(val) {
      let tagsToSend = []

      val.forEach(item => {
        tagsToSend.push(this.tagCloud[item].tag)
      })
      this.setPatientTagFilters(tagsToSend)
    },
    tagCloud() {
      //make sure the selected tags are in the tag cloud
      // let includedTags = val.map(e => e.tag)
      // this.selectedTags.forEach(item => {
      //   if (!includedTags.includes(item)) {
      //     this.selectedTags = this.selectedTags.filter(e => e.tag !== item.tag)
      //   }
      // })
    },
  },
  computed: {
    ...mapState('org', ['org', 'enrolledPatients', 'orgDevices', 'patientsLoading']),
    ...mapState('inbox', ['filters', 'complexFilters', 'patientList', 'patientTagFilters']),

    tagCloud() {
      let arrayOfArrayOfTags = this.enrolledPatients.map(a => a.tags)

      let tags = []
      arrayOfArrayOfTags.forEach(patientTags => {
        patientTags.forEach(tag => {
          if (!tags.includes(tag)) {
            tags.push(tag)
          }
        })
      })

      if (this.patientTagFilters.length > 0) {
        this.patientTagFilters.forEach(tag => {
          if (!tags.includes(tag)) {
            tags.push(tag)
          }
        })
      }

      let tagObjects = []

      tags.forEach(tag => {
        let count = this.filteredArray.filter(e => e.tags.includes(tag)).length
        tagObjects.push({ tag: tag, count: count })
      })

      return tagObjects
    },
    appliedFilters() {
      let filters = []
      this.complexFilters.forEach(filter => {
        if (filter.tags.length > 0) {
          filter.tags.forEach(filter => {
            filters.push(filter)
          })
        }
      })
      this.patientTagFilters.forEach(tag => {
        filters.push(tag)
      })
      return filters
    },
    headsUpCategories() {
      return [
        { title: 'Mentions', icon: 'mdi-at', text: "Notes you're mentioned in.", count: 200 },
        { title: 'New Alerts', icon: 'mdi-at', text: "Notes you're mentioned in.", count: 200 },
        { title: 'Text Messages', icon: 'mdi-at', text: "Notes you're mentioned in.", count: 200 },
        { title: 'Care Team Review', icon: 'mdi-at', text: "Notes you're mentioned in.", count: 200 },
        { title: 'New Patients', icon: 'mdi-at', text: "Notes you're mentioned in.", count: 200 },
      ]
    },
    categoriesForSorting() {
      return CONSTS.sortingCategories
    },
    tookAReadingInTheLast48() {
      let cutoff2 = new Date()
      cutoff2.setDate(cutoff2.getDate() - 2)

      return this.filteredArray.filter(function (e) {
        return e.mostRecentDataTimestamp > cutoff2.getTime()
      }).length
    },
    tookAReadingInTheLast15Days() {
      let cutoff2 = new Date()
      cutoff2.setDate(cutoff2.getDate() - 15)

      return this.enrolledPatients.filter(function (e) {
        return e.mostRecentDataTimestamp && e.mostRecentDataTimestamp > cutoff2.getTime()
      }).length
    },
    didntTakeAreadingInMoreThan15() {
      let cutoff2 = new Date()
      cutoff2.setDate(cutoff2.getDate() - 14)

      return this.enrolledPatients.filter(function (e) {
        return e.mostRecentDataTimestamp && e.mostRecentDataTimestamp <= cutoff2.getTime()
      })
    },
    tookAReadingInTheLastTwoDays() {
      let cutoff2 = new Date()
      cutoff2.setDate(cutoff2.getDate() - 2)
      cutoff2.setHours(0, 0, 0, 0)

      return this.enrolledPatients.filter(function (e) {
        return e.mostRecentDataTimestamp > cutoff2.getTime()
      }).length
    },
    tookAReading3To7DaysAgo() {
      let cutoff3 = new Date()
      cutoff3.setDate(cutoff3.getDate() - 2)
      cutoff3.setHours(0, 0, 0, 0)

      let cutoff5 = new Date()
      cutoff5.setDate(cutoff5.getDate() - 7)
      cutoff5.setHours(0, 0, 0, 0)

      return this.filteredArray.filter(function (e) {
        return e.mostRecentDataTimestamp >= cutoff5.getTime() && e.mostRecentDataTimestamp < cutoff3.getTime()
      }).length
    },
    tookAReading7To14DaysAgo() {
      let cutoff3 = new Date()
      cutoff3.setDate(cutoff3.getDate() - 7)
      cutoff3.setHours(0, 0, 0, 0)

      let cutoff5 = new Date()
      cutoff5.setDate(cutoff5.getDate() - 14)
      cutoff5.setHours(0, 0, 0, 0)

      return this.filteredArray.filter(function (e) {
        return e.mostRecentDataTimestamp >= cutoff5.getTime() && e.mostRecentDataTimestamp < cutoff3.getTime()
      }).length
    },
    tookAReading14To30DaysAgo() {
      let cutoff3 = new Date()
      cutoff3.setDate(cutoff3.getDate() - 14)
      cutoff3.setHours(0, 0, 0, 0)

      let cutoff5 = new Date()
      cutoff5.setDate(cutoff5.getDate() - 30)
      cutoff5.setHours(0, 0, 0, 0)

      return this.filteredArray.filter(function (e) {
        return e.mostRecentDataTimestamp >= cutoff5.getTime() && e.mostRecentDataTimestamp < cutoff3.getTime()
      }).length
    },
    tookAReading30To60DaysAgo() {
      let cutoff3 = new Date()
      cutoff3.setDate(cutoff3.getDate() - 30)
      cutoff3.setHours(0, 0, 0, 0)

      let cutoff5 = new Date()
      cutoff5.setDate(cutoff5.getDate() - 60)
      cutoff5.setHours(0, 0, 0, 0)

      return this.filteredArray.filter(function (e) {
        return e.mostRecentDataTimestamp >= cutoff5.getTime() && e.mostRecentDataTimestamp < cutoff3.getTime()
      }).length
    },
    tookAReading60PlusDaysAgo() {
      let cutoff5 = new Date()
      cutoff5.setDate(cutoff5.getDate() - 60)
      cutoff5.setHours(0, 0, 0, 0)

      return this.filteredArray.filter(function (e) {
        return e.mostRecentDataTimestamp < cutoff5.getTime()
      }).length
    },
    arrayOfTotals() {
      let complianceChart = []

      let totalsForMax = [
        this.tookAReadingInTheLastTwoDays,
        this.tookAReading3To7DaysAgo,
        this.tookAReading7To14DaysAgo,
        this.tookAReading14To30DaysAgo,
        this.tookAReading30To60DaysAgo,
        this.tookAReading60PlusDaysAgo,
      ]
      let top = Math.max(...totalsForMax)

      complianceChart.push({ title: '0-2', value: this.tookAReadingInTheLastTwoDays / top, color: 'green darken-1' })
      complianceChart.push({ title: '3 to 7', value: this.tookAReading3To7DaysAgo / top, color: 'green' })
      complianceChart.push({ title: '8 to 14', value: this.tookAReading7To14DaysAgo / top, color: 'green lighten-1' })
      complianceChart.push({ title: '15 to 30', value: this.tookAReading14To30DaysAgo / top, color: 'yellow' })
      complianceChart.push({ title: '31 to 60', value: this.tookAReading30To60DaysAgo / top, color: 'red lighten-1' })
      complianceChart.push({ title: 'More than 60', value: this.tookAReading60PlusDaysAgo / top, color: 'red' })
      return complianceChart
    },
    patientConditions() {
      let arrayOfArrayOfConditions = this.filteredArray.map(a => a.conditions)
      let conditionsWithoutDuplicates = []
      let icd10Codes = []
      arrayOfArrayOfConditions.forEach(patientConditions => {
        patientConditions.forEach(condition => {
          if (!icd10Codes.includes(condition.split('|')[0])) {
            icd10Codes.push(condition.split('|')[0])
            conditionsWithoutDuplicates.push({
              value: condition.split('|')[0],
              text: condition.split('|')[1],
            })
          }
        })
      })
      return conditionsWithoutDuplicates
    },
    circleThickness() {
      return this.circleDiameter * 0.055
    },
    qhpTimeToDate() {
      let qhpTotal = 0
      this.filteredArray.forEach(patient => {
        if (patient.millisecondsThisPeriod) {
          qhpTotal = qhpTotal + patient.millisecondsThisPeriod
        }
      })
      return qhpTotal / 60000
    },
    moreThanOneCondition() {
      let patientsWithMoreThanOneCondition = this.filteredArray.filter(function (e) {
        return e.conditions.length > 1
      }).length

      return patientsWithMoreThanOneCondition
    },
    messageToday() {
      let startOfToday = new Date()
      startOfToday.setHours(0, 0, 0, 0)

      let subsetPatients = this.filteredArray.filter(function (e) {
        return e.lastMessageTimestamp > startOfToday.getTime()
      })
      return subsetPatients.length
    },
    messageInLast7Days() {
      let sevenDays = new Date()
      sevenDays.setDate(sevenDays.getDate() - 7)
      sevenDays.setHours(0, 0, 0, 0)

      let subsetPatients = this.filteredArray.filter(function (e) {
        return e.lastMessageTimestamp > sevenDays.getTime()
      })
      return subsetPatients.length
    },
    tookAReadingToday() {
      let startOfToday = new Date()
      startOfToday.setHours(0, 0, 0, 0)

      let subsetPatients = this.filteredArray.filter(function (e) {
        return e.mostRecentDataTimestamp > startOfToday.getTime()
      })
      return subsetPatients.length
    },
    tookAReadingLast7Days() {
      let sevenDays = new Date()
      sevenDays.setDate(sevenDays.getDate() - 7)
      sevenDays.setHours(0, 0, 0, 0)

      let countToReturn = this.filteredArray.filter(function (e) {
        return e.mostRecentDataTimestamp > sevenDays.getTime()
      }).length

      return countToReturn
    },
    moreThan30SinceLastReading() {
      let monthAgo = new Date()
      monthAgo.setDate(monthAgo.getDate() - 30)
      monthAgo.setHours(0, 0, 0, 0)

      let countToReturn = this.filteredArray.filter(function (e) {
        return e.mostRecentDataTimestamp < monthAgo.getTime()
      }).length

      return countToReturn
    },
    threeDaysInARow() {
      // patients that have taken a reading every other day for a long time?
      let count = 0
      this.filteredArray.forEach(patient => {
        if (
          patient.rpm &&
          patient.rpm.activity &&
          patient.rpm.activity.dates &&
          patient.rpm.activity.dates.length > 0
        ) {
          count = count + 1
        }
      })

      return count
    },
    outOfRange() {
      let subsetPatients = this.filteredArray.filter(function (e) {
        return e.alert
      })
      return subsetPatients.length
    },
    addedInTheLast30Days() {
      let thirtyDays = new Date()
      thirtyDays.setDate(thirtyDays.getDate() - 30)
      thirtyDays.setHours(0, 0, 0, 0)

      let countToReturn = this.enrolledPatients.filter(function (e) {
        let enrollment = new Date(e.rpm.enrollment.start).getTime()
        return enrollment > thirtyDays.getTime()
      }).length

      return countToReturn
    },
    noData() {
      let countToReturn = this.filteredArray.filter(function (e) {
        return e.noData
      }).length

      return countToReturn
    },
    averageEnrollmentAge() {
      let enrollmentAges = this.filteredArray.map(a => a.enrollmentAge)
      let totalAge = 0
      enrollmentAges.forEach(age => {
        totalAge = totalAge + age
      })

      return totalAge / enrollmentAges.length
    },
    highCompliancePatients() {
      let filteredList = this.filteredArray

      filteredList = filteredList.filter(function (e) {
        return e.highCompliance
      })

      return filteredList.length
    },
    qualifiedRecently() {
      let filteredList = this.filteredArray

      filteredList = filteredList.filter(function (e) {
        return e.recentQualifications > 0
      })

      return filteredList.length
    },

    onFire() {
      let filteredList = this.filteredArray

      filteredList = filteredList.filter(function (e) {
        return e.onFire
      })

      return filteredList.length
    },

    lowCompliancePatients() {
      let filteredList = this.filteredArray

      filteredList = filteredList.filter(function (e) {
        return e.lowCompliance
      })

      return filteredList.length
    },
    conditionsSeries() {
      let series = []
      let includedCount = 0
      let otherCount = 0
      this.conditionCounts.forEach(card => {
        if (card.count / this.filteredArray.length > 0.02) {
          series.push(card.count)
          includedCount = includedCount + 1
        } else {
          otherCount = otherCount + card.count
        }
      })
      series.push(otherCount)
      return series
    },
    conditionsLabels() {
      let labels = []
      let includedCount = 0
      this.conditionCounts.forEach(card => {
        if (card.count / this.filteredArray.length > 0.02) {
          labels.push(card.icd10sForMatch.sort().join(', '))
          includedCount = includedCount + 1
        }
      })
      labels.push('Other')
      return labels
    },
    radialBarSeries() {
      let series = []
      let includedCount = 0
      let otherCount = 0
      this.conditionCounts2.forEach(card => {
        let value = (card.count / this.filteredArray.length) * 100

        if (value > 1 && includedCount < 6) {
          if (this.funimate) {
            series.push(Math.ceil((card.count / this.filteredArray.length) * 100))
          } else {
            series.push(0)
          }
          includedCount = includedCount + 1
        }
      })
      if (otherCount > 0 && this.funimate) {
        series.push(otherCount)
      } else if (otherCount > 0) {
        series.push(0)
      }

      return series
    },
    radialBarLabels() {
      let colors = ['#0E3C55', '#1495BA', '#A2B86C', '#F16D20', 'primary lighten-2']
      let dark = [false, false, false, false, true]
      let labels = []
      let includedCount = 0
      this.conditionCounts2.forEach(card => {
        let value = (card.count / this.filteredArray.length) * 100

        if (value > 1 && includedCount < 6) {
          card.color = colors[includedCount]
          card.dark = dark[includedCount]
          labels.push(card)
          includedCount = includedCount + 1
        }
      })

      return labels
    },
    conditionCounts2() {
      let compiledCounts = []

      this.patientConditions.forEach(conditionOption => {
        let conditionSubset = this.filteredArray.filter(function (e) {
          return e.conditionsForMatching.includes(conditionOption.value)
        })

        let returnObject = {
          icd10: conditionOption.value,
          description: conditionOption.text,
          count: conditionSubset.length,
        }
        if (conditionSubset.length > 0) {
          compiledCounts.push(returnObject)
        }
      })
      return compiledCounts.sort((a, b) => b.count - a.count)
    },
    chartOptionsDonut() {
      return {
        chart: {
          type: 'donut',
          animations: {
            enabled: false,
          },
        },

        labels: this.conditionsLabels,
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              size: '75%',
              labels: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
      }
    },
    radialBarOptions() {
      return {
        chart: {
          height: '100%',
          width: '100%',
          type: 'radialBar',
          animations: { enabled: false },
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: '30%',
              background: 'transparent',
              image: undefined,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        colors: ['#1ab7ea', '#0084ff', '#39539E', '#0077B5'],
        labels: this.radialBarLabels,
        legend: {
          show: true,
          floating: false,
          fontSize: '24px',
          position: 'left',
          offsetX: 0,
          offsetY: 15,
          labels: {
            useSeriesColors: false,
          },
          markers: {
            size: 0,
          },
          formatter: function (seriesName, opts) {
            return seriesName + ':  ' + opts.w.globals.series[opts.seriesIndex] + '%'
          },
          itemMargin: {
            vertical: 3,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
      }
    },
    chartOptionsMini() {
      return {
        chart: {
          sparkline: { enabled: true },
          animations: {
            enabled: false,
          },
        },
        stroke: { width: 0, curve: 'smooth' },
        fill: {
          opacity: 1,
          gradient: {
            shade: 'light',
            type: 'horizontal',
            shadeIntensity: 0.0,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 100],
            colorStops: [],
          },
        },
        xaxis: {
          forceNiceScale: false,
          type: 'datetime',
          show: false,
        },
        tooltip: {
          enabled: true,
        },
      }
    },
    series() {
      if (this.stats.series) {
        return this.stats.series
      }
      return []
    },
  },
  methods: {
    ...mapMutations('inbox', ['toggleInboxFilter', 'updateOrgStats', 'clearInboxFilters']),
    ...mapActions('inbox', ['updateOrgStats']),
    ...mapMutations('inbox', ['setPatientTagFilters']),
    async statisticsForComparison() {
      let categoriesTranslation = {
        qhpTimeThisMonth: 'qhpCategory',
        engagementPerfectionNeeded: 'onTrackCategory',
        timeSinceLastReading: 'lastReadingCategory',
      }
      let actualData = {}
      Object.keys(this.categoriesForSorting).forEach(broadCategory => {
        let catArray = this.categoriesForSorting[broadCategory]

        catArray.forEach(cat => {
          actualData[cat.name] = this.enrolledPatients.filter(function (e) {
            return e.sort[categoriesTranslation[broadCategory]] === cat.tag
          }).length
        })
      })

      console.log(actualData)

      let patientsWithA99457OrMore = this.enrolledPatients.filter(function (e) {
        return e.millisecondsThisPeriod >= 60 * 20 * 1000
      }).length

      let enrollmentDates = []

      this.enrolledPatients.forEach(patient => {
        let enrolled = Date.parse(patient.rpm.enrollment.start)
        let pt = { id: patient.id, enrolled: enrolled }
        enrollmentDates.push(pt)
      })

      this.updateOrgStats({
        org: this.org.id,
        qualifiedFor99457: patientsWithA99457OrMore,
        activeLast48: this.tookAReadingInTheLast48,
        activeLast15: this.tookAReadingInTheLast15Days,
        totalEnrolled: this.enrolledPatients.length,
        addedInLast30Days: this.addedInTheLast30Days,
        enrollmentDates: enrollmentDates,
        localStatsForComparison: actualData,
        updated: new Date().getTime(),
      })
    },
    toggleFilter(tag) {
      console.log(tag)
      this.toggleInboxFilter(tag)
    },
    circleStyle(index) {
      return (
        'position: absolute; width:' +
        this.circleDiameter +
        'px; margin-left: 0px; ' +
        'top: ' +
        index * (this.circleThickness * 1.5) +
        'px;'
      )
    },
    msToTime(s) {
      if (!s) {
        return '00:00:00'
      }
      let seconds = Math.floor((s / 1000) % 60)
      let minutes = Math.floor((s / (1000 * 60)) % 60)
      let hours = Math.floor(s / (1000 * 60 * 60))

      hours = hours < 10 ? '0' + hours : hours
      minutes = minutes < 10 ? '0' + minutes : minutes
      seconds = seconds < 10 ? '0' + seconds : seconds

      return hours + ':' + minutes + ':' + seconds
    },
    circleLabelStyle() {
      return 'margin-bottom: ' + this.circleThickness * 0.5 + 'px;'
    },
    relativeTime(value) {
      let now = new Date().getTime()
      var Difference_In_Time = now - value

      let days = Difference_In_Time / (3600000 * 24)

      // if tis less than three days ago use moment.
      if (value && Math.floor(days) < 3) {
        let dt = moment(value).tz(moment.tz.guess()).format('MM/DD/YYYY h:mm a')
        if (dt.includes('12:00 am')) {
          return 'Today'
        } else {
          return moment(value).tz(moment.tz.guess()).calendar()
        }
      } else if (value) {
        return this.getConversationalDateAndTimeFromTimestamp(value)
      }
      return ''
    },
  },
  mounted() {
    this.showCaracol = true
    if (this.org) {
      this.statisticsForComparison()
    }
  },
}
</script>

<style></style>
