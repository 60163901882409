<template>
  <v-select
    v-if="orgSwitcherOptions.length > 0 && !searchFocused"
    style="font-weight: bold; max-width: 360px"
    hide-details
    solo
    flat
    background-color="transparent"
    placeholder="ORG SWITCHER"
    v-model="orgSwitcherSelection"
    :items="orgSwitcherOptions"
    @change="switchOrg"
    return-object
  >
    <template v-slot:selection="{ item }">
      <v-row align="center">
        <v-avatar class="white--text mr-2" size="32" color="primary">
          <span style="font-weight: bold; font-size: 14px">
            {{ item.name.split(' ')[0].charAt(0)
            }}<span v-if="item.name.split(' ')[1]">{{ item.name.split(' ')[1].charAt(0) }}</span>
          </span>
        </v-avatar>
        <span class="text-truncate" style="width: 270px">{{ item.name }}</span>
      </v-row>
    </template>
    <template v-slot:item="{ item }">
      <span>{{ item.name }}</span>
    </template>
  </v-select>
</template>
<style>
.v-card--link:before {
  background: none;
}
</style>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { getOrgs } from '@/shared-ui/store/org'

export default {
  components: {},
  props: {
    transitioning: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      patientSearchText: null,
      selectedPatients: [],
      fullOrgsDict: {},
      searchFocused: false,
    }
  },
  watch: {
    orgSwitcherOptions() {
      this.orgSwitcherSelection = this.orgSwitcherOptions.find(opt => {
        return opt.id === this.selectedOrgId
      })
      this.orgSwitcherSelection = this.orgSwitcherSelection || this.orgSwitcherOptions[0]
      this.loadOrg(this.orgSwitcherSelection.id)
    },
  },
  methods: {
    ...mapActions('org', ['loadOrg']),
    ...mapMutations('auth', ['setSelectedOrgId']),
    ...mapMutations('inbox', ['setPatientList', 'setOrgNotifications']),
    ...mapActions('patient', ['convertLegacyReviewNotesToFirebaseReviewNotes']),

    switchOrg() {
      this.$emit('prepareOrgSwitch')
      this.setSelectedOrgId(this.orgSwitcherSelection.id)
      this.loadOrg(this.orgSwitcherSelection.id)
    },
  },
  computed: {
    ...mapState('auth', ['user', 'selectedOrgId']),
    ...mapState('org', [
      'org',
      'patientsLoading',
      'enrolledPatients',
      'unreadConvosCount',
      'unenrolledPatients',
      'users',
    ]),
    ...mapState('org', ['providerReviewNotifications', 'careTeamReviewNotifications', 'mentions']),
    ...mapState('patient', ['patient']),
    orgUserDictionary() {
      const dict = {}
      if (this.users && this.users.length > 0) {
        this.users.forEach(user => {
          dict[user.id] = { name: 'Unknown', initials: 'UK' }
          if (user.firstName && user.lastName) {
            dict[user.id].name = user.firstName + ' ' + user.lastName
            dict[user.id].initials = user.firstName.charAt(0) + user.lastName.charAt(0)
          }
        })
      }
      return dict
    },
    orgSwitcherOptions() {
      const options = []
      for (const o of this.user.orgs) {
        if (!this.fullOrgsDict[o.orgId]) continue
        options.push({
          name: this.fullOrgsDict[o.orgId].name,
          id: o.orgId,
          value: this.fullOrgsDict[o.orgId].name,
        })
      }
      options.sort((a, b) => a.name.localeCompare(b.name))
      return options
    },
  },
  async beforeCreate() {
    const fullOrgsDict = {}
    for (const org of await getOrgs()) {
      fullOrgsDict[org.id] = org
    }
    this.fullOrgsDict = fullOrgsDict
  },
}
</script>
