<template>
  <v-card flat color="grey lighten-5" class="flex py-4 px-3">
    <v-row class="pb-1" no-gutters align="center" justify="start">
      <span class="font-weight-bold">{{ chartTitle }}</span>
    </v-row>
    <v-card height="130" color="transparent" flat>
      <canvas :id="uniqueID"></canvas>
    </v-card>

    <v-card v-if="false" style="overflow-y: auto" flat color="white" height="175" class="flex">
      <v-simple-table style="background-color: transparent" dense>
        <template v-slot:default>
          <tbody>
            <tr v-for="(row, index) in data" :key="index">
              <td align="left">
                <v-row no-gutters>
                  <span class="text-caption">{{ data[index].x }} Weeks</span>
                </v-row>
              </td>
              <td align="right">
                <v-row justify="end" class="pr-1">
                  <span class="font-weight-bold mr-1">{{ data[index].y }}</span>
                </v-row>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-card>
</template>
<script>
import { defineComponent, onMounted, ref, watch, computed } from '@vue/composition-api'
import Chart from 'chart.js'
import 'chartjs-adapter-date-fns'

export default defineComponent({
  props: {
    population: {
      type: Array,
      default: () => [],
    },
    filterCategory: {
      type: Array,
      default: () => [],
    },
    patientSortKey: {
      type: String,
      default: null,
    },
    chartTitle: {
      type: String,
      default: 'Title',
    },
  },
  setup(props, { emit }) {
    const chart = ref(null)
    const uniqueID = ref(Math.floor(Math.random() * 100000) + ':Identifier')

    watch(
      () => props.population,
      () => {
        if (props.population.length > 0) {
          loadChart1()
        }
      },
      { deep: true }
    )

    const data = computed(() => {
      let actualData = []
      let weeksDict = {}
      props.population.forEach(patient => {
        let weeksEnrolled = patient.sort.weeksCategory
        if (weeksDict[weeksEnrolled]) {
          weeksDict[weeksEnrolled] = weeksDict[weeksEnrolled] + 1
        } else {
          weeksDict[weeksEnrolled] = 1
        }
      })

      Object.keys(weeksDict).forEach(key => {
        actualData.push({ y: weeksDict[key], x: key })
      })

      return actualData
    })

    // const colorPalette = computed(() => {
    //   const backgroundColor = []
    //   const borderColor = []
    //   let successMod = 0
    //   let warningMod = 0
    //   let failMod = 0
    //   props.filterCategory.forEach(category => {
    //     if (category.color === 0) {
    //       backgroundColor.push(CONSTS.colorsForGraphs.success[successMod])
    //       borderColor.push('#ffffff')
    //       successMod = successMod + 1
    //     }
    //     if (category.color === 1) {
    //       backgroundColor.push(CONSTS.colorsForGraphs.warning[warningMod])
    //       borderColor.push('#ffffff')
    //       warningMod = warningMod + 1
    //     }
    //     if (category.color === 2) {
    //       backgroundColor.push(CONSTS.colorsForGraphs.failure[failMod])
    //       borderColor.push('#ffffff')
    //       failMod = failMod + 1
    //     }
    //     if (category.color === 3) {
    //       backgroundColor.push(CONSTS.colorsForGraphs.issue[0]), borderColor.push('#ffffff')
    //       borderColor.push('#ffffff')
    //     }
    //     if (category.color === 4) {
    //       backgroundColor.push(CONSTS.colorsForGraphs.issue[1]), borderColor.push('#ffffff')
    //       borderColor.push('#ffffff')
    //     }
    //   })
    //   return { backgroundColor: backgroundColor, borderColor: borderColor }
    // })

    const dataToAdd = computed(() => {
      return {
        datasets: [
          {
            label: 'Patients',
            data: data.value,
            backgroundColor: '#4CAF504D',
            borderColor: '#4CAF508',
            borderWidth: 1,
          },
        ],
      }
    })

    const loadChart1 = () => {
      if (chart.value) {
        chart.value.data.datasets[0].data = data.value
        chart.value.update()
      } else {
        const ctx = document.getElementById(uniqueID.value)
        chart.value = new Chart(ctx, {
          type: 'line',

          data: dataToAdd.value,
          options: {
            layout: {
              padding: {
                left: 0,
                right: 0,
                top: 10,
                bottom: 0,
              },
            },
            scales: {
              xAxes: [
                {
                  type: 'linear',
                },
              ],
              yAxes: [
                {
                  display: false,
                  type: 'linear',
                  show: false,
                  ticks: {
                    maxTicksLimit: 2,
                  },
                  grid: {
                    drawBorder: false,
                  },
                },
              ],
            },
            legend: {
              display: false,
            },
            onClick: function (e) {
              const activePoints = chart.value.getElementsAtEventForMode(
                e,
                'nearest',
                {
                  intersect: true,
                },
                false
              )
              console.log(dataToAdd.value.datasets[activePoints[0]._datasetIndex])
              selectedFilter(dataToAdd.value.datasets[activePoints[0]._datasetIndex].data[activePoints[0]._index].x)
            },
            tooltips: {
              // Disable the on-canvas tooltip
              enabled: true,

              custom: function (tooltipModel) {
                if (tooltipModel.opacity === 1) {
                  chart.value.canvas.style.cursor = 'pointer'
                } else {
                  chart.value.canvas.style.cursor = null
                }
              },
            },

            responsive: true,
            maintainAspectRatio: false,
          },
        })
      }
    }

    const selectedFilter = index => {
      emit('selectedFilter', { category: 'weeksCategory', tag: index })
    }

    onMounted(() => {
      loadChart1()
    })

    return { uniqueID, data }
  },
})
</script>

<style>
.card.left {
  -webkit-transform: scale(0.35);
  -moz-transform: scale(0.35);
  -o-transform: scale(0.35);
  -ms-transform: scale(0.35);
  transform: scale(0.35) translate(-230px, -150px);
  z-index: 0;
}
.card {
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
  width: 100%;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1) translate(0px, 30px);
  z-index: 10;
}
.card.right {
  -webkit-transform: scale(0.35);
  -moz-transform: scale(0.35);
  -o-transform: scale(0.35);
  -ms-transform: scale(0.35);
  transform: scale(0.35) translate(230px, -150px);
  z-index: 0;
}
</style>
