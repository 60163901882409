import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import conversation from './v2/conversation'
import enrollment from './v2/enrollment'
import inbox from './v2/inbox'
import org from './modules/org'
import patient from './modules/patient'
import rpm from './v2/rpm'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    enrollment,
    org,
    patient,
    rpm,
    conversation,
    inbox,
  },
})
