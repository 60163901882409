<template>
  <v-navigation-drawer fixed floating dark color="#001426" mini-variant mini-variant-width="60" permanent stateless>
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-list-item
          class="py-1"
          v-bind="attrs"
          v-on="on"
          v-model="isDisplayingEnterprise"
          :to="{ name: 'Enterprise' }"
        >
          <v-list-item-icon>
            <v-img :src="require('@/assets/Arrowhealth-bug-RGB.png')"></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Enterprise</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <span>Enterprise Statistics</span>
    </v-tooltip>

    <v-list nav dense>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on" v-model="isDisplayingDashboard" :to="{ name: 'Inbox' }">
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Inbox</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <span>Inbox</span>
      </v-tooltip>

      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on" v-model="isDisplayingMessaging" :to="{ name: 'Conversations' }">
            <v-list-item-icon>
              <v-badge :value="unreadConvosCount" :content="unreadConvosCount" color="primary" overlap>
                <v-icon>mdi-message-reply-text</v-icon>
              </v-badge>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Conversations</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <span>Conversations</span>
      </v-tooltip>
      <app-drawer-user-navigation-list v-if="false" />
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on" v-model="isDisplayingAccount" :to="accountRoute">
            <v-list-item-icon>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ user.firstName }} {{ user.lastName }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        {{ user.firstName }} {{ user.lastName }}
      </v-tooltip>
      <v-fade-transition>
        <v-tooltip v-if="org" right>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on" v-model="isDisplayingOrg" exact :to="orgRoute">
              <v-list-item-icon>
                <v-icon>mdi-domain</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          {{ org.name }}
        </v-tooltip>
      </v-fade-transition>
      <v-fade-transition>
        <v-tooltip v-if="org" right>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on" v-model="isDisplayingEnrollment" :to="enrollmentRoute">
              <v-list-item-icon>
                <v-icon class="rotate">mdi-account-plus</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Enrollment</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          Enrollment
        </v-tooltip>
      </v-fade-transition>
      <v-fade-transition>
        <v-tooltip v-if="org" right>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on" v-model="isDisplayingDeviceManagement" :to="deviceRoute">
              <v-list-item-icon>
                <v-icon class="rotate">mdi-devices</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Devices</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          Devices
        </v-tooltip>
      </v-fade-transition>
      <v-fade-transition>
        <v-tooltip v-if="org" right>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on" :to="{ name: 'Activity' }" v-model="isDisplayingReports">
              <v-list-item-icon>
                <v-icon>mdi-google-spreadsheet</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Activity Report</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          Activity Report
        </v-tooltip>
      </v-fade-transition>
    </v-list>
    <template v-slot:append>
      <v-tooltip v-if="org" right>
        <template v-slot:activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on" @click="$emit('turnOffEnhanced')" v-model="isDisplayingReports">
            <v-list-item-icon>
              <v-icon>mdi-chart-donut</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Enhanced View</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        Toggle Enhanced View
      </v-tooltip>
    </template>
  </v-navigation-drawer>
</template>
<style>
.v-card--link:before {
  background: none;
}
</style>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  components: {},
  props: {},
  data() {
    return {}
  },
  watch: {},
  methods: {
    ...mapActions('org', ['loadOrg']),
    ...mapMutations('auth', ['setSelectedOrgId']),
    ...mapMutations('inbox', ['setPatientList', 'setOrgNotifications']),
    ...mapActions('patient', ['convertLegacyReviewNotesToFirebaseReviewNotes']),

    openNewBillingPlatform() {
      this.child = window.open('https://partners.novorpm.com', 'null', null)
    },
  },
  computed: {
    ...mapState('auth', ['user', 'selectedOrgId']),
    ...mapState('org', [
      'org',
      'patientsLoading',
      'enrolledPatients',
      'unreadConvosCount',
      'unenrolledPatients',
      'users',
    ]),
    ...mapState('org', ['providerReviewNotifications', 'careTeamReviewNotifications', 'mentions']),
    ...mapState('patient', ['patient']),
    isDisplayingReports() {
      return 'PatientActivity' === this.$route.name
    },
    isDisplayingEnterprise() {
      return 'Enterprise' === this.$route.name
    },
    isDisplayingAccount() {
      return 'Account' === this.$route.name
    },
    isDisplayingDevices() {
      return 'Enrollment' === this.$route.name
    },
    isDisplayingEnrollment() {
      return 'Device Management' === this.$route.name
    },
    isDisplayingOrg() {
      return 'Organization' === this.$route.name
    },
    accountRoute() {
      return { name: 'Account', params: { id: this.user.id } }
    },
    orgRoute() {
      return { name: 'Organization', params: { id: this.org.id } }
    },
    enrollmentRoute() {
      return { name: 'Enrollment', params: { id: this.org.id } }
    },
    deviceRoute() {
      return { name: 'Device Management', params: { id: this.org.id } }
    },
    isDisplayingAlerts() {
      return 'Alerts' === this.$route.name
    },
    isDisplayingInbox() {
      return 'Inbox' === this.$route.name
    },
    isDisplayingActivity() {
      return 'Engagement' === this.$route.name
    },
    isDisplayingDeviceManagement() {
      return 'Device Management' === this.$route.name
    },
    isDisplayingDashboard() {
      return 'Inbox' === this.$route.name
    },
    isDisplayingMessaging() {
      return 'Messaging' === this.$route.name
    },
    isDisplayingConversations() {
      return 'Conversations' === this.$route.name
    },
  },
  async beforeCreate() {},
}
</script>
