export default {
  patient: null,
  patientUpdating: false,
  deprecatedNotes: [],
  deprecatedNotesLoading: false,
  accessLogs: [],
  accessLogsLoading: false,

  _yearOfMetricsKindsPulled: [],
  _yearOfMetricsKindsToPull: [],
  yearOfMetrics: {}, // {kind -> time-descending array of readings}
  yearOfMetricsLoaded: false,

  _resetHooks: [],
}
